import { useState, useEffect } from "react";
import { statusCode } from "../../utility/constants/utilObject";
import { useNavigate, useLocation, useParams, } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { dbConfiguration } from "./DBConfig";
import { formatText } from "../../utility/helper";
import { InfinitySpin } from "react-loader-spinner";
import { createDbSessionHandler } from "../../services/dbConnect";
import { connect } from "react-redux";
import { testSchema } from '../../utility/testDB/testSchema';


const DBForm = ({ dbName, backButton }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const propData = location.state;

    const { sample_db } = useParams();

    const config = dbConfiguration[dbName];

    if (!config && !sample_db) {
        return <div>Invalid database selected</div>;
    }

    useEffect(() => {
        if (sample_db && sample_db.length > 0) {
            setLoading(true);

            const sqlPayload = {
                "name": 'carDatabase',
                "database_type": "MYSQL",
                "credentials": JSON.stringify({
                    "database": "classicmodels",
                    "host": "13.235.113.150",
                    "username": "aws_product",
                    "password": "pinnacle_11049",
                    "port": "3306"
                })
            }
            createDbSessionHandler(sqlPayload)
                .then((res) => {
                    if (res.status == statusCode.HTTP_201_CREATED) {
                        console.log(res.data.schema);
                        navigate(`/database-intelligence/analytics/${res.data.session_id}`, {
                            state: {
                                schema: res.data.schema,
                                connection_type: 'NEW',
                            }
                        });
                    }
                }).catch((err) => {
                    console.log(err);
                })
        }
    }, [sample_db])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(config).forEach((key) => {
            const type = config[key];
            const isOptional = type.includes("(optional)");
            if (!isOptional && !formData[key]) {
                newErrors[key] = `${formatText(key)} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setLoading(true);
        console.log(formData);
        if (sample_db){
            
        }
        const payload = {
            "name": formData.database,
            "database_type": dbName.toUpperCase(),
            "credentials": JSON.stringify(formData)
        }
        createDbSessionHandler(payload)
            .then((res) => {
                if (res.status == statusCode.HTTP_201_CREATED) {
                    console.log(res.data.schema);
                    navigate(`/database-intelligence/analytics/${res.data.session_id}`, {
                        state: {
                            schema: res.data.schema,
                            connection_type: 'NEW',
                        }
                    });
                }
            }).catch((err) => {
                console.log(err);
            })
    };

    const handleBack = () => {
        closeFrom();
    }

    return (
        <>
            <div style={{
                width: '100%',
                height: '40px',
                borderBottom: '1px solid #d8d6de',
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <div style={{
                    height: '18px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}>Connect to Database | {dbName || propData?.schema}</div>
            </div>

            <div className="db-form">
                {
                    loading
                        ?
                        <div className="loading-wrapper">
                            <InfinitySpin color="#0C6980" height="100px" />
                            <span>Connecting to your Database...</span>
                        </div>
                        :
                        <Form onSubmit={handleSubmit}>
                            {config && Object.keys(config).map((key) => {
                                const type = config[key];
                                const isOptional = type.includes("(optional)");
                                const isPassword = key.toLowerCase().includes("password");
                                return (
                                    <FormGroup key={key}>
                                        <Label for={key}>
                                            {formatText(key)} {isOptional && "(optional)"}
                                        </Label>
                                        <Input
                                            id={key}
                                            name={key}
                                            placeholder={`Enter ${formatText(key)}`}
                                            type={isPassword ? "password" : (type.includes("string") ? "text" : "number")}
                                            required={!isOptional}
                                            value={formData[key] || ''}
                                            onChange={handleChange}
                                            invalid={!!errors[key]}
                                        />
                                        {errors[key] && <div className="text-danger">{errors[key]}</div>}
                                    </FormGroup>
                                );
                            })}
                            <div className="btn-wrapper" >
                                <Button onClick={backButton} color="secondary">Back to DB Selection</Button>
                                <Button type="submit" color="success">Connect</Button>
                            </div>
                        </Form>


                }
            </div>
        </>
    )
}

export default DBForm;