import Types from "../types";
import { documentListHandler } from "../../services/document";
import { statusCode } from "../../utility/constants/utilObject";
import { uploadDocumentFile } from "../../services/onboarding";
import { toast } from "react-hot-toast";

export const setDocument = (documentPaylaoad) => async (dispatch) => {
    dispatch({
        type: Types.DOCUMENT.SUCCESS,
        payload: documentPaylaoad,
    });
}

// Saves the last created document
export const setGeneratedDocument = (generatedDocumentPayload) => async (dispatch) => {
    dispatch({
        type: Types.GENERATED_DOCUMENTS,
        payload: generatedDocumentPayload,
    });
}

export const uploadDocument = (dataPayload) => async (dispatch) => {
    uploadDocumentFile(dataPayload)
      .then((res)=>{
          if(res.status.toString() === statusCode.HTTP_201_CREATED.toString()){
              dispatch({
                  type: Types.DOCUMENT.SUCCESS,
                  payload: res.data,
              });
              toast.success(res.data.message);
          }
      })
      .catch((err) => {
          console.log('Error while uploading the document', err);
      })
}

export const reloadDocument = () => async (dispatch) => {
    const userId = localStorage.getItem("id")
    documentListHandler(userId)
    .then((res)=>{
        if(res.status.toString() === statusCode.HTTP_200_OK.toString()){
            dispatch({
                type: Types.DOCUMENT.SUCCESS,
                payload: res.data.reverse(),
            });
        }
    })
    .catch((err)=>{})
}

export const addNewDocument = (documentPaylaoad) => async (dispatch) => {
    dispatch({
        type: Types.DOCUMENT.ADD_NEW,
        payload: [documentPaylaoad],
    });
}

export const setPreviewDocument = (previewDocumentPaylaoad) => async (dispatch) => {
    dispatch({
        type: Types.PREVIEW_DOCUMENT.UPDATE,
        payload: previewDocumentPaylaoad,
    });
}
export const setCurrentDocumentAnalysis = (documentPaylaoad) => async (dispatch) => {
    dispatch({
        type: Types.DOCUMENT_ANALYSIS.UPDATE,
        payload: documentPaylaoad,
    });
}
