import "./index.scss"
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input, FormText } from "reactstrap";
import { Search } from "react-feather";
import PageHeader from "../../@core/components/PageHeader";
import FinancialStatement from "../../assets/icons/financial_statement_analytics.png";
import TrailBalance from "../../assets/icons/trial_balance_analytics.png";
import ScannedPdf from "../../assets/icons/scanned_pdf_analytics.png";
import BankStatement from "../../assets/icons/bank_statement_analytics.png";
import ZohoLogo from "../../assets/logo/zoho_logo.png";
import TallyLogo from "../../assets/logo/tally_logo.png";
import GSTLogo from "../../assets/logo/gst.png";
import VATLogo from "../../assets/logo/vat.webp";
import { fetchUploadedDocumentDetails } from "../../utility/docFunctions";
import { connect } from "react-redux";
import { showErrorToast } from "../../utility/helper";
import Banner from "../components/banner";
import PremiumIcon from "../../assets/icons/premium.png";


const PRODUCT_TYPE = {
  FINANCIAL_STATEMENT: 'FINANCIAL_STATEMENT',
  TRAIL_BALANCE: 'TRAIL_BALANCE',
  SCANNED_PDF: 'SCANNED_PDF',
}


const LedgerAndBooks = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({
    name: '',
    documentType: '',
    file: null
  })

  // Data Variables
  const [selectedProduct, setSelectedProduct] = useState(null);

  const initLoadClick = (productType) => {
    setSelectedProduct(productType);
    setModal(!modal)
    // fileInputRef.current.click();
  }

  const loadFile = (e) => {
    // const file = e.target.files[0];
    console.log(uploadInfo.file);
    const file = uploadInfo.file;
    const fileDetails = fetchUploadedDocumentDetails(file);

    if (selectedProduct == PRODUCT_TYPE.FINANCIAL_STATEMENT) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/financial-statement-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    } else if (selectedProduct == PRODUCT_TYPE.TRAIL_BALANCE) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/trial-balance-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    } else if (selectedProduct == PRODUCT_TYPE.SCANNED_PDF) {
      if (fileDetails.fileCategory !== 'PDF') {
        showErrorToast('Please upload a PDF file');
      } else {
        navigate('/scanned-pdf-analytics/new-analysis', { state: { file: uploadInfo } });
      }
    }
  }

  //Modal Input

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUploadInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  }

  const ledgerModal = () => {
    return (
      <>
        <div>

          <Modal isOpen={modal} toggle={initLoadClick} className="ledger-modal">
            <ModalBody>
              <Form>
                <FormGroup row>
                  <Label for="name" sm={3}>Name:</Label>
                  <Col sm={9}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Document Name"
                      type="text"
                      value={uploadInfo.name}
                      onChange={handleInput}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="documentType" sm={3}>
                    Select Document Type
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="documentType"
                      id="documentType"
                      value={uploadInfo.documentType}
                      onChange={handleInput}
                    >
                      <option value="">Select Document Type</option>
                      <option value="text-pdf">Text PDF</option>
                      <option value="scanned-pdf">Scanned PDF / Image</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label
                    for="file"
                    sm={3}
                  >
                    File
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(e) => setUploadInfo({ ...uploadInfo, file: e.target.files[0] })}
                    />
                    {/* <FormText>
                      This is some placeholder block-level help text for the above input. It‘s a bit lighter and easily wraps to a new line.
                    </FormText> */}
                  </Col>
                </FormGroup>

              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={loadFile}>
                Proceed
              </Button>{' '}
              <Button color="secondary" onClick={initLoadClick}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    )
  };

  return (
    <>
      {/* <Banner /> */}

      <Card>
        <PageHeader
          pageName={"Dashboard"}
          backButtonEnabled={false}
        />
        <div className='container-fluid vh-85'>
          {/* <div className='page-header'>
            <div className='tab-container'>
              <div className='section-head'>Select From Options Below</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className='search-container'>
              <div className='search-area'>
                <input
                  className='header-search'
                  placeholder='Search...'
                />
                <Search size={18} color='#B0BEC5' />
              </div>
            </div>
          </div> */}
          <div className='page-container no-scroll-bar'>
            {/* Statement Container */}
            <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
            <div key={1} className="outer-template-container">
              <div className="template-container-header">
                Statement Analytics
              </div>
              <div className="template-container-items-list">
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={FinancialStatement} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    Financial Statement Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    {/* <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button> */}

                    <button
                      onClick={() => { initLoadClick(PRODUCT_TYPE.FINANCIAL_STATEMENT) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/financial-statements') }} className="action-btn">History</button>
                  </div>
                </div>
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={TrailBalance} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    Trial Balance Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    {/* <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button> */}

                    <button
                      onClick={() => { initLoadClick(PRODUCT_TYPE.TRAIL_BALANCE) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/trial-balances') }} className="action-btn">History</button>
                  </div>
                </div>
                {/* <div key={3} className="template-container-item" onClick={() => { navigate('/financial-statement-analyser') }}> */}
                <div key={3} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={BankStatement} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    Bank Statement Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  {/* <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div> */}
                  <div className="overlay">
                    <button
                      onClick={() => { navigate('/financial-statement-analyser') }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/dashboard') }} className="action-btn">History</button>
                  </div>
                </div>
                <div key={4} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={ScannedPdf} alt="Scanned PDF Analytics" />
                  </div>
                  <div className="header">
                    Scanned PDF Analysis
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    {/* <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button> */}

                    <button
                      onClick={() => { initLoadClick(PRODUCT_TYPE.SCANNED_PDF) }}
                      style={{ borderRight: '0.5px solid #FFFF' }}
                      className="action-btn">New</button>
                    <button onClick={() => { navigate('/scanned-pdfs') }} className="action-btn">History</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Book Integration Container */}
            <div key={2} className="outer-template-container">
              <div className="template-container-header">
                {/* Ledger Books Integration */}
                Upcoming Features
              </div>
              <div className="template-container-items-list">
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={ZohoLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    Zoho Books Integration
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                    {/* <button className="action-btn">Comming Soon...</button> */}

                  </div>

                </div>
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={TallyLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    Tally Integration
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
                <div key={3} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={GSTLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    GST Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
                <div key={4} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={VATLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    VAT Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn"> <img src={PremiumIcon} alt='/' /> Premium </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Taxation
            <div key={3} className="outer-template-container">
              <div className="template-container-header">
                Taxation
              </div>
              <div className="template-container-items-list">
                <div key={1} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={GSTLogo} alt="Financial Statement" />
                  </div>
                  <div className="header">
                    GST Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn">Comming Soon...</button>
                  </div>
                </div>
                <div key={2} className="template-container-item">
                  <div className="image-container">
                    <img className="image-item" src={VATLogo} alt="Trail Balance" />
                  </div>
                  <div className="header">
                    VAT Taxation
                  </div>
                  <div className="description">
                    Analyse all the financial statements details like balance sheet, cash flow statement, income statement and financial ratios.
                  </div>
                  <div className="overlay">
                    <button className="action-btn">Comming Soon...</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Card>
      {ledgerModal()}
    </>
  );
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ }) => {
  return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(LedgerAndBooks);