import React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatText } from "../../../utility/helper";
import { ClipLoader } from "react-spinners";

const CustomeTable = ({ loading, data, allowedColumns, loadingText, errResponse, redirectionLink, redirectTitle, singleRedirectionLink }) => {
    const navigate = useNavigate()
    const tableColumns = data.length > 0 ? ['serialNo', ...Object.keys(data[0]).filter(col => allowedColumns.includes(col))] : allowedColumns;

    const handleRowClick = (row) => {
        if(singleRedirectionLink && row){ navigate(singleRedirectionLink + row.id) }
    };

    const renderTableRows = () => {
        if (loading) {
            return (
                <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: '17px' }}>{loadingText || ''}</span> <ClipLoader size={"sm"} />
                    </td>
                </tr>
            );
        } else if (data.length === 0) {
            return (
                <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                        {errResponse || ''}
                        <br />
                        {
                            redirectTitle && redirectionLink && 
                            <Link
                                to={redirectionLink || ''}
                                style={{
                                    marginTop: '5px',
                                    width: '150px',
                                    height: '100px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    textDecoration: 'underline'
                                }}> {redirectTitle || ''}
                            </Link>
                        }
                    </td>
                </tr>
            );
        } else {
            return data.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item)} style={{cursor:'pointer'}}>
                    {tableColumns.map((col, i) => (
                        <td key={i}>
                            {col === 'serialNo' ? index + 1 :
                                col === 'created_at' || col === 'updated_at' ? moment(item[col]).format('DD-MM-YYYY') : item[col]}
                        </td>
                    ))}
                </tr>
            ));
        }
    }
    return (
        <div>
            <Table bordered hover responsive style={{ backgroundColor: 'white' }}>
                <thead style={{ backgroundColor: 'black', color: '#455A64', position:'sticky' }}>
                    <tr>
                    {tableColumns && tableColumns.map((item, i) => (
                        <th key={i} style={{fontSize: '14px'}}>{item === 'serialNo' ? 'S.No' : formatText(item)}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {/* <tr> */}
                    {/* <td colSpan={tableColumns.length}> */}
                        {/* <div style={{ height: '60vh', overflowY: 'scroll' }}> */}
                        {/* <table className="table" style={{cursor:'pointer'}}> */}
                            {/* <tbody> */}
                            {renderTableRows()}
                            {/* </tbody> */}
                        {/* </table> */}
                        {/* </div> */}
                    {/* </td> */}
                    {/* </tr> */}
                </tbody>
            </Table>
        </div>
    )
}

export default CustomeTable;