import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "./style.scss";
//Components
import PageHeader from "../../../@core/components/PageHeader";
import { Button, Card } from "reactstrap";

import MainConversationScreen from "./MainConversationScreen";
import Sidebar from "../../Dashboard/Sidebar";

const WhatsAppConversation = () => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { item, sidebar } = location.state || {};

    useEffect(()=>{
        if(sidebar){
            setSidebarOpen(true)
        }
    },[sidebar])
    return (
        <div>
            <PageHeader
                pageName={`WhatsAppBot Dashboard${item ? ` | Client Mobile No: ${item.client_number}  | ${item.name}` : ''}`}
                backButtonEnabled={true}
            />
            <Card className="wtsApp-bot">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div className="breadCrumb-bar">
                        <div className="client-Info">
                        </div>
                        <div className="breadCrumb-bar__btn">
                            <Button color="success" onClick={() => { setSidebarOpen(true) }}>Conversation</Button>
                        </div>
                    </div>
                    <MainConversationScreen />
                </div>

                <Sidebar open={sidebarOpen} title="Wtsapp Conversation" toggleSidebar={() => { setSidebarOpen(false) }} />
            </Card>
        </div>
    )
}

export default WhatsAppConversation;