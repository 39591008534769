import React from 'react';
import './index.scss'; // Assuming you create a separate CSS file for styling
import { Youtube } from 'react-feather';

const SourceContainer = ({onClickSource}) => {
    const sources = [
        { name: 'Website', icon: 'fa fa-globe', link: 'https://www.example.com' },
        { name: 'Youtube video', icon: 'fa fa-play-circle', link: 'https://www.youtube.com' },
        { name: 'Zoho', icon: 'fa fa-cloud', link: 'https://www.zoho.com' }
    ];

    const handleSourceClick = (index) => {
        onClickSource(index)
    }

    return (
        <div className="source-container">
            {sources.map((source, index) => (
                <button style={{textAlign:'start'}} key={index} className="source-button" onClick={() => {handleSourceClick(index)}}>
                    <i className={source.icon} style={{ marginRight: '10px' }} aria-hidden="true"></i>
                    {source.name}
                </button>
            ))}
        </div>
    );
};

export default SourceContainer;
