import Types from "../types";
import { fetchThirdPartyAuthListHandler } from "../../services/user";
import { statusCode } from "../../utility/constants/utilObject";

export const loadThirdPartyAuthPlatform = () => async (dispatch) => {
    fetchThirdPartyAuthListHandler()
    .then((res)=>{
        if(res.status == statusCode.HTTP_200_OK){
            dispatch({ type: Types.THIRD_PARTY_AUTH, payload: res.data.data });
        }
    })
    .catch((err)=>{})
}

export const updateThirdPartyData = (tpPayload) => async (dispatch) => {
    dispatch({type: Types.THIRD_PARTY_AUTH, payload: tpPayload});
}