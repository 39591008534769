import "./index.scss"

// Chart import
import BarCard from "../components/Charts/Bar";
import LineCard from "../components/Charts/LineCard";
import AreaCard from '../components/Charts/AreaCard';
import PieChartCard from "../components/Charts/PieChartCard";
import NewTable from "../components/Charts/NewTables";

//reactstrap
import { Button } from "reactstrap";

//Icons
import { Settings, Edit2 } from "react-feather";

/** 
 * =======================
 * Row and Column render function
 * =======================
*/


export const fourColumnRow = (columns, rowIndex, handleQueryClick) => {
    const columnDivList = columns.map((col, colIndex) => {
        return (
            <div key={colIndex} className="four-column-row-column">
                <div className="four-column-row-column__header">
                    <div className="title">{col.title}</div>
                    <div className="action">
                        <Button outline onClick={() => handleQueryClick(col)} className="query-btn">
                            Query <Edit2 />
                        </Button>
                        <Button outline>
                            <Settings />
                        </Button>
                    </div>

                </div>
                <div className="four-column-row-column__body">
                    {col.chartType ? renderChart(col.chartType, col.data) : <div className="data">{col.data}</div>}
                </div>
            </div>
        )
    })
    return (<div className="four-column-row-main-container">
        {columnDivList}
    </div>)
}

export const threeColumnRow = (columnsData, rowIndex, handleQueryClick) => {
    const columnDivList = columnsData.map((column, index) => (
        <div key={index} className="three-column-row-column">
            <div className="three-column-row-column__header">
                <div className="title">{column.title}</div>
                <div className="action">
                    <Button outline onClick={() => handleQueryClick(column)} className="query-btn">
                        Query <Edit2 />
                    </Button>
                    <Button outline>
                        <Settings />
                    </Button>
                </div>
            </div>
            <div className="three-column-row-column-body">
                <div className="three-column-row-column-body-chart">
                    {renderChart(column.chartType, column.data)}
                </div>
            </div>
        </div>
    ));
    return (<div className="three-column-row-main-container">
        {columnDivList}
    </div>)
}

export const twoColumnRow = (columns, rowIndex, handleQueryClick) => {

    const columnDivList = columns.map((column, colIndex) => (
        <div key={colIndex} className="two-column-row-column">
            <div className="two-column-row-column__header">
                <div className="title">{column.title}</div>
                <div className="action">
                    <Button outline onClick={() => handleQueryClick(column)} className="query-btn">
                        Query <Edit2 />
                    </Button>
                    <Button outline>
                        <Settings />
                    </Button>
                </div>
            </div>
            <div className="two-column-row-column-body">
                <div className="two-column-row-column-body-chart">
                    {renderChart(column.chartType, column.data)}
                </div>
            </div>
        </div>
    ))

    return (<div className="two-column-row-main-container">
        {columnDivList}
    </div>)
}

export const oneColumnRow = (columns, rowIndex, handleQueryClick) => {
    const singleContainer = columns.map((col, index) => (
        <div className="one-column-row-column" key={index}>
            <div className="one-column-row-column__header">
                <div className="title">
                    {col.title}
                </div>
                <div className="one-column-row-column-header-action">
                    <Button outline onClick={() => handleQueryClick(col)} className="query-btn">
                        Query <Edit2 />
                    </Button>
                    <Button outline>
                        <Settings />
                    </Button>
                </div>
            </div>
            <div className="one-column-row-column__body">
                <div className="chart">
                    {renderChart(col.chartType, col.data)}

                </div>
            </div>
        </div>
    ))

    return (
        <div className="one-column-row-main-container" key={columns.id}>
            {singleContainer}
        </div>

    )

}

/** 
 * =======================
 * Chart render functions 
 * =======================
*/


// Function to render chart based on type
const renderChart = (chartType, data) => {
    switch (chartType) {
        case CHART_TYPES.PIE:
            return <PieChartCard data={data} />;
        case CHART_TYPES.BAR:
            return <BarCard data={data} />;
        case CHART_TYPES.LINE:
            return <LineCard data={data} />;
        case CHART_TYPES.AREA:
            return <AreaCard data={data} />;
        case CHART_TYPES.TABLE:
            return <NewTable data={data} />;
        default:
            return null;
    }
};

export const validatePieChartData = (data) => {
    if (!data) {
        return 'Data is required';
    }
    if (!data.labels || data.labels.length === 0) {
        return 'Labels are required';
    }
    if (!data.datasets || data.datasets.length === 0) {
        return 'Datasets are required';
    }
    return '';
}

export const validateBarChartData = (data) => {
    if (!data) {
        return 'Data is required';
    }
    if (!data.labels || data.labels.length === 0) {
        return 'Labels are required';
    }
    if (!data.datasets || data.datasets.length === 0) {
        return 'Datasets are required';
    }
    return '';
}

export const validateLineChartData = (data) => {
    if (!data) {
        return 'Data is required';
    }
    if (!data.labels || data.labels.length === 0) {
        return 'Labels are required';
    }
    if (!data.datasets || data.datasets.length === 0) {
        return 'Datasets are required';
    }
    return '';
}

export const validateAreaChartData = (data) => {
    if (!data) {
        return 'Data is required';
    }
    if (!data.labels || data.labels.length === 0) {
        return 'Labels are required';
    }
    if (!data.datasets || data.datasets.length === 0) {
        return 'Datasets are required';
    }
    return '';
}


export const CHART_TYPES = {
    PIE: 'PIE',
    BAR: 'BAR',
    LINE: 'LINE',
    AREA: 'AREA',
    TABLE: 'TABLE'
}

export const ROW_TYPE = {
    FOUR_COL_CHART_ROW: 'FOUR_COL_NUM_ROW',
    THREE_COL_CHART_ROW: 'THREE_COL_CHART_ROW',
    TWO_COL_CHART_ROW: 'TWO_COL_CHART_ROW',
    ONE_COL_CHART_ROW: 'ONE_COL_CHART_ROW',
}

export const dashbaordDB = {
    id: 'db-intelligence',
    title: 'DB Intelligence',
    rows: [
        {
            id: 'row-1',
            type: ROW_TYPE.FOUR_COL_CHART_ROW,
            columns: [
                {
                    id: 'col-1',
                    chartType: 'PIE',
                    title: 'Pie Chart',
                    data: {
                        labels: ['A', 'B', 'C'],
                        datasets: [
                            {
                                data: [300, 50, 100],
                                backgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56'
                                ],
                                hoverBackgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56'
                                ]
                            }
                        ]
                    },
                    loader: false
                },
                {
                    id: 'col-2',
                    chartType: 'PIE',
                    title: 'Pie Chart',
                    data: {
                        labels: ['A', 'B', 'C'],
                        datasets: [
                            {
                                data: [300, 50, 100],
                                backgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56'
                                ],
                                hoverBackgroundColor: [
                                    '#FF6384',
                                    '#36A2EB',
                                    '#FFCE56'
                                ]
                            }
                        ]
                    },
                    loader: false
                }
            ]
        }
    ]
}