import { ChevronRight } from "react-feather";
import { Link, useNavigate } from "react-router-dom";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


function formatDate(dateString) {
    const date = new Date(dateString);
    return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
}
function formatAnalysisDate(dateString) {
    const date = new Date(dateString);
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
}
function toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}


export const columns = [
    {
        name: 'Client Name',
        selector: row => { console.log(row), row.customer_name },
        cell: row => toTitleCase(row.customer_name || ''),
        minWidth: '300px',

    },
    {
        name: 'Analysis Date',
        selector: row => row.created_at,
        sortable: true,
        minWidth: '200px',
        cell: row => formatDate(row.created_at || ''),
    },
    {
        name: 'Analysis Period',
        selector: row => row.statement_period?.from_date,
        minWidth: '300px',
        cell: row => (formatAnalysisDate(row.statement_period?.from_date || '') + " - " + formatAnalysisDate(row.statement_period?.to_date))
    },
    {
        name: 'Bank',
        selector: row => row.bank_details?.name,
        minWidth: '200px',
        cell: row => (
            <>
                <span className="bank-img">
                    <img src={row.bank_details.image_url || ''} alt="/" />
                </span>
                <span className="bank-name">{row.bank_details.name || ''}</span>
            </>
        )
    },
    {
        name: '',
        minWidth: '100px',
        cell: (row) => {
            const navigate = useNavigate();
            const handleNavigation = () => {
                console.log('clicked')
                navigate(`/upload-history/${row.id || ''}`, {
                    state: {
                        bankName: row?.bank_details?.name || 'Unknown Bank',
                    },
                });
            };

            return (
                <button onClick={handleNavigation} color="link" className="btn btn-link" >
                    <ChevronRight />
                </button>
            )
        }

    },
];