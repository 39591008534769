import React, { Component } from 'react';

// ** Reactstrap Imports
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Badge,  Button } from 'reactstrap'
import PageHeader from '../../../@core/components/PageHeader'
import ContainerHeader from '../../../@core/components/ContainerHeader'
import HandlePayment from '../../../@core/components/HandlePayment';

class Plan extends Component {

    render() {
        return (
            <Card>
                <PageHeader
                    pageName={"Billing & Usage"}
                    backButtonEnabled={true}
                />
                <div className='blog-edit-wrapper container-fluid vh-85'>
                    {/* <ContainerHeader
                        pageName={"Current Billing Details"}
                    /> */}
                    <Card>
                        <CardHeader className='border-bottom'>
                        <CardTitle tag='h4'>Current plan</CardTitle>
                        </CardHeader>
                        <CardBody className='my-2 py-25'>
                        <Row>
                            <Col md='6'>
                            <div className='mb-2 pb-50'>
                                <h5>
                                Your Current Plan is <strong>Basic</strong>
                                </h5>
                                <span>A simple start for everyone</span>
                            </div>
                            {/* <div className='mb-2 pb-50'>
                                <h5>Active until April 30, 2023</h5>
                                <span>We will send you a notification 10 days before subscription expiration</span>
                            </div>
                            <div className='mb-2 mb-md-1'>
                                <h5>
                                $30 Per Month{' '}
                                <Badge color='light-primary' className='ms-50'>
                                    Popular
                                </Badge>
                                </h5>
                                <span>Standard plan for small to medium businesses</span>
                            </div> */}
                            </Col>
                            <Col md='6'>
                            </Col>
                            <Col xs={12}>
                                {/* <Button color='primary' className='me-1 mt-1' onClick={() => {}}>
                                    Upgrade Plan
                                </Button> */}
                                {/* <HandlePayment/>
                                <Button outline color='danger' className='mt-1'>
                                    Cancel Subscription
                                </Button> */}
                                
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                </div>
            </Card>
          )
    }
}

export default Plan;
