import { Card } from "reactstrap"

const RecommendBlogCard = ({data})=>{
    return(
        <Card className="recommend-blog-card">
            <a className="recommend-blog-card__link" target="_blank" href={`https://beta.insightai.in/blogs/blog-single/${data.id || ''}`}>
                <h2>{data.title || ''}</h2>
                <p>The financial world today faces a lot of challenges, notably financial crimes such as money laundering and fraud. These issues are not just confined to small-scale operations; even large, established companies can find themselves embroiled in allegations. For example, in 2022, a major multinational corporation faced scrutiny from a national enforcement age</p>
                <span className="reading-time">{data.read_time || ''} read</span>
            </a>
        </Card>
    )
}

export default RecommendBlogCard;