import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
  // Session APIs
  uploadFile: "analytics/session/load_session_document/",
  fetchResponse: "analytics/session/fetch_query_answer/",
  loadAllSessions: "analytics/session/fetch_all_sessions/",

  // Document APIs
  loadSessionDocument: "analytics/document/fetch_documents_by_session?session_id={0}",

  // Message APIs
  loadSessionMessagea: "analytics/messages/fetch_chat_by_session?session_id={0}",
  
};

export const loadAllSessionsHandler = () => {
  return REQUESTS.get(API_ROUTES.loadAllSessions, localStorage.getItem("token"));
}

export const uploadDataFile = (payload) => {
  return REQUESTS.postFile(API_ROUTES.uploadFile, localStorage.getItem("token"), payload);
};

export const loadSessionDocumentHandler = (sessionId) => {
  return REQUESTS.get(API_ROUTES.loadSessionDocument.format(sessionId), localStorage.getItem("token"));
}

export const loadSessionMessagesHandler = (sessionId) => {
  return REQUESTS.get(API_ROUTES.loadSessionMessagea.format(sessionId), localStorage.getItem("token"));
}

export const fetchQueryResponseHandler = (payload) => {
  return REQUESTS.post(API_ROUTES.fetchResponse, localStorage.getItem("token"), payload);
}