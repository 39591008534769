import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
    newsFetch: 'blog/fetch_news_by_keyword/',
    blogFetch: 'blog/list_post',
}

export const newsFetchRequest = () => {
    return REQUESTS.get(API_ROUTES.newsFetch, localStorage.getItem('token'))
};

export const blogsFetchRequest = () => {
    return REQUESTS.get(API_ROUTES.blogFetch, localStorage.getItem('token'))
};