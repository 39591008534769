import Types from '../types';

const initialState = {
  payload: null,
};

export default function thirdPartyAuth(state = initialState, action) {
    switch (action.type) {
        case Types.THIRD_PARTY_AUTH:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
}