import Types from '../types';

const initialState = {
  payload: null,
  trendContentPayload: null,
  trendKeywordPayload: null,
};

export default function shortPostContent(state = initialState, action) {
    switch (action.type) {
        case Types.SHORT_CONTENT_GENERATED:
            return {
                ...state,
                payload: action.payload,
            };
        case Types.TREND_CONTENT_GENERATED:
            return {
                ...state,
                trendContentPayload: action.payload,
            };
        case Types.TREND_KEYWORD_GENERATED:
            return {
                ...state,
                trendKeywordPayload: action.payload,
            };
        default:
            return state;
    }
}