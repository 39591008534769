import './style.scss';

import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import {
    FormGroup, 
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    Spinner
} from "reactstrap";
import { Send, Upload, Globe, Trash2 } from 'react-feather';
import MarkdownParser from "../../../components/MarkdownTypingEditor";
import { statusCode } from "../../../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast, truncateStringToNPlace } from "../../../../utility/helper";
import { fetchUploadedDocumentDetails } from "../../../../utility/docFunctions";
import {
    uploadChatbotDataFile,
    listAllDocumentsHandler,
    fetchTestResponseHandler,
    fetchAllTestConvesationHandler,
    deleteFileHandler
} from '../../../../services/chatBot';
import { BarLoader, ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileExcel, faTable, faFileImage, faGlobe, faFileWord } from '@fortawesome/free-solid-svg-icons'
import whiteBotImage from "../../../../assets/logo/bot@2x.png"
import moment from 'moment';
import ConfirmationModal from '../../../components/ConfirmationModal';

const ChatBotConversation = (props) => {
    // Fetch data from proprs
    const { chatbotId } = props;
    const { session } = useParams();
    const firstName = localStorage.getItem("first_name");
    // Data variable
    const [chatbot_Id, setChatbotId] = useState(chatbotId);
    const [messages, setMessages] = useState([]); // These messages are loaded from th backend
    const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
    const [message, setMessage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [file, setFile] = useState(null);
    const [sessionId, setSessionId] = useState(session);
    const [inputUrl, setInputUrl] = useState('');
    const [websiteModalShow, setWebsiteModalShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [fileIdForDeletion, setFileIdForDeletion] = useState(null);
    const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(false);
    const fileInputRef = useRef(null);

    // Loaders
    const [documentLoader, setDocumentLoader] = useState(false);
    const [websiteLoader, setWebsiteLoader] = useState(false);
    const [isChatLoaded, setIsChatLoaded] = useState(false);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [isFetchingResponse, setIsFetchingResponse] = useState(false);

    // Reference Variables
    const documentUploadRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [isUserInputFocused, setIsUserInputFocused] = useState(false);


    "Initali Load of the document"
    "============================"
    useEffect(() => {
        if (chatbot_Id && chatbot_Id !== 'new-bot') {
            setMessages([{
                "id": '0001',
                "message": "Welcome back!!",
                "isBot": true,
                "time": new Date().toLocaleTimeString()
            }])
            loadChatbotDocuments();
            loadChatMessages();
        } else {
            setMessages([{
                "id": '0001',
                "message": "Hello! How can I assist you today?",
                "isBot": true,
                "time": new Date().toLocaleTimeString()
            }])
            setIsDocumentLoaded(true);
            setIsChatLoaded(true);
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const isSessionIdValid = () => {
        return chatbot_Id && chatbot_Id !== 'new-bot';
    }
    "Hanlders for document uplaod"
    "============================"

    const handleFileUpload = () => {
        if (!chatbot_Id || chatbot_Id === 'new-bot') {
            showErrorToast('Chatbot profile not created yet');
            return;
        }
        if (!file) {
            showErrorToast('Please select a file first!');
            return;
        }
        const fileDetails = fetchUploadedDocumentDetails(file);
        setDocumentLoader(true);
        const formData = new FormData();
        formData.append('document', file);
        formData.append('name', file.name);
        formData.append('chatbot', chatbot_Id);
        formData.append('document_type', fileDetails.fileCategory);
        formData.append('size', Math.ceil(fileDetails.fileSizeInKB));

        // addNewBotLoaderMessage('Please wait while I upload the document');
        uploadChatbotDataFile(formData)
            .then(response => {
                setMessagesList(messagesList.slice(0, messagesList.length - 1));
                if (response.status == statusCode.HTTP_201_CREATED) {
                    setFileList([...fileList, { ...response.data }]);
                    showSuccessToast('File uploaded successfully');
                    setFile(null);
                    // addNewBotMessage('Document uploaded successfully. You can now start querying on this document.');
                } else {
                    showErrorToast('Error uploading file');
                    // addNewBotMessage('Error uploading file. Please try again');
                }
                setDocumentLoader(false);
            })
            .catch((error) => {
                setMessagesList(messagesList.slice(0, messagesList.length - 1));
                console.error('Error:', error);
                addNewBotMessage('Error uploading file. Please try again');
                setDocumentLoader(false);
            });
    };

    const getDomainNameFromWebsiteUrl = (url) => {
        try {
            const urlObject = new URL(url);
            const hostname = urlObject.hostname;
            const modifiedHostname = hostname.replace(/\./g, '_');
            return modifiedHostname;
        } catch (error) {
            return null;
        }
    }

    const handleWebsiteUrlUpload = () => {
        if (!chatbot_Id || chatbot_Id === 'new-bot') {
            showErrorToast('Chatbot profile not created yet');
            return;
        }
        const isValid = /^(ftp|http|https):\/\/[^ "]+$/.test(inputUrl);
        if (!isValid) {
            setIsValidUrl(false);
            showErrorToast('Invalid URL');
            return;
        }

        setWebsiteModalShow(false);
        setWebsiteLoader(true);
        const websiteName = getDomainNameFromWebsiteUrl(inputUrl);
        const formData = new FormData();
        formData.append('name', websiteName);
        formData.append('chatbot', chatbot_Id);
        formData.append('url', inputUrl);
        formData.append('document_type', 'WEBSITE_URL');
        formData.append('size', 0);

        // addNewBotLoaderMessage('Please wait while I load the website data');
        uploadChatbotDataFile(formData)
            .then(response => {
                setMessagesList(messagesList.slice(0, messagesList.length - 1));
                if (response.status == statusCode.HTTP_201_CREATED) {
                    setFileList([...fileList, { ...response.data }]);
                    showSuccessToast('File uploaded successfully');
                    setInputUrl(null);
                    showSuccessToast('Website data uploaded successfully');
                    // addNewBotMessage('Website data uploaded successfully. You can now start querying on this document.');
                } else {
                    showErrorToast('Error uploading website data');
                    // addNewBotMessage('Error uploading website data. Please try again');
                }
                setWebsiteLoader(false);
            })
            .catch((error) => {
                setMessagesList(messagesList.slice(0, messagesList.length - 1));
                showErrorToast('Error uploading website data');
                // addNewBotMessage('Error uploading website data. Please try again');
                setWebsiteLoader(false);
            });
    }

    const loadFile = (e) => {
        const file = e.target.files[0];
        const fileDetails = fetchUploadedDocumentDetails(file);
        // fileSizeInKB should be less than 5 mb
        if (fileDetails.fileSizeInKB > 5000) {
            showErrorToast('File size should be less than or equal to 5MB');
            return;
        }
        if (fileDetails.fileType == 'UNKNOWN') {
            showErrorToast('File type not supported. Please upload a valid document');
            return;
        }

        if (fileDetails.fileType == 'WEBP') {
            showErrorToast('Only PNG and JPEG image supported. Please upload a valid image file');
            return;
        }
        setFile(file);
    }

    const removeFileFromDB = () => {
        if(!fileIdForDeletion) {
            showErrorToast('Error deleting file');
            console.log("File ID not found")
            return;
        }
        setIsDocumentLoaded(false);
        deleteFileHandler(fileIdForDeletion)
        .then(response => {
            setIsDocumentLoaded(false);
            setFileIdForDeletion(null);
            if (response.status == statusCode.HTTP_200_OK) {
                showSuccessToast('File deleted successfully');
                setFileList(fileList.filter(file => file.id !== fileIdForDeletion));
            } else {
                showErrorToast('Error deleting file');
            }
        })
        .catch((error) => {
            setFileIdForDeletion(null);
            setIsDocumentLoaded(false);
            showErrorToast('Error deleting file');
        })
    }

    const initLoadDocument = () => {
        if (fileList.length == 5) {
            // addNewBotMessage('You have reached the maximum limit of 5 documents. You may use a new session to upload more documents.');
            showSuccessToast('You have reached the maximum limit of 5 documents. You may use a new session to upload more documents.');
            const scrollTimeout = setTimeout(() => {
                scrollToBottom()
                clearTimeout(scrollTimeout);
            }, 0);
        } else {
            fileInputRef.current.click();
        }
    }

    const loadChatbotDocuments = () => {
        listAllDocumentsHandler(chatbot_Id)
            .then(response => {
                if (response.status == statusCode.HTTP_200_OK) {
                    setFileList(response.data.data);
                    setIsDocumentLoaded(true);
                } else {
                    showErrorToast('Error loading documents. Please refresh the page and try again');
                }
            })
    }

    const renderDocumentIcon = (documentType) => {
        switch (documentType) {
            case 'PDF':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFilePdf} />
            case 'XLSX':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileExcel} />
            case 'DOCX':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileWord} />
            case 'DOC':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileWord} />
            case 'XLS':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileExcel} />
            case 'PNG':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} />
            case 'JPEG':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} />
            case 'WEBP':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFileImage} />
            case 'WEBSITE_URL':
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faGlobe} />
            default:
                return <FontAwesomeIcon style={{ marginRight: "10px", width: '20px' }} icon={faFilePdf} />
        }
    }

    const renderDocument = () => {
        if (!isDocumentLoaded) {
            return (<div style={{ textAlign: 'center' }}>Loading Docuement...</div>)
        } else if (isDocumentLoaded && fileList.length === 0) {
            return (<div style={{ textAlign: 'center' }}>No document available</div>)
        } else if (isDocumentLoaded && fileList.length > 0) {
            return (<>
                {fileList.map((file, index) => {
                    return (<li key={index} style={{ color: '#0C6980', paddingRight: '30px', fontSize: '18px', position: 'relative' }} className="document-list-item">
                        <div style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}>{renderDocumentIcon(file.document_type)} {file.name}</div>
                        <button 
                            className='cancel-btn' 
                            onClick={()=>{
                                console.log("File ID: ", file)
                                setFileIdForDeletion(file.id)
                                handleDOcumentDelete()
                            }}><Trash2 />
                        </button>
                    </li>
                    )
                })}
            </>)
        }
    }

    const handleDOcumentDelete = ()=>{
        setShowConfirmation(prev => !prev);
    }

    const handleUrlInputChange = (event) => {
        setIsValidUrl(true);
        setInputUrl(event.target.value);
    };

    const renderPromptModal = () => {
        if (websiteModalShow) {
            return (
                <Modal style={{ zIndex: '100' }} isOpen={websiteModalShow}>
                    <ModalHeader>{`Enter Website URL`}</ModalHeader>
                    <ModalBody>
                        <Input type="text" value={inputUrl} onChange={handleUrlInputChange} />
                        {!isValidUrl && <div style={{ color: 'red', marginTop: '10px' }}>Invalid URL!</div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleWebsiteUrlUpload}>Proceed</Button>{' '}
                        <Button color="secondary" onClick={() => { setWebsiteModalShow(false) }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    "Hanlders for chat messages"
    "=========================="
    const scrollToBottom = () => {
        const scroll = chatContainerRef.current;
        if (scroll) {
            scroll.scrollTo({
                top: scroll.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const loadChatMessages = () => {
        fetchAllTestConvesationHandler(chatbot_Id)
            .then(response => {
                setIsChatLoaded(true);
                if (response.status == statusCode.HTTP_200_OK) {
                    setMessages([...messages, ...response.data.data]);
                } else {
                    setMessages([...messages]);
                }
            })
            .catch((error) => {
                setIsChatLoaded(true);
                setMessages([...messages]);
            })
    }

    const handleBotReceivedMessage = (message) => {
        const messagePayload = {
            message: message,
            isBot: true,
            time: moment().format("hh:mm A"),
        }
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, messagePayload];
            return updatedMessages;
        });
        setIsFetchingResponse(false);
        scrollToBottom();
    }

    const handleSendMessage = () => {
        const messagePayload = {
            message: message,
            isBot: false,
            time: moment().format("hh:mm A"),
        }
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, messagePayload];
            return updatedMessages;
        });
        const scrollTimeout = setTimeout(() => {
            scrollToBottom();
            clearTimeout(scrollTimeout)
        }, 500)
        fetchBotResponse(message);
        setMessage("");
    }

    const fetchBotResponse = (query) => {
        if (!isSessionIdValid()) {
            handleBotReceivedMessage("You have not uploaded any document yet. Please upload a document first.");
            return;
        }
        setIsFetchingResponse(true);
        fetchTestResponseHandler({
            bot: chatbot_Id,
            query: query,
            advanced_search: advancedSearchEnabled
        })
            .then((response) => {
                if (response.status == statusCode.HTTP_200_OK) {
                    handleBotReceivedMessage(response.data.message);
                } else {
                    handleBotReceivedMessage("I am currently facing some issues. Please try again later.");
                }
            })
            .catch((error) => {
                handleBotReceivedMessage("I am currently facing some issues. Please try again later.");
            });
    }

    const handleTextMessage = (e) => { setMessage(e.target.value) }

    const handleKeyPress = async (event) => {
        if (event.key === "Enter") {
            event.preventDefault();  // Prevent the default behavior of a line break
            handleSendMessage(event);  // Replace this with your actual send message function
        }
    };

    const handleFocus = () => setIsUserInputFocused(true);

    const handleBlur = () => setIsUserInputFocused(false);

    "Here new methods for message rendering will be present"
    "====================================================="

    const renderLoaderMessage = (message, loaderType) => {
        let loaderMessage = null;
        if (loaderType == "OLD_CHAT") {
            loaderMessage = <>
                Loading previous chat messages <BarLoader width="250" cssOverride={{ marginTop: '10px' }} color="#1972f5" />
            </>;
        } else {
            loaderMessage = <>
                Checking for your query <BarLoader width="250" cssOverride={{ marginTop: '10px' }} color="#1972f5" />
            </>;
        }
        return (
            <div
                key={message}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    marginRight: "10px",
                    marginBottom: "25px",
                }}
            >
                <div style={{ height: "30px", width: "30px", borderRadius: '5px' }}>
                    <img src={whiteBotImage} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </div>
                <div style={{
                    background: "#FFFFFF",
                    color: "#000",
                    padding: "10px",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    maxWidth: "70%",
                    textAlign: "left",
                    fontSize: "0.9em",
                }}>
                    {loaderMessage}
                </div>
            </div>
        )
    }

    const renderBotMessage = (message) => {
        return (
            <div key={message}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: 'flex-end',
                    marginRight: "10px",
                    marginBottom: "25px",
                    fontWeight: 'bold',
                }}>
                <div style={{ height: "30px", width: "30px", borderRadius: '5px' }}>
                    <img src={whiteBotImage} style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </div>
                <div style={{
                    background: "#E0F2F1",
                    color: "#000",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "10px 10px 10px 0",
                    marginLeft: "10px",
                    maxWidth: "80%",
                    textAlign: "left",
                    fontSize: "1.1em",
                    fontWeight: "600"
                }}>
                    {<MarkdownParser isTyping={false} texts={[message]} />}
                </div>
            </div>
        )
    }

    const renderUserMessage = (message) => {
        return (
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: 'flex-start',
                marginRight: "10px",
                marginBottom: "25px",
            }}>
                <div style={{
                    background: "#0C6980",
                    color: "#ffffff",
                    padding: "10px",
                    borderRadius: "10px 0 10px 10px ",
                    marginRight: "10px",
                    maxWidth: "70%",
                    textAlign: "right",
                    fontSize: "1em",
                }}>
                    {message}
                </div>
            </div>
        )
    }

    const renderMessages = () => {
        const messageArray = messages.map((message, index) => {
            if (message.isBot) {
                return renderBotMessage(message.message);
            } else {
                return renderUserMessage(message.message);
            }
        });
        if (isFetchingResponse) {
            messageArray.push(renderLoaderMessage())
        }
        if (!isChatLoaded) {
            messageArray.push(renderLoaderMessage("Loading previous chat messages", "OLD_CHAT"))
        }
        return messageArray
    }

    return (
        <div style={{ height: '70vh' }} className='conversation-wrapper'>
            <div style={{ padding: '15px' }} className="conversation-wrapper__left">
                <div className='conversation-wrapper__top'>
                    <span>Chatbot Knowledge Base</span>
                </div>
                <ul style={{ height: '50vh' }} className="document-list">
                    {renderDocument()}
                </ul>
                {/* Action Buttons */}
                <div className='conversation-wrapper__bottom'>
                    <input ref={fileInputRef} type="file" onChange={loadFile} style={{ display: 'none' }} />
                    {
                        file && !documentLoader &&
                        <button className="upload-btn"
                            onClick={handleFileUpload}
                            style={{
                                background: '#A5D6A7',
                                color: '#00695C',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                marginBottom: '5px'
                            }}><Upload size={17} style={{ marginRight: '5px' }} /> Upload: {truncateStringToNPlace(file.name, 7)}</button>
                    }
                    {/* Navigations */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '49%' }}>
                            {
                                !documentLoader &&
                                <button className="upload-btn"
                                    onClick={initLoadDocument}
                                    style={{
                                        background: '#A5D6A7',
                                        color: '#00695C',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                    }}><Upload size={"15px"} style={{ marginRight: '5px', marginBottom: '2px' }} /> Document</button>
                            }
                            {
                                documentLoader &&
                                <button className="upload-btn"
                                    onClick={initLoadDocument}
                                    style={{
                                        background: '#A5D6A7',
                                        color: '#00695C',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                    }}>Uploading <ClipLoader size={"sm"} /></button>
                            }
                        </div>
                        <div style={{ width: '49%' }}>
                            {
                                !websiteLoader &&
                                <button className="upload-btn"
                                    onClick={() => setWebsiteModalShow(true)}
                                    style={{
                                        background: '#A5D6A7',
                                        color: '#00695C',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                    }}><Globe size={"15px"} style={{ marginRight: '5px', marginBottom: '2px' }} /> Website URL</button>
                            }
                            {
                                websiteLoader &&
                                <button className="upload-btn"
                                    onClick={initLoadDocument}
                                    style={{
                                        background: '#A5D6A7',
                                        color: '#00695C',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}>Loading Website <ClipLoader size={"sm"} /></button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='conversation-wrapper__right'>
                <div className='advanced_toggle'>
                    <FormGroup switch>
                        <Label 
                            style={{
                                fontSize:'17px', 
                                marginLeft:'20px', 
                                color: advancedSearchEnabled ? "#1972f5" : "#78909C"
                            }} check>
                            Advanced Search
                        </Label>
                        <Input 
                            style={{cursor: 'pointer'}} 
                            onChange={()=>{
                                setAdvancedSearchEnabled(!advancedSearchEnabled)
                            }} 
                            type="switch" 
                            role="switch" 
                        />
                    </FormGroup>
                </div>
                <div className="conversation-wrapper__tabpane">
                   
                    <div
                        ref={chatContainerRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            overflowY: "scroll"
                        }}
                    >
                        {renderMessages()}
                    </div>
                    <div className='conversation-pane'>
                        <Form>
                            <Input
                                id="bot-message-input"
                                onChange={handleTextMessage}
                                onKeyPress={handleKeyPress}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={message}
                                name="message"
                                placeholder="Ask any question pertaining to your document...."
                            />
                            <button onClick={handleSendMessage} className='icon' ><Send /></button>
                        </Form>
                    </div>
                </div>
                {renderPromptModal()}
            </div>
            <ConfirmationModal 
                open={showConfirmation} 
                // toggle={handleDOcumentDelete} 
                onConfirm={()=>{
                    removeFileFromDB()
                    setShowConfirmation(false)
                }}
                onCancel={()=>{
                    setShowConfirmation(false)
                    setFileIdForDeletion(null)
                }}
                title={'Are you sure you want to delete this document? This action cannot be undone.'}
            />
        </div>
    )
}

export default ChatBotConversation;