import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardText, CardTitle, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { sendVerifyEmailHandler } from "../../../services/user";
import { statusCode } from "../../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast } from "../../../utility/helper";
import InsightAILogo from "@src/assets/images/logo/LogoDark@2x.png";
import "@styles/react/pages/page-authentication.scss";
import { ONBOARDING_STATUS } from "../../../utility/constants/socialMediaPlatform";
import { ChevronRight } from "react-feather";
import { ClipLoader } from "react-spinners";


const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [emailLoader, setEmailLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("email")) {
      setEmail(window.localStorage.getItem("email"));
    } else {
      setEmail("");
    }
  }, []);

  const handleSkipEmail = () => {
    navigate("/dashboard");
    // const status = window.localStorage.getItem("status");
    // if (status && status === ONBOARDING_STATUS.ONBOARDING_IN_PROCESS) {
    //   navigate("/register");
    // } else if (status && status === ONBOARDING_STATUS.ACTIVE) {
    //   navigate("/dashboard");
    // }
  };

  const handleResendEmail = () => {
    setEmailLoader(true);
    const status = window.localStorage.getItem("status");
    if (email) {
      sendVerifyEmailHandler({
        email: email,
        origin: window.location.origin,
        request_type: "confirm_email"
      })
        .then((res) => {
          setEmailLoader(false);
          if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
            showSuccessToast("Verification email sent");
            // if (status && status === ONBOARDING_STATUS.ONBOARDING_IN_PROCESS) {
            //   setTimeout(() => {
            //     navigate("/register");
            //   }, 1000);
            // } else if (status && status === ONBOARDING_STATUS.ACTIVE) {
            //   setTimeout(() => {
            //     navigate("/dashboard");
            //   }, 1000);
            // }
          } else {
            return showErrorToast("Could not send verification email, Please try again after sometime");
          }
        }).catch((error) => {
        setEmailLoader(false);
        return showErrorToast("Could not send verification email, Please try again after sometime");
      });
    } else {
      return showErrorToast("Email not found");
    }
  };

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <CardBody>
            <Link className="brand-logo" to="/" onClick={e => e.preventDefault()}>
              <img src={InsightAILogo} style={{ width: "140px", height: "30px", marginBottom:'8%' }} alt={"logo"} />
            </Link>
            <CardTitle tag="h2" className="fw-bolder text-center mb-1">
              Verify your email ✉️
            </CardTitle>
            <CardText className="mb-2 text-center">
            We have sent a verification email to your registered email address : <span className="fw-bolder">{email}</span>. Please check your inbox and click on the verification link to proceed. 
              <span className="fw-bolder" style={{display: 'block', fontSize: '14px', marginTop: '10px', color: '#44ace4'}}>If you do not see the email in your inbox, please check your Junk or Spam folders. Sometimes our emails may end up there by mistake.</span>
            </CardText>

            {emailLoader ?
              <div className="sipnner-container">
                <ClipLoader />
              </div> :
              <>
                <Button block color="primary" className={emailLoader ? "disabled-link" : ""} onClick={handleSkipEmail}>
                  <span className="align-middle d-sm-inline-block d-none">Skip for now</span>
                  <ChevronRight size={14} className="align-middle ms-sm-25 ms-0"></ChevronRight>
                </Button>
                <p className="text-center mt-2">
                  <span>Didn't receive an email? </span>
                  <a href={"#"} className={emailLoader ? "disabled-link" : ""} onClick={handleResendEmail}>
                    <span>Resend</span>
                  </a>
                </p>
              </>
            }

          </CardBody>
        </Card>
      </div>
    </div>
  );
};
export default VerifyEmail;



