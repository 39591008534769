const NewsCard = ({data}) => {
    return (
        <div className="news-card">
            <a href={data.url || ''} target="_blank" className="news-card__link">
                <div className="news-card__img">
                    <img src={data?.urlToImage || ''} alt="/" />
                </div>
                <div className="news-card__content">
                    <h2 className="news-card__title">{data?.title || ''}</h2>
                    <p>{data?.description || ''}</p>
                </div>
            </a>
        </div>
    )
}

export default NewsCard;