export const analyticsData = {
    "id": "analytics-dashboard-1",
    "title": "Monthly Sales Overview",
    "query_type": "summary",
    "publishedDate": "2024-08-01",
    "rows": [
        {
            "id": "row-1",
            "db_id": "db-001",
            "type": "FOUR_COL_NUM_ROW",
            "columns": [
                {
                    "id": "col-1",
                    "title": "Average Sales",
                    "query_type": "single_value",
                    "data": 42
                },
                {
                    "id": "col-2",
                    "title": "Customers",
                    "query_type": "single_value",
                    "data": 557
                },
                {
                    "id": "col-3",
                    "title": "Revenue Generated",
                    "query_type": "single_value",
                    "data": "$99,150"
                },
                {
                    "id": "col-4",
                    "title": "Customer Satisfaction",
                    "query_type": "single_value",
                    "data": "4.8/5"
                }
            ]
        },
        {
            "id": "row-2",
            "db_id": "db-002",
            "type": "THREE_COL_CHART_ROW",
            "columns": [
                {
                    "id": "col-1",
                    "title": "Sales by Region",
                    "query_type": "chart",
                    "chartType": "BAR",
                    "data": {
                        "labels": ["North", "South", "East", "West"],
                        "datasets": [
                            {
                                "label": "Sales",
                                "data": [5000, 7000, 3000, 4000],
                                "backgroundColor": ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
                            }
                        ]
                    }
                },
                {
                    "id": "col-2",
                    "title": "Revenue Growth",
                    "query_type": "chart",
                    "chartType": "LINE",
                    "data": {
                        "labels": ["January", "February", "March", "April"],
                        "datasets": [
                            {
                                "label": "Revenue",
                                "data": [15000, 17000, 13000, 19000],
                                "borderColor": "#36A2EB",
                                "fill": false
                            }
                        ]
                    }
                },
                {
                    "id": "col-3",
                    "title": "Customer Satisfaction Over Time",
                    "query_type": "chart",
                    "chartType": "AREA",
                    "data": {
                        "labels": ["January", "February", "March", "April"],
                        "datasets": [
                            {
                                "label": "Satisfaction Score",
                                "data": [4.5, 4.6, 4.4, 4.7],
                                "backgroundColor": "rgba(54, 162, 235, 0.2)",
                                "borderColor": "#36A2EB",
                                "fill": true
                            }
                        ]
                    }
                }
            ]
        },
        {
            "id": "row-3",
            "db_id": "db-003",
            "type": "TWO_COL_CHART_ROW",
            "columns": [
                {
                    "id": "col-1",
                    "title": "Product Category Distribution",
                    "query_type": "chart",
                    "chartType": "PIE",
                    "data": {
                        "labels": ["Electronics", "Apparel", "Home Goods", "Books"],
                        "datasets": [
                            {
                                "data": [300, 200, 150, 100],
                                "backgroundColor": ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"]
                            }
                        ]
                    }
                },
                {
                    "id": "col-2",
                    "title": "Top 5 Products",
                    "query_type": "chart",
                    "chartType": "BAR",
                    "data": {
                        "labels": ["Product A", "Product B", "Product C", "Product D", "Product E"],
                        "datasets": [
                            {
                                "label": "Sales",
                                "data": [1000, 2000, 1500, 1200, 1800],
                                "backgroundColor": "#FFCE56"
                            }
                        ]
                    }
                }
            ]
        },
        {
            "id": "row-4",
            "db_id": "db-004",
            "type": "ONE_COL_CHART_ROW",
            "columns": [
                {
                    "id": "col-1",
                    "title": "Sales by Product",
                    "query_type": "table",
                    "chartType": "TABLE",
                    "data": {
                        "headers": ["Product", "Units Sold", "Revenue"],
                        "rows": [
                            ["Product A", "1000", "$10000"],
                            ["Product B", "750", "$7500"],
                            ["Product C", "500", "$5000"]
                        ]
                    }
                }
            ]
        }
    ]
}
