import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

//progressbar
import { Card, CardBody, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { FaCheckCircle } from "react-icons/fa";

import "./style.scss";


const StatementProcess = (props) => {
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const navigate = useNavigate();

  const { bankResponse, loading, error } = useSelector(state => ({
    bankResponse: state.bankStatementReducer.bankResponse,
    loading: state.bankStatementReducer.loading,
    error: state.bankStatementReducer.error,
  }));


  const { selectedItem, files, resposeData } = props;

  useEffect(() => {
    if (!loading && Object.keys(bankResponse).length > 0) {
      setCompleted(true);
      navigate('/upload-history', { state: { resposeData: bankResponse, selectedItem: selectedItem } }); // Use actual path and state
    }
  }, [loading, bankResponse, error, navigate, selectedItem]);

  useEffect(() => {
    let timer;

    if (loading) {
      setCompleted(false);
      setProgress(0);

      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
    } else {
      // When loading is false, set progress to 100 and mark as completed
      setProgress(100);
      setCompleted(true);

      if (timer) {
        clearInterval(timer);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };

  }, [loading]);

  //files
  useEffect(() => {
    if (files) {
      files.map((item, i) => {
        return (
          setDocumentName(item.name)
        );
      });
    }

  }, [files]);


  return (
    <>

      {
        selectedItem ?
          (<div className="document-process">
            {!completed && (
              <div className="bank-details d-flex justify-content-center align-items-center my-2">
                <img src={selectedItem?.image_url || ""} className="w-5" />
                <h2 className="">{selectedItem?.name || ""}</h2>
              </div>
            )}

            {
              loading &&
              <>
                <div className="document-process__notification">
                  <h2 className="document-process__title">Processing Your Statement...</h2>
                  <p>We will let you know, when we are done</p>
                </div>
                <div className="my-3  process-indicator">
                  <div className="progress-container">
                    <Progress
                      className="mb-0 document-progressbar"
                      color={completed ? "success" : "primary"}
                      value={progress}
                    />
                  </div>
                  <div className="process-info">
                    <h5>{documentName || ""}</h5>

                    {completed ? (
                      <span className="completed-text "><FaCheckCircle /></span>
                    ) : (
                      ''
                    )}
                  </div>

                </div>
              </>
            }

            {error && Object.keys(error).length > 0 && (
              <h2 className="error-msg">{error.data?.error || error?.statusText || 'No data Found !'}</h2>
            )}

          </div>)
          :
          <Card>
            <CardBody>
              <h2 className="text-center">No Documents Uploaded</h2>
            </CardBody>
          </Card>
      }
    </>
  );
};

export default StatementProcess;