import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
// import SquareBlock from '../../assets/images/icons/squares-block.png';
import { Grid, Database, Table } from 'react-feather';
import { testSchema } from '../../utility/testDB/testSchema';

import './style.scss';

const NestedAccordion = ({schemaData}) => {
    const [openFirstLevel, setOpenFirstLevel] = useState('1');
    const [openSecondLevel, setOpenSecondLevel] = useState('');
    const [openThirdLevel, setOpenThirdLevel] = useState('');

    const toggleFirstLevel = (id) => {
        if (openFirstLevel === id) {
            setOpenFirstLevel('');
        } else {
            setOpenFirstLevel(id);
        }
    };

    const toggleSecondLevel = (id) => {
        if (openSecondLevel === id) {
            setOpenSecondLevel('');
        } else {
            setOpenSecondLevel(id);
        }
    };

    const toggleThirdLevel = (id) => {
        if (openThirdLevel === id) {
            setOpenThirdLevel('');
        } else {
            setOpenThirdLevel(id);
        }
    };

    const renderSchema = () => {
        const schemaArray = []
        const tableArray = []
        schemaArray.push(
            <AccordionHeader targetId="1" className="db-accordion__first">
                <Database size={16} style={{ marginRight: '10px' }} />
                {schemaData.database}
            </AccordionHeader>
        )

        schemaData.tables.forEach((table, index) => {
            let columnArray = []
            table.column_names.forEach((column, indexColumn) => {
                columnArray.push(
                    <AccordionBody accordionId={`1-${index}`}>
                        <Grid size={16} style={{ marginRight: '10px', marginLeft: '10px' }} />
                        {column}
                    </AccordionBody>
                )
            })

            tableArray.push(
                <AccordionItem>
                    <AccordionHeader targetId={`1-${index}`} className="db-accordion__second">
                        <Table size={16} style={{ marginRight: '10px' }} />
                        {table.table_name}
                    </AccordionHeader>
                    {columnArray}
                </AccordionItem>
            )
        })
        schemaArray.push(
            <AccordionBody accordionId="1">
                <Accordion open={openSecondLevel} toggle={toggleSecondLevel} >
                    {tableArray}
                </Accordion>
            </AccordionBody>
        )
        return schemaArray
    }

    return (

        <Accordion open={openFirstLevel} toggle={toggleFirstLevel} className="db-accordion">
            <AccordionItem>
                {renderSchema()}
            </AccordionItem>
        </Accordion>
    );
};

export default NestedAccordion;
