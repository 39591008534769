export const dbConfiguration ={
    "MySQL": {
        "host": "string",
        "port": "integer",
        "username": "string",
        "password": "string",
        "database": "string"
    },
    "PostgreSQL": {
        "host": "string",
        "port": "integer",
        "username": "string",
        "password": "string",
        "database": "string"
    },
    "Redshift": {
        "host": "string",
        "port": "integer",
        "username": "string",
        "password": "string",
        "database": "string",
        "cluster_identifier": "string"
    },
    "MongoDB": {
        "host": "string",
        "port": "integer",
        "username": "string (optional)",
        "password": "string (optional)",
        "database": "string"
    },
    "SQLite": {
        "file_path": "string"
    },
    "Oracle": {
        "host": "string",
        "port": "integer",
        "sid": "string",
        "username": "string",
        "password": "string"
    },
    "SQLServer": {
        "host": "string",
        "port": "integer",
        "username": "string",
        "password": "string",
        "database": "string"
    },
    "Cassandra": {
        "contact_points": "string (comma-separated list)",
        "port": "integer",
        "keyspace": "string",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "Elasticsearch": {
        "host": "string",
        "port": "integer",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "BigQuery": {
        "project_id": "string",
        "dataset_id": "string",
        "service_account_key_file": "string"
    },
    "Snowflake": {
        "account_identifier": "string",
        "username": "string",
        "password": "string",
        "database": "string",
        "schema": "string",
        "warehouse": "string",
        "role": "string (optional)"
    },
    "Teradata": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string",
        "password": "string"
    },
    "Db2": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string",
        "password": "string"
    },
    "DynamoDB": {
        "region": "string",
        "access_key_id": "string",
        "secret_access_key": "string",
        "table_name": "string"
    },
    "HBase": {
        "zookeeper_quorum": "string (comma-separated list)",
        "port": "integer",
        "table_name": "string"
    },
    "Hive": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "Impala": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "Neo4j": {
        "bolt_url": "string",
        "username": "string",
        "password": "string"
    },
    "Redis": {
        "host": "string",
        "port": "integer",
        "password": "string (optional)",
        "database_index": "integer (default 0)"
    },
    "InfluxDB": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "CouchDB": {
        "host": "string",
        "port": "integer",
        "database": "string",
        "username": "string (optional)",
        "password": "string (optional)"
    },
    "Firebase": {
        "database_url": "string",
        "service_account_key_file": "string"
    }
}