import BarCard from "./Bar";
import LineCard from "./LineCard";
import AreaCard from './AreaCard';
import PieChartCard from "./PieChartCard";
import NewTable from "./NewTables";
const Charts = () =>{
    return(
        <div>
            Charts

            <BarCard />
            <LineCard />
            <AreaCard />
            <PieChartCard />
            <NewTable />
        </div>
    )
}

export default Charts;