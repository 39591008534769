import React, { useEffect } from "react"
import { connect } from "react-redux";
import { setNavbarContent } from "../../../redux/actions";

/**
 * Should take in the icon and name and retrun on click
 */
const PageHeader = (props) => {
    const {
        pageName, 
        backButtonEnabled, 
        headerBtnEnabled,
        headerBtnName,
        headerBtnClickHandler
    } = props
    useEffect(() => { 
        props.setNavbarContent({
            pageName: pageName,
            backButtonEnabled: backButtonEnabled?backButtonEnabled:false,
            headerBtnEnabled: headerBtnEnabled?headerBtnEnabled:false,
            headerBtnName: headerBtnName?headerBtnName:null,
            headerBtnClickEnabled: false
        })
    }, [])

    if(props.navbarPayload && props.navbarPayload.headerBtnClickEnabled){
        if(headerBtnClickHandler){ headerBtnClickHandler() }
        props.setNavbarContent({
            ...props.navbarPayload,
            headerBtnClickEnabled: false
        })
    }

    return(<></>)
}

const mapDispatchToProps = {
    setNavbarContent
};

const mapStateToProps = ({ navbarContent }) => {
  return {
    navbarPayload: navbarContent.payload,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);