import React, { useState, useEffect } from 'react';
import { Spinner } from "reactstrap";

const TypingEffect = ({ texts, speed = 20 , addLoader = false}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        if (texts.length === 0) return;

        const currentText = texts[currentIndex % texts.length];
        if (!currentText) return;

        if (displayedText.length < currentText.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedText(currentText.slice(0, displayedText.length + 1));
            }, speed);
            return () => clearTimeout(timeoutId);
        } else if (currentIndex < texts.length - 1) {
            // Move to the next text when the current text is fully displayed
            const timeoutId = setTimeout(() => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
                setDisplayedText("");
            }, speed * 10); // Adjust the delay before moving to the next text
            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, displayedText, speed, texts]);

    return (
        <p style={{wordBreak:'break-word'}}>
            {displayedText}
            {addLoader && texts[0].length == displayedText.length && 
                <>
                    <Spinner style={{marginLeft: '15px'}} type="grow" size={"sm"}/>
                    <Spinner style={{marginLeft: '5px'}} type="grow" size={"sm"}/>
                    <Spinner style={{marginLeft: '5px'}} type="grow" size={"sm"}/>
                </>
            }
        </p>
    );
};

export default TypingEffect;
