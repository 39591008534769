// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'


export const authSlice = createSlice({
  name: 'authentication',
  reducers: {
    handleLogin: (state, action) => {
    },
    handleLogout: state => {
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
