import React, { useState, useEffect } from 'react'

import searchIcon from "../../../assets/logo/searchIcon.gif";
import calender from "../../../assets/logo/calendar.gif";
import note from "../../../assets/logo/note.gif";
import rocket from "../../../assets/logo/rocket.gif";
import { useDispatch } from 'react-redux'

import "./infoLoader.scss";
import { toggleInfoLoader } from '../../../redux/layout';

const InfoLoader = () => {
	const [index, setIndex] = useState(0);
	const dispatch = useDispatch()

	const payload = [{
		header: "Analysing information provided",
		para: "We are analysing all information you provided about goal, audience and brand.",
		img: searchIcon,
	},
	{
		header: "Identifying content for platforms targeting",
		para: "Finding content for marketing channel provided as per your audience",
		img: note,
	},
	{
		header: "Generating content and post flow",
		para: "Building content which can boost your brand marketing and give you desired results",
		img: rocket,
	},
	{
		header: "Finalizing your marketing plan",
		para: "Building post calendar, where all post will be scheduled as per best date and time.",
		img: calender,
	},]

	useEffect(() => {
		const timer = setTimeout(() => {
			setIndex(index + 1);
		}, 7500);

		if (index >= 4) {
			dispatch(toggleInfoLoader(false));
		}

		return () => clearTimeout(timer);
	}, [index]);

	return (
		<React.Fragment>
			{index < 4 ?
				<React.Fragment>
					<div className={"infoLayover"} />
					<div className={"centerInfoLayover"}>
						<div className={"infoContainer"}>
							<img className={"imgContianer"} src={payload[index].img} />
							<h2 className={"infoHeader"}>{payload[index].header}</h2>
							<p className={"infoPara"}>{payload[index].para}</p>
						</div>
					</div>
				</React.Fragment> : null}
		</ React.Fragment>
	)
}

export default InfoLoader
