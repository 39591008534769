import Types from '../types';

const initialState = {
  botDataList: [],
  botScript: null,
  botData: {},
  loader: false,
};

export default function botReducer(state = initialState, action) {
  switch (action.type) {
    case Types.BOT.SUCCESS:
      return {
        ...state,
        botDataList: action.payload,
        loader: false,
      };

    case Types.BOT.LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    case Types.BOT.GET_BOT:
      return {
        ...state,
        botData: action.payload,
      };

    case Types.BOT.UPDATE_BOT:
      return {
        ...state,
        botData: action.payload,
      };

    case Types.BOT.SCRIPT:
      return {
        ...state,
        botScript: action.payload,
      };

    case Types.BOT.SCRIPT_CLEAR:
      return {
        ...state,
        botScript: action.payload,
        botData: {},
      };

    default:
      return state;
  }
}
