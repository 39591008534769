import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
  bankListFetch: "analytics/bank_statement_analysis/fetch_banks_list?country={0}",
  bankStatementParser: "analytics/bank_statement_analysis/bank_statement_parser/",
  bankStatementHistory: "analytics/bank_statement_analysis/fetch_dashboard_metrics/",
  bankSingleHistory: "analytics/bank_statement_analysis/fetch_processed_response/?statement_id={0}"

};

export const bankListRequest = (countryName) => {
  return REQUESTS.get(API_ROUTES.bankListFetch.format(countryName), localStorage.getItem("token"));
};

export const bankStatementRequest = (payload) => {
  return REQUESTS.postFile(API_ROUTES.bankStatementParser, localStorage.getItem("token"), payload, {
    validateStatus: function(status) {
      return status >= 200 && status < 510;
    }
  });
};

export const bankStatementHistoryRequest = () => {
  return REQUESTS.get(API_ROUTES.bankStatementHistory, localStorage.getItem("token"));
};
export const bankStatementSingleHistoryRequest = (statement_id) => {
  return REQUESTS.get(API_ROUTES.bankSingleHistory.format(statement_id), localStorage.getItem("token"));
};