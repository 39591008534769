// ** Icons Import
import Dashboard from "../../assets/logo/fintech-dashboard.png";
import Knowledge from "../../assets/logo/Knowledge.png";
import Ledger from "../../assets/icons/ledger.png";
import Bot from "../../assets/images/bot/robot.png";
import Support from '../../assets/logo/support.png';
import DB from "../../assets/icons/DB.png";



const navIcon = (source) => (
  <img src={source} style={{ width: "20px", height: "20px" }} alt={''}/>
)

const navIconGroup = (source) => (
  <img src={source} style={{ width: "20px", height: "20px", marginLeft: '-5px', marginRight: '5px' }} alt={''}/>
)

let dashboard = [
  {
    id: 'cs-dashbaord',
    title: 'Dashboard',
    icon: navIcon(Dashboard),
    navLink: '/dashboard',
  },
  // {
  //   id: 'ledger-and-books',
  //   title: 'Ledger and Books',
  //   icon: navIcon(Ledger),
  //   navLink: '/ledger-and-books'
  // },
  // {
  //   id: 'db-intelligence',
  //   title: 'DB Intelligence',
  //   icon: navIcon(DB),
  //   navLink: '/database-intelligence'
  // },
  // {
  //   id: 'search-query',
  //   title: 'ChatBot',
  //   icon: navIcon(Bot),
  //   navLink: '/chatbot-dashboard'
  // },
  {
    id: 'knowledge-center',
    title: 'Knowledge Center',
    icon: navIcon(Knowledge),
    navLink: '/knowledge-center',
  },
  {
    id: 'support',
    title: 'Support',
    icon: navIcon(Support),
    navLink: '/support',
  },
]

export default dashboard;
