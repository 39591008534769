export const statusCode = {
    HTTP_200_OK: '200',
    HTTP_201_CREATED: '201',
    HTTP_204_DELETED: '204',
    HTTP_400_BAD_REQUEST: '400',
    HTTP_401_UNAUTHORIZED: '401',
}

export const emailVerificationStatus = {
    PENDING: "EMAIL_VERIFICATION_PENDING",
    ACTIVE: "ACTIVE",
    DELETED: "DELETED",
    BLOCKED: "BLOCKED",
}