//will reamove after actual API
export const botData = [
  { id: 1, bot_id: 'bot_123', website: 'example.com', created_at: '2024-05-20' },
  { id: 2, bot_id: 'bot_456', website: 'example.org', created_at: '2024-05-21' },
];


//wtsappBot Fake Data

export const fakeData = [
  {
    id: 1,
    client_number: '1234567890',
    name: 'Otto',
    last_conversation: '06-08-2024',
    last_document_uploaded: '06-08-2024',
    premium: true
  },
  {
    id: 2,
    client_number: '1234567891',
    name: 'John',
    last_conversation: '06-07-2024',
    last_document_uploaded: '06-07-2024',
    premium: false
  },
  {
    id: 3,
    client_number: '1234567892',
    name: 'Alice',
    last_conversation: '06-06-2024',
    last_document_uploaded: '06-06-2024',
    premium: true
  },
  {
    id: 4,
    client_number: '1234567893',
    name: 'Bob',
    last_conversation: '06-05-2024',
    last_document_uploaded: '06-05-2024',
    premium: false
  },
  {
    id: 5,
    client_number: '1234567894',
    name: 'Charlie',
    last_conversation: '06-04-2024',
    last_document_uploaded: '06-04-2024',
    premium: true
  },
  {
    id: 6,
    client_number: '1234567895',
    name: 'Dave',
    last_conversation: '06-03-2024',
    last_document_uploaded: '06-03-2024',
    premium: false
  },
  {
    id: 7,
    client_number: '1234567896',
    name: 'Eve',
    last_conversation: '06-02-2024',
    last_document_uploaded: '06-02-2024',
    premium: true
  },
  {
    id: 8,
    client_number: '1234567897',
    name: 'Frank',
    last_conversation: '06-01-2024',
    last_document_uploaded: '06-01-2024',
    premium: false
  },
  {
    id: 9,
    client_number: '1234567898',
    name: 'Grace',
    last_conversation: '05-31-2024',
    last_document_uploaded: '05-31-2024',
    premium: true
  },
  {
    id: 10,
    client_number: '1234567899',
    name: 'Hank',
    last_conversation: '05-30-2024',
    last_document_uploaded: '05-30-2024',
    premium: false
  },
];
