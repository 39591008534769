import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const SupportAccordion = () => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <div className='support-faq'>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">How does the chatbot handle complex customer inquiries? </AccordionHeader>
                    <AccordionBody accordionId="1">
                        Our chatbot uses advanced natural language processing to understand and address complex inquiries.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="2">Can the chatbot integrate with our existing CRM system?</AccordionHeader>
                    <AccordionBody accordionId="2">
                        Yes, our chatbot seamlessly integrates with your existing CRM system, enabling personalized interactions based on customer data and maintaining a comprehensive customer profile.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="3">How does the AI-powered chatbot enhance customer service?</AccordionHeader>
                    <AccordionBody accordionId="3">
                        Our AI-powered chatbot provides instant responses to customer queries, ensuring quick resolution and improved customer satisfaction. It handles common questions, freeing up human agents to focus on more complex issues.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="4"> Can the chatbot assist with online shopping and product recommendations? </AccordionHeader>
                    <AccordionBody accordionId="4">
                        Absolutely! Our chatbot can guide customers through their shopping journey, offering personalized product recommendations based on their preferences and browsing history.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="5">How secure is the data handled by the chatbot?</AccordionHeader>
                    <AccordionBody accordionId="5">
                        Data security is our top priority. Our chatbot is designed with robust security protocols to ensure that all customer interactions and data are securely managed and compliant with industry standards.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="6">What types of financial statements can your software analyze?</AccordionHeader>
                    <AccordionBody accordionId="6">
                        Our software can analyze a wide range of financial statements, including income statements, balance sheets, cash flow statements, and more.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="7"> How does the software improve financial decision-making?</AccordionHeader>
                    <AccordionBody accordionId="7">
                        By providing detailed insights into profitability, liquidity, and solvency metrics, our software helps businesses make informed financial decisions, identify trends, and forecast future performance.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="8">Can the software generate customizable financial reports?</AccordionHeader>
                    <AccordionBody accordionId="8">
                        Yes, our software offers customizable reporting options, allowing you to tailor reports to meet specific business needs and present data in a way that’s easy to understand.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="9"> How does the software handle data security and privacy?</AccordionHeader>
                    <AccordionBody accordionId="9">
                        We adhere to strict data security protocols and comply with all relevant regulations to ensure that your financial data is protected and handled with the utmost confidentiality.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="10">Is the software suitable for small businesses as well as large enterprises?</AccordionHeader>
                    <AccordionBody accordionId="10">
                        Our financial statement analysis software is scalable and adaptable, making it suitable for businesses of all sizes, from small startups to large enterprises.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="11"> How does the bank statement analysis software work?</AccordionHeader>
                    <AccordionBody accordionId="11">
                        Our software automatically ingests and analyzes your bank statements, categorizing transactions and providing a clear summary of your financial activities.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="12">Can the software detect fraudulent transactions?</AccordionHeader>
                    <AccordionBody accordionId="12">
                        Yes, our software includes advanced algorithms that can identify and flag suspicious or unusual transactions, helping to prevent fraud.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="13">How can this software help with budgeting and financial planning?</AccordionHeader>
                    <AccordionBody accordionId="13">
                        By analyzing your spending and saving patterns, our software provides insights that are essential for creating effective budgets and financial plans.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="14">What formats of bank statements are supported?</AccordionHeader>
                    <AccordionBody accordionId="14">
                        Our software supports various formats, including PDF, CSV, Excel, scanned PDF and images making it easy to upload and analyze your bank statements.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="15">How secure is my financial data with this software?</AccordionHeader>
                    <AccordionBody accordionId="15">
                        Your data security is our priority. We use state-of-the-art encryption and security measures to protect your financial information.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="16">What types of documents can your software process?</AccordionHeader>
                    <AccordionBody accordionId="16">
                        Our document intelligence software can process a variety of document types, including invoices, contracts, receipts, and more.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="17">What formats of documents are supported?</AccordionHeader>
                    <AccordionBody accordionId="17">
                        Our software supports various formats, including PDF, CSV, Excel, scanned PDF and images making it easy to upload and analyze your documents.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="18">How does the software extract and categorize information from documents? </AccordionHeader>
                    <AccordionBody accordionId="18">
                        Using advanced machine learning algorithms, our software accurately extracts key information from documents and categorizes it for easy access and analysis.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="19">Can the software integrate with other business systems?</AccordionHeader>
                    <AccordionBody accordionId="19">
                        Yes, our document intelligence software integrates seamlessly with other business systems, such as ERP and CRM, to streamline workflows and enhance productivity.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="20">How does the software ensure data accuracy and reduce manual errors?</AccordionHeader>
                    <AccordionBody accordionId="20">
                        Our software automates data entry and processing, significantly reducing the risk of manual errors and ensuring high data accuracy.
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="21">Is the software compliant with industry regulations?</AccordionHeader>
                    <AccordionBody accordionId="21">
                        Yes, our software is designed to comply with all relevant industry regulations, ensuring that your document handling processes meet legal and compliance standards.
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default SupportAccordion;