import Types from '../types';

const initialState = {
  payload: {},
};

export default function navbarContent(state = initialState, action) {
    switch (action.type) {
        case Types.NAVBAR_CONTENT:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
}