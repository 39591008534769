import Types from "../types";

//API
import { bankStatementHistoryRequest } from "../../services/bankStatement";


export const uploadHistory = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: Types.UPLOAD_HISTORY.PENDING
      });

      const response = await bankStatementHistoryRequest();
      if (response && response.data) {
        dispatch({
          type: Types.UPLOAD_HISTORY.FULFILLED,
          payload: response.data?.statement_metrics_dashboard_data
        });
      }

      if (response.response) {
        dispatch({
          type: Types.UPLOAD_HISTORY.ERROR,
          payload: response.response
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPLOAD_HISTORY.ERROR,
        payload: error
      });
    }
  };
};