import { useState, Fragment, useEffect, } from "react";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

//tatbs
import { Nav, NavItem, NavLink, TabContent, TabPane, Table, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { it } from "@faker-js/faker";
import { object } from "prop-types";
import copy from "copy-to-clipboard";
import { singleBankStatementHistory } from "../../../redux/actions/bankStatement";

import { xml } from "./data (1)";
import "./style.scss";

const AnalysisTable = () => {
    const [parsedData, setParsedData] = useState('');

    // State to manage active tab
    const [activeTab, setActiveTab] = useState('1');
    const [activeLink, setActiveLink] = useState('')
    const [excel, SetExcel] = useState('');
    const [copyButtonText, setCopyButtonText] = useState("Copy JSON");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    // const data = useSelector(state => state.bankStatementReducer.bankResponse);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const { statement_id } = useParams();
    const dispatch = useDispatch();
    const { bankResponse, loading, error } = useSelector(state => ({
        bankResponse: state.bankStatementReducer.bankResponse,
        loading: state.bankStatementReducer.loading,
        error: state.bankStatementReducer.error,
    }));

    // Function to toggle active tab
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handleSetActiveLink = (linkName) => {
        setActiveLink(linkName);
    };
    const handleCopy = () => {
        if (parsedData) {
            const jsonString = JSON.stringify(parsedData, null, 2);
            copy(jsonString);

            // setCopyButtonText("Copied!");
            toast.success('Copied!', {
                duration: 4000,
                position: 'top-center',
                style: {
                    background: 'green',
                    color: 'white'
                },
            });
        }

    };
    const handlePDFPreview = () => {
        // window.open(file[0].path, "_blank");
    }
    const handleDownload = () => {
        if (excel) {
            const excelUrl = excel;
            window.open(excelUrl, "_blank");
        }

    };
    function formatText(text) {
        return text
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
    useEffect(() => {
        if (bankResponse?.parsed_data) {
            setParsedData(bankResponse?.parsed_data);
        }
        SetExcel(bankResponse?.excel_url);

    }, [bankResponse]);

    useEffect(() => {
        if (statement_id) {
            dispatch(singleBankStatementHistory(statement_id))
        }
    }, [dispatch, statement_id]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    function formatAnalysisDate(dateString) {
        if (dateString) {
            const date = new Date(dateString);
            return `${months[date.getMonth()]} ${date.getFullYear()}`;
        }
    }

    return (
        <>

            {
                loading ?
                    <div className='loader-wrapper'>
                        <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#0082AC" data-testid="oval-svg">
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                    <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#0082ac6e" strokeWidth="2"></circle>
                                    <path d="M20 0c0-9.94-8.06-20-20-20">
                                        <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    :

                    ((error && Object.keys(error).length > 0) || Object.keys(parsedData).length === 0) ?
                        <h2 className='error-msg'>No data found</h2>
                        :
                        <>
                            <div className="analysis-table">
                                <div className="analysis-table__banner">
                                    <div className="analysis-table__content">
                                        <h1 className="analysis-table__title">{Object.values(parsedData?.account_details)[2] || ''}</h1>
                                        <p>Analysis of {location?.state?.bankName || ''} Bank Statements for {formatAnalysisDate(Object.values(parsedData?.account_details?.statement_period)[0])} - {formatAnalysisDate(Object.values(parsedData?.account_details?.statement_period)[1])}</p>
                                    </div>
                                    <div className="analysis-dropdown">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle caret color="primary">
                                                Download
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                                <DropdownItem onClick={handleDownload}>
                                                    Download Excel
                                                </DropdownItem>
                                                <DropdownItem onClick={handleCopy}>
                                                    Copy JSON
                                                </DropdownItem>
                                                {/* <DropdownItem onClick={handlePDFPreview}>
                                        Preview Document
                                    </DropdownItem> */}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <div className="analysis-table__main">
                                    {/* <div className="custom-analysis-bar">
                                    <NavLink className={activeLink === 'saving' ? 'custom-btn active' : 'custom-btn'} onClick={() => handleSetActiveLink('saving')}>Saving Analysis</NavLink>
                                    <NavLink className={activeLink === 'fd' ? 'custom-btn active' : 'custom-btn'} onClick={() => handleSetActiveLink('fd')}>FD Analysis</NavLink>
                                    <NavLink className={activeLink === 'rd' ? 'custom-btn active' : 'custom-btn'} onClick={() => handleSetActiveLink('rd')}>RD Analysis</NavLink>
                                    <NavLink className={activeLink === 'salary' ? 'custom-btn active' : 'custom-btn'} onClick={() => handleSetActiveLink('salary')}>Salary Analysis</NavLink>
                                    <NavLink className={activeLink === 'overdraft' ? 'custom-btn active' : 'custom-btn'} onClick={() => handleSetActiveLink('overdraft')}>Sanction and Overdraft Analysis</NavLink>
                                </div> */}

                                    <div className="analysis-table__wrapper">

                                        <TabContent activeTab={activeTab} className="analysis-table__tabcontent">
                                            <TabPane tabId="1" className="analysis-table__tabpane">
                                                <Table bordered>
                                                    <tbody>

                                                        <tr>
                                                            <th>{formatText(Object.keys(parsedData.account_details)[0] || '')}</th>
                                                            <td>{Object.values(parsedData.account_details)[0] || ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{formatText(Object.keys(parsedData.account_details)[1] || '')}</th>
                                                            <td>{Object.values(parsedData.account_details)[1] || ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{formatText(Object.keys(parsedData.account_details)[2] || '')}</th>
                                                            <td>{Object.values(parsedData.account_details)[2] || ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{formatText(Object.keys(parsedData.account_details)[3] || '')}</th>
                                                            <td>{Object.values(parsedData.account_details)[3] || ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{formatText(Object.keys(parsedData.account_details)[4] || '')}</th>
                                                            <td> {formatAnalysisDate(Object.values(parsedData.account_details.statement_period)[0]) || ''} - {formatAnalysisDate(Object.values(parsedData.account_details.statement_period)[1]) || ''}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </TabPane>
                                            {
                                                !(parsedData?.analyzed_details["CASH DEPOSITS"].length === 0) &&
                                                <TabPane tabId="2" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Narration</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.analyzed_details["CASH DEPOSITS"].map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date || ''}</td>
                                                                        <td>{item?.amount || ''} </td>
                                                                        <td>{item?.balance || ''}</td>
                                                                        <td>{item?.narration || ''}</td>
                                                                        <td>{item?.type || ''}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {
                                                !(parsedData?.analyzed_details["CASH WITHDRAWALS"].length === 0) &&

                                                <TabPane tabId="3" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Narration</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.analyzed_details["CASH WITHDRAWALS"].map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>{item?.amount}</td>
                                                                        <td>{item?.balance}</td>
                                                                        <td>{item?.narration}</td>
                                                                        <td>{item?.type}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {
                                                !(parsedData?.analyzed_details["CHEQUE BOUNCES"].length === 0) &&
                                                <TabPane tabId="4" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Narration</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.analyzed_details["CHEQUE BOUNCES"].map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date || ''}</td>
                                                                        <td>{item?.amount || ''}</td>
                                                                        <td>{item?.narration || ''}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }

                                            {
                                                !(parsedData?.analyzed_details["CHEQUE DEPOSITS"].length === 0) &&
                                                <TabPane tabId="5" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Narration</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.analyzed_details["CHEQUE DEPOSITS"].map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>{item?.amount}</td>
                                                                        <td>{item?.balance}</td>
                                                                        <td>{item?.narration}</td>
                                                                        <td>{item?.type}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {
                                                !(parsedData?.analyzed_details["CHEQUE WITHDRAWALS"].length === 0) &&
                                                <TabPane tabId="6" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Narration</th>
                                                                <th>Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.analyzed_details["CHEQUE WITHDRAWALS"].map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>{item?.amount}</td>
                                                                        <td>{item?.balance}</td>
                                                                        <td>{item?.narration}</td>
                                                                        <td>{item?.type}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {
                                                !(parsedData?.trxn_details.length === 0) &&
                                                <TabPane tabId="7" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Narration</th>
                                                                <th>Transaction Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                parsedData?.trxn_details.map((item) => (
                                                                    <tr>
                                                                        <td>{item?.date}</td>
                                                                        <td>{item?.amount}</td>
                                                                        <td>{item?.balance}</td>
                                                                        <td>{item?.narration}</td>
                                                                        <td>{item?.trxn_type}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }

                                            {
                                                parsedData.fraud_details?.fraud_flag &&
                                                <TabPane tabId="8" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <tbody>

                                                            <tr>
                                                                <th>Fraud Flag: </th>
                                                                <td>{Object.values(parsedData.fraud_details?.fraud_flag) || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Fraud Markers (if any): </th>
                                                                <td>{Object.values(parsedData.fraud_details)[1] || 'None'}</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {parsedData?.analyzed_details['EOD BALANCE']?.daywise_eod_balance &&
                                                <TabPane tabId="9" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>EOD Balance</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {

                                                                parsedData?.analyzed_details['EOD BALANCE']?.daywise_eod_balance?.map((item, index) => (

                                                                    <tr key={index}>
                                                                        {Object.entries(item).map(([key, value]) => (
                                                                            <Fragment key={key}>
                                                                                <td>{key}</td>
                                                                                <td>{value}</td>
                                                                            </Fragment>
                                                                        ))}

                                                                    </tr>
                                                                ))

                                                            }
                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                            {
                                                parsedData?.analyzed_details['EOD BALANCE']?.monthwise_eod_balance &&
                                                <TabPane tabId="10" className="analysis-table__tabpane">
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Month</th>
                                                                <th>25th Percentile EOD Balance</th>
                                                                <th>50th Percentile EOD Balance</th>
                                                                <th>75th Percentile EOD Balance</th>
                                                                <th>Average EOD Balance</th>
                                                                <th>Max EOD Balance</th>
                                                                <th>Min EOD Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                Object.entries(parsedData?.analyzed_details['EOD BALANCE']?.monthwise_eod_balance)?.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item[0] || 'None'}</td>
                                                                        <td>{item[1]['25th percentile eod balance'] || 'None'}</td>
                                                                        <td>{item[1]['50th percentile eod balance'] || 'None'}</td>
                                                                        <td>{item[1]['75th percentile eod balance'] || 'None'}</td>
                                                                        <td>{item[1]['avg eod balance'] || 'None'}</td>
                                                                        <td>{item[1]['max eod balance'] || 'None'}</td>
                                                                        <td>{item[1]['min eod balance'] || 'None'}</td>
                                                                    </tr>
                                                                ))

                                                            }
                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            }
                                        </TabContent>

                                        <div className="analysis-table__tabs-wrapper">
                                            <Nav tabs className="analysis-table__tabs">
                                                <NavItem>
                                                    <NavLink
                                                        className={activeTab === '1' ? 'active' : ''}
                                                        onClick={() => toggleTab('1')}
                                                    >
                                                        Account Details
                                                    </NavLink>
                                                </NavItem>

                                                {
                                                    !(parsedData?.analyzed_details["CASH DEPOSITS"].length === 0) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '2' ? 'active' : ''}
                                                            onClick={() => toggleTab('2')}
                                                        >
                                                            Cash Deposits
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    !(parsedData?.analyzed_details["CASH WITHDRAWALS"].length === 0) &&

                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '3' ? 'active' : ''}
                                                            onClick={() => toggleTab('3')}
                                                        >
                                                            Cash Withdrawal
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    !(parsedData?.analyzed_details["CHEQUE BOUNCES"].length === 0) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '4' ? 'active' : ''}
                                                            onClick={() => toggleTab('4')}
                                                        >
                                                            Cheque Bounces
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    !(parsedData?.analyzed_details["CHEQUE DEPOSITS"].length === 0) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '5' ? 'active' : ''}
                                                            onClick={() => toggleTab('5')}
                                                        >
                                                            Cheque Deposits
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    !(parsedData?.analyzed_details["CHEQUE WITHDRAWALS"].length === 0) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '6' ? 'active' : ''}
                                                            onClick={() => toggleTab('6')}
                                                        >
                                                            Cheque Withdrawals
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    !(parsedData?.trxn_details.length === 0) &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '7' ? 'active' : ''}
                                                            onClick={() => toggleTab('7')}
                                                        >
                                                            General Transaction Details
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    parsedData.fraud_details?.fraud_flag &&

                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '8' ? 'active' : ''}
                                                            onClick={() => toggleTab('8')}
                                                        >
                                                            Fraud Details
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {parsedData?.analyzed_details['EOD BALANCE']?.daywise_eod_balance &&
                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '9' ? 'active' : ''}
                                                            onClick={() => toggleTab('9')}
                                                        >
                                                            Daywise EOD Balance
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                                {
                                                    parsedData?.analyzed_details['EOD BALANCE']?.monthwise_eod_balance &&

                                                    <NavItem>
                                                        <NavLink
                                                            className={activeTab === '10' ? 'active' : ''}
                                                            onClick={() => toggleTab('10')}
                                                        >
                                                            Monthwise EOD Balance
                                                        </NavLink>
                                                    </NavItem>
                                                }
                                            </Nav>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="bottom-btn-wrapper">
                                <Button color="primary" outline onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
                                <Button color="primary" onClick={() => navigate('/financial-statement-analyser')}>New Analysis</Button>
                            </div>
                        </>
            }
        </>
    )
}

export default AnalysisTable;