// React Imports
import { useEffect, useState } from "react";

//Components
import PageHeader from "../../@core/components/PageHeader";
import CustomeTable from "../components/CustomTable";

//Reactstrap
import { Card } from "reactstrap";
import { Search, Edit } from "react-feather";

//services
import { loadAllSessionsHandler } from "../../services/documentQnA";
import { showErrorToast, showSuccessToast } from "../../utility/helper";
import { fetchDbConnectionsListHandler } from "../../services/dbConnect";
import { statusCode } from "../../utility/constants/utilObject";


const DBHistory = () => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSessions, setFilteredSessions] = useState(sessions);
    const [editSessionId, setEditSessionId] = useState(null);
    const [editedName, setEditedName] = useState('');

    useEffect(() => {
        fetchDbConnectionsListHandler().
            then((response) => {
                
                if (response.status == statusCode.HTTP_200_OK) {
                    // Sort the response in the reverse order of creation date
                    response.data.document.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setSessions(response.data.document);
                    setFilteredSessions(response.data.document);
                    showSuccessToast("Sessions loaded successfully");
                } else {
                    setSessions([]);
                    setFilteredSessions([]);
                    // showErrorToast("Failed to load sessions");
                }
                setLoading(false);
            })
            .catch((error) => {
                setSessions([]);
                setFilteredSessions([]);
                setLoading(false);
                // showErrorToast("Failed to load sessions");
            });
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = sessions.filter(session =>
            session.name.toLowerCase().includes(value) ||
            session.date.includes(value)
        );
        setFilteredSessions(filtered);
    };

    const stopEditing = () => {
        setEditSessionId(null);
        setEditedName('');
    };

    const saveChanges = (id) => {
        const updatedSessions = sessions.map(session =>
            session.id === id ? { ...session, name: editedName } : session
        );
        setSessions(updatedSessions);
        stopEditing();
    };

    //columns
    const allowedColumns = ['serialNo', 'name', 'database_type',  'created_at', 'updated_at'];
    return (
        <>
            <PageHeader
                pageName={"Previous Connections"}
                backButtonEnabled={false}
            />

            <Card className="blogPreviewContainer">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                        width: '100%',
                        marginBottom: '10px',
                    }}>
                        <h3>List of previous connections</h3>
                        <div style={{
                            position: 'relative',
                            width: '300px'
                        }}>
                            <Search
                                size={18}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '15px',
                                    transform: 'translateY(-50%)'
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search sessions..."
                                style={{
                                    padding: '8px 16px 8px 20px', // Left padding is increased for icon
                                    fontSize: '13px',
                                    width: '100%',
                                    borderRadius: '20px',
                                    border: '1px solid #ccc' // Adjust color as needed
                                }}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                    {/* Table data */}
                    <div style={{ height: 'calc(85vh - 80px)', overflow: 'auto', width: '100%' }}>
                        <CustomeTable
                            loading={loading}
                            data={filteredSessions || []}
                            allowedColumns={allowedColumns}
                            errResponse='No Connections found.'
                            loadingText='Loading your connections'
                            singleRedirectionLink='/database-intelligence/analytics/'
                            redirectionLink="/database-intelligence/"
                            redirectTitle="Click here to create a new connection."
                        />


                    </div>

                </div>
            </Card>
        </>
    )
}

export default DBHistory;