import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
    createDbSession: "analytics/db_connect/create_db_session/",
    fetchDbResponseFromUserQuery: "analytics/db_connect/fetch_db_response_from_user_query/",
    fetchSessionDbSchema: "analytics/db_connect/fetch_session_db_schema/?session={0}",
    fetchDbConnectionList: "analytics/session/fetch_all_sessions/",
    postUserFeedback: "analytics/db_message/save_chat_feedback/",
    previousChatHistory: "analytics/db_message/fetch_db_chat_by_session/?session_id={0}",


}

export const createDbSessionHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.createDbSession, localStorage.getItem('token'), payload)
};

export const fetchDbQueryResponseHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.fetchDbResponseFromUserQuery, localStorage.getItem('token'), payload)
};

export const fetchSessionDbSchemaHandler = (sessionId) => {
    return REQUESTS.get(API_ROUTES.fetchSessionDbSchema.format(sessionId), localStorage.getItem('token'))
}
export const fetchDbConnectionsListHandler = () => {
    return REQUESTS.get(API_ROUTES.fetchDbConnectionList, localStorage.getItem('token'))
}

export const postUserFeedbackHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.postUserFeedback, localStorage.getItem('token'), payload)
}

export const fetchPreviousChatHandler = (session_id) => {
    return REQUESTS.get(API_ROUTES.previousChatHistory.format(session_id), localStorage.getItem('token'))
}


