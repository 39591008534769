import { useState, useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FormGroup, Input, ListGroup, ListGroupItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
//country flags
import CountryFlag from 'react-country-flag';
//icons
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector, connect } from 'react-redux';

import { bankListAction } from '../../../redux/actions/bankList';
import { setSelectedBank } from '../../../redux/actions/selectedBank';
import firstBankSelectedReducer from '../../../redux/reducers/bankSelected';


const BankHeader = ({ activeStep, setSelected, bankSelect }) => {
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({
        name: "India",
        code: "IN"
    });
    const [bankSingleList, setBankSingleList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const steps = [
        'Select Bank',
        'Upload Bank Statement',
    ];

    const { bankList, loading, error } = useSelector(state => ({
        bankList: state.bankListReducer.bankList,
        loading: state.bankListReducer.loading,
        error: state.bankListReducer.error,
    }));


    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleCountry = (name, code, e) => {
        dispatch(bankListAction(name));
        setSelectedCountry({ name, code });
        setDropdownOpen(false);
    }

    const handleSelect = (item) => {
        setSelectedItem(item);
        setSelected(item)
    };

    useEffect(() => {
        if (Object.keys(bankList).length > 0) {
            const joinedArray = bankList.MAIN_BANK.concat(bankList.OTHER_BANK);
            setBankSingleList(joinedArray);

            // Automatically select the first item if bankSingleList changes
            if (joinedArray.length > 0) {
                setSelectedItem(joinedArray[0]);
            }
        }
    }, [bankList]);

    useEffect(() => {
        // Set selectedItem from Redux store on initial load
        if (bankSelect && bankSelect.name) {
            setSelectedItem(bankSelect);
        }
    }, [bankSelect]);


    return (
        <div className='bank-header'>
            <Stepper activeStep={activeStep} className='bank-stepper' >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div className='bank-header__actions'>
                {
                    activeStep === 0 &&
                    <>
                        <div className='country-dropdown'>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret outline>
                                    <CountryFlag countryCode={selectedCountry.code} svg />
                                    <span className='country-name'>{selectedCountry.name}</span>
                                </DropdownToggle>
                                <DropdownMenu container="body" >
                                    <DropdownItem onClick={() => handleCountry('India', 'IN')}>
                                        {/* onSelect={handleCountry('India', "IN")} */}
                                        <CountryFlag countryCode={"IN"} svg />
                                        <span className='contry-name'>India</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleCountry('Uae', 'AE')}>
                                        {/* onSelect={handleCountry('Uae', "AE")} */}
                                        <CountryFlag countryCode={"AE"} svg />
                                        <span className='contry-name'>UAE</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        {/* <div className='bank-search'>
                            <FormGroup>
                                <span className="input-icon">
                                    <FaSearch />
                                </span>
                                <Input
                                    id="bankSearch"
                                    name="search"
                                    placeholder="Bank Name"
                                    type="search"

                                />
                            </FormGroup>
                        </div> */}
                    </>

                }
                {
                    activeStep === 1 &&
                        bankSingleList.length > 0 ?
                        <div className='bank-dropdown'>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle caret outline>
                                    <span>
                                        <img src={selectedItem.image_url || ''} alt='/' ></img>
                                    </span>
                                    <span className='bank-name'>{selectedItem ? selectedItem.name : 'Select Bank'}</span>
                                </DropdownToggle>
                                <DropdownMenu container="body" className='bank-dropdown__menu'>
                                    {

                                        bankSingleList.map((item, i) => {
                                            return (
                                                <DropdownItem onClick={() => handleSelect(item)}>
                                                    <span>
                                                        <img src={item.image_url || ''} alt='/' ></img>
                                                    </span>
                                                    <span className='contry-name'>{item.name || ''}</span>
                                                </DropdownItem>
                                            )
                                        })
                                    }


                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    setSelected: item => dispatch(setSelectedBank(item))
});
const mapStateToProps = ({ firstBankSelectedReducer }) => {
    return {
      bankSelect: firstBankSelectedReducer.bankSelect,
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(BankHeader);