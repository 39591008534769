import React, { useEffect, useState } from 'react'
import { Card, Spinner } from 'reactstrap'
import PropTypes from "prop-types";
import { Search } from "react-feather";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import CustomCard from "./CustomCard";
import { NewTemplates } from "./data";
import NotificationModel from '../../Dashboard/NotificationModel';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "black",
    height: "3px",
    top: "35px",
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#FFFFFF",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BankTemplateSelection = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  // const [templateArray, setTemplateArray] = React.useState([]);
  const [template, setTemplate] = React.useState(null);
  const [showModel, setShowModel] = useState(false)

  const handleChange = (event, newValue) => { setValue(newValue); };

  useEffect(() => {
    loadTemplates()
  }, [])

  const loadTemplates = () => {

  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleModalState = (state) => {
    setShowModel(state => !state)
  }

  const templateArray = [...NewTemplates]

  return (
    <Card>
      <div className='container-fluid vh-85'>
        <div className='page-header'>
          <div className='tab-container'>
            <div className='section-head'>Analysis</div>
          </div>
          <div className='search-container'>
            <div className='search-area'>
              <input
                className='header-search'
                placeholder='Search...'
              />
              <Search size={18} color='#B0BEC5' />
            </div>
          </div>
        </div>
        <div className='page-container no-scroll-bar'>
          <div className='inline-left-align-content api-card-column'>
            {templateArray.map((val) => {
              return (
                <CustomCard
                  image={val.image}
                  heading={val.heading}
                  contentType={val.contentType}
                  nav={val.nav}
                  showModel={handleModalState}
                  subHeading={val.subHeading}
                />
              )
            })}
          </div>
          {
            showModel ? <NotificationModel open={showModel} toggleNotification={handleModalState} /> : <></>
          }
        </div>
      </div>
    </Card>
  );
};
export default BankTemplateSelection;