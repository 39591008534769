import React from "react";
import DashboardContent from "./dashboardContent";
import "./index.scss";
import PageHeader from "../../@core/components/PageHeader";

const Dashboard = () => {
  return (
    <React.Fragment>
      <PageHeader
        pageName={"Dashboard"}
        backButtonEnabled={false}
      />
      <DashboardContent />
    </React.Fragment>
  );
};

export default Dashboard;
