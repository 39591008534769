import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ContactModal = ({ open, toggle, title, submit }) => {
    return (
        <>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader style={{justifyContent: 'center', fontSize: '26px'}}>
                    Talk to our Executive
                </ModalHeader>
                <ModalBody style={{padding: '30px 20px 50px'}}>
                    <Form style={{width: '100%'}}>
                        <FormGroup>
                            <Input id="name"
                                name="name"
                                placeholder="Your Name"
                                type="text" />
                        </FormGroup>
                        <FormGroup>
                            <Input id="email"
                                name="email"
                                placeholder="Your Email"
                                type="email" 
                                style={{marginTop: '0', borderRadius: '0.357rem', border: '1px solid #d8d6de'}}
                                
                                />
                        </FormGroup>
                        <FormGroup>
                            <Input id="phone"
                                name="phone"
                                placeholder="Your Mobile number"
                                type="text" />
                        </FormGroup>
                        <FormGroup>
                            <Input id="user-msg"
                                name="user-msg"
                                placeholder="Your Message"
                                type="textarea" 
                                style={{marginTop: '0', borderRadius: '0.357rem', border: '1px solid #d8d6de'}}
                                />
                        </FormGroup>

                        <Button color="primary">Submit</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ContactModal;