import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';


const NewTable = ({ data }) => {
    // Destructure headers and rows from tableData
    const { headers, rows } = data;

    return (
        <div>
            <Card>
                <CardBody>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                {headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <th scope="row">{rowIndex + 1}</th>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}

export default NewTable;
