import React from 'react';
import './index.scss'; // Import the CSS styles
import GoogleLogo from "@src/assets/logo/google.png";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const GoogleSignInButton = ({ onSignInSuccess }) => {
    const clientId = '136818431546-8dmlnnu8f35r4n7mdgj6ibsn4br04f0i.apps.googleusercontent.com';
    const navigate = useNavigate();

    const onSuccess = (response) => {
        const { credential } = response;
        handleThirdPartyLoginSubmit(credential, 'GOOGLE');
    };

    const onFailure = (error) => {
        console.error('Login Failed:', error);
    };
    const handleThirdPartyLoginSubmit = (credential, loginType) => {

        // Decode the JWT to get user details
        const decodedToken = jwtDecode(credential);

        // Extract user information from the decoded token
        const { 
            family_name, given_name
            , name, email, picture, jti, sub } = decodedToken;

        if(decodedToken){
            onSignInSuccess(decodedToken)
            // navigate('/register', { state: decodedToken })
        }

    };

    return (
        <>
            <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin
                    onSuccess={onSuccess}
                    onError={onFailure}
                />
            </GoogleOAuthProvider>


            {/* <button className="google-sign-in-button" onClick={() => { }}>
                <img style={{ background: '#ffffff', borderRadius: '5px', padding: '2px' }} src={GoogleLogo} alt="Google sign-in" />
                <span>Sign in with Google</span>
            </button> */}
        </>
    );
}

export default GoogleSignInButton;
