// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// Custom logout function
import { onLogout } from '../../../../utility/helper'

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-user.png';
import { generateInitialsAvatar } from '../../../../utility/helper';

const UserDropdown = () => {

  const navigate = useNavigate();

  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  //** Vars
  let userAvatar = generateInitialsAvatar(localStorage.getItem('first_name'), localStorage.getItem('last_name'))
  let userImageUrl = localStorage.getItem('image_url')
  let userName = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
  let userEmail = localStorage.getItem('email')
  if(userImageUrl && userImageUrl != 'null' && userImageUrl != 'NA' && userImageUrl != ''){ 
    userAvatar = userImageUrl
  }

  const renderDropdownToggle = () => {
    return (
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/pages/account-settings'>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to='/apps/email'>
          <Mail size={14} className='me-75' />
          <span className='align-middle'>Inbox</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/apps/todo'>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/apps/chat'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem> */}
        <DropdownItem divider />
        {/* <DropdownItem tag={Link} to='/pages/account-settings'>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/pricing'>
          <CreditCard size={14} className='me-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/faq'>
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem> */}
        <DropdownItem tag={Link} to='/login' onClick={onLogout}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    )
  }


  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle 
        href='/' tag='a' 
        className='nav-link dropdown-user-link' 
        onClick={e => {
          e.preventDefault();
          navigate("/pages/account-settings");
        }}>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />

        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{userName}</span>
          {/* <span className='user-status'>{(userData && userData.role) || 'Admin'}</span> */}
          {/* <span className='user-status' style={{width: '104%'}}>{userEmail}</span> */}
        </div>
      </DropdownToggle>
      {/* {renderDropdownToggle()} */}
    </UncontrolledDropdown>
  )
}

export default UserDropdown
