import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmationModal = ({open, toggle, title, submit, onConfirm, onCancel})=>{
    return(
        <>
            <Modal isOpen={open}>
                <ModalHeader>
                    Important!
                </ModalHeader>
                <ModalBody>
                    {title}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirm}>Yes</Button>
                    <Button color="secondary" onClick={onCancel}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfirmationModal;