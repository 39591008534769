import { Card, CardBody } from 'reactstrap';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


// Function to transform the input data
const transformData = (data) => {
    return data.labels.map((label, index) => {
        const transformedItem = { name: label };
        data.datasets.forEach((dataset) => {
            transformedItem[dataset.label] = dataset.data[index];
        });
        return transformedItem;
    });
};

const BarCard = ({ data }) => {
    // Transform data to Recharts format
    const chartData = transformData(data);
    return (
        <ResponsiveContainer width="100%" height={250}>
            <BarChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {data.datasets.map((dataset, index) => (
                    <Bar
                        key={index}
                        dataKey={dataset.label}
                        fill={'#8884d8'}
                        activeBar={<Rectangle fill={'#8884d8'} />}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarCard;