import { Card, CardBody } from 'reactstrap';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// const data = [
//     {
//         name: 'January',
//         uv: 3200,
//         pv: 2100,
//         amt: 2200,
//     },
//     {
//         name: 'February',
//         uv: 2800,
//         pv: 2500,
//         amt: 2000,
//     },
//     {
//         name: 'March',
//         uv: 3500,
//         pv: 3100,
//         amt: 2700,
//     },
//     {
//         name: 'April',
//         uv: 4000,
//         pv: 3600,
//         amt: 3200,
//     },
//     {
//         name: 'May',
//         uv: 3700,
//         pv: 3300,
//         amt: 3100,
//     },
//     {
//         name: 'June',
//         uv: 4200,
//         pv: 3900,
//         amt: 3500,
//     },
//     {
//         name: 'July',
//         uv: 4600,
//         pv: 4300,
//         amt: 3800,
//     },
// ];
// Transform the data to match the format required by the AreaChart
const transformData = (data ) => {
    const transformedData = data.labels.map((label, index) => ({
        name: label,
        value: data.datasets[0].data[index]
    }));
    return transformedData;
};



const AreaCard = ({data, chartTitle}) => {
    const chartData = transformData(data);

    // Extract the label from the dataset
    const datasetLabel = data.datasets[0]?.label || "Default Label";
    return (
        <Card>
            <CardBody>
                <ResponsiveContainer width="100%" height={250}>
                    <AreaChart
                        width={500}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey = "value"
                            stroke="#36A2EB"
                            fill="rgba(54, 162, 235, 0.2)"
                            name={datasetLabel}

                        />
                    </AreaChart>
                </ResponsiveContainer>
            </CardBody>
        </Card>

    )
}

export default AreaCard;