import Types from "../types";

//API
import { bankStatementRequest, bankStatementSingleHistoryRequest } from "../../services/bankStatement";
import { statusCode } from "../../utility/constants/utilObject";


export const bankStatement = (bankPayload) => {
  return (dispatch) => {
    dispatch({
      type: Types.BANKS_STATEMENT.PENDING
    });

    bankStatementRequest(bankPayload)
      .then((response) => {

        if (response.status == statusCode.HTTP_200_OK) {
          dispatch({
            type: Types.BANKS_STATEMENT.FULFILLED,
            payload: response.data
          });
        }

        if (response.response.status >= statusCode.HTTP_400_BAD_REQUEST && response.response.status <= 501) {
          dispatch({
            type: Types.BANKS_STATEMENT.ERROR,
            payload: response.response
          });
        }

      })
      .catch((error) => {
        dispatch({
          type: Types.BANKS_STATEMENT.ERROR,
          payload: error
        });
      });
  };
};


export const singleBankStatementHistory = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: Types.BANKS_STATEMENT.PENDING
      });

      const response = await bankStatementSingleHistoryRequest(id);

      if (response && response.data) {
        dispatch({
          type: Types.BANKS_STATEMENT.FULFILLED,
          payload: response.data
        });
      }

      if (response.response) {
        dispatch({
          type: Types.BANKS_STATEMENT.ERROR,
          payload: response.response
        });
      }
    } catch (error) {
      dispatch({
        type: Types.BANKS_STATEMENT.ERROR,
        payload: error
      });
    }
  };
};