import Types from '../types';

const initialState = {
    
    botScript: {},
    loading: false,
    error: '',
};

export default function chatBotReducer(state = initialState, action) {
    switch (action.type) {
        case Types.CREATE_CHATBOT.PENDING:
            return {
                ...state,
                loading: true
            };
        case Types.CREATE_CHATBOT.FULFILLED:
            return {
                ...state,
                botScript: action.payload,
                loading: false
            };

        case Types.CREATE_CHATBOT.ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}