// ** React Imports
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

// ** Third Party Components

// ** Reactstrap Imports
import { Row, Col, TabContent, TabPane } from "reactstrap";

// ** Demo Components
import Tabs from "./templates/Tabs";
import BillingTabContent from "./templates/BillingTabContent";
import AccountTabContent from "./AccountTabContent";
import CompanyTabContent from "./CompanyTabContent";

// ** Styles
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "@styles/react/pages/page-account-settings.scss";
import PageHeader from "../../../@core/components/PageHeader";

const AccountSettings = () => {
  let currentTab = "1";
  const [activeTab, setActiveTab] = useState(currentTab);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
 
   useEffect(()=>{
     if(location.search){
       currentTab =  params.get('tab');
       setActiveTab(currentTab);
     }else{
       currentTab = '1';
       setActiveTab(currentTab);
     }
 
   }, [location.search]);

  const toggleTab = tab => {
    setActiveTab(tab);
  };

  return (<>
      <PageHeader
        pageName={`Settings`}
        backButtonEnabled={true}
      />
      <div className="container-fluid vh-85">
        <Row>
          <Col xs={12}>
            <div style={{ width: "100%", marginBottom: "10px" }}></div>
            <Tabs className="mb-2" activeTab={activeTab} toggleTab={toggleTab} />
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <AccountTabContent />
              </TabPane>
              <TabPane tabId="2">
                <CompanyTabContent />
              </TabPane>
              <TabPane tabId="3">
                <BillingTabContent />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AccountSettings;
