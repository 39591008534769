import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
    createBotProfile: "analytics/chatbot/create_chatbot/",
    chatBotAnalytics: "analytics/chatbot/bot_analytics/?bot_id={0}",
    listAllBot: "analytics/chatbot/list_chatbot/",
    uploadFile: "analytics/botdata/load_bot_data/",
    listAllDocuments: "analytics/botdata/list_docs?chatbot_id={0}",
    deleteFile: "analytics/botdata/delete_data_file/?file_id={0}",
    fetchTestResponse: "analytics/converse/respond_test_query/",
    fetchAllTestConvesation: "analytics/converse/fetch_test_conversation?chatbot_id={0}",
    getBotById: "analytics/chatbot/get_chatbot/?bot_id={0}",
    editChatBotById: "analytics/chatbot/update_chatbot/?bot_id={0}",
    deleteBot: "analytics/chatbot/delete_chatbot/?bot_id={0}"

}

export const createChatBot = (payload) => {
    return REQUESTS.postFile(API_ROUTES.createBotProfile, localStorage.getItem("token"), payload, {
        validateStatus: function (status) {
            return status >= 200 && status < 510;
        }
    });
};

export const fetchChatBotAnalytics = (botId) => {
    return REQUESTS.get(API_ROUTES.chatBotAnalytics.format(botId), localStorage.getItem("token"));
};

export const listAllChatBotHandler = () => {
    return REQUESTS.get(API_ROUTES.listAllBot, localStorage.getItem("token"));
};

export const uploadChatbotDataFile = (payload) => {
  return REQUESTS.postFile(API_ROUTES.uploadFile, localStorage.getItem("token"), payload);
};

export const listAllDocumentsHandler = (chatbot_id) => {
    return REQUESTS.get(API_ROUTES.listAllDocuments.format(chatbot_id), localStorage.getItem("token"));
};

export const fetchTestResponseHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.fetchTestResponse, localStorage.getItem("token"), payload);
};

export const fetchAllTestConvesationHandler = (botId) => {
    return REQUESTS.get(API_ROUTES.fetchAllTestConvesation.format(botId), localStorage.getItem("token"));
};

export const fetchBotById = (botId) => {
    return REQUESTS.get(API_ROUTES.getBotById.format(botId), localStorage.getItem("token"));
};

export const editChatBot = (botId, payload) => {
    return REQUESTS.put(API_ROUTES.editChatBotById.format(botId), localStorage.getItem("token"), payload);
};

export const deleteChatBot = (botId) => {
    return REQUESTS.delete(API_ROUTES.deleteBot.format(botId), localStorage.getItem("token"));
};
export const deleteFileHandler = (fileId) => {
    return REQUESTS.get(API_ROUTES.deleteFile.format(fileId), localStorage.getItem("token"));
}
