import React, { useState, useEffect } from 'react';
import { ClockLoader } from "react-spinners";
import './index.scss'; // Ensure you have the correct CSS path

const MultiStepDataLoader = ({ stepNumber = 0, steps = ['Uploading', 'Analysing', 'Processing'] }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [activeLoader, setActiveLoader] = useState(0);

  useEffect(() => {
    if (stepNumber >= 0 && stepNumber < steps.length) {
      setCurrentStep(stepNumber);
      const loaderTimer = setTimeout(() => {
        if (stepNumber < steps.length) {
          setActiveLoader(stepNumber);
        }
      }, 800);

      return () => clearTimeout(loaderTimer);
    }
  }, [stepNumber, steps.length]);

  return (
    <div className="container">
      <div className="progress-bar-container">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={`circle ${index === activeLoader ? 'active' : ''} ${index < currentStep ? 'active' : ''}`}>
              {index < currentStep ? <i className="large-icon fa fa-check"></i> : (index === activeLoader ? <ClockLoader size={50} color="#FFFFFF" /> : null)}
            </div>
            {index < steps.length - 1 && (
              <div className={`progress-bar ${index < currentStep ? 'filled' : ''}`}></div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="labels">
        {steps.map((label, index) => (
          <div key={index} className="label">{label}</div>
        ))}
      </div>
    </div>
  );
};

export default MultiStepDataLoader;
