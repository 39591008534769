import React, { useState } from "react"
import { ArrowLeft } from "react-feather"
import { useNavigate } from 'react-router-dom'
import {   
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button 
} from 'reactstrap';
import { colors } from "../../../utility/constants/colors"
import './index.scss'

const ContainerHeader = (props) => {
    const {
        pageName, 
        backButtonEnabled, 
        backBtnAlert, 
        backBtnAlertMsg,
        headerBtnEnabled,
        headerBtnName,
        headerBtnClickHandler,
        indicators
    } = props
    const navigate = useNavigate()
    const [alertModalShow, setAlertModalShow] = useState(false)


    const renderPromptModal = () => {
        if(alertModalShow){
            return (
                <Modal style={{zIndex:'100'}} isOpen={alertModalShow}>
                    <ModalHeader>Confirm</ModalHeader>
                    <ModalBody>
                        <div className='parameter-container'>
                            <div className='parameter-name'>Are you sure you want to leave this page?</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={()=>setAlertModalShow(false)} color="primary">Apply</Button>{' '}
                        <Button onClick={()=>setAlertModalShow(false)} color="secondary">Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    const backBtnHandle = () => {
        if(backButtonEnabled){
            if(backBtnAlert){
                setAlertModalShow(true)
            }else{
                navigate(-1)
            }
        }
    }

    const renderKeyValueInfo = () => {
        if(!indicators) return (<></>)
        return indicators.map((indicator, index) => {
            return (
                <div className={`data-block ${indicators.length - 1 == index?'right-border':''}`} key={index}>
                    <div className='key'>{indicator.key}:</div>
                    <div className='value'>{indicator.value}</div>
                </div>
            )
        })
    }

    return(
        <div className='pageHeaderContainer'>
             <div>
                {
                    backButtonEnabled?
                    <ArrowLeft 
                        onClick={backBtnHandle} 
                        className="backIcon" 
                        size={25} 
                        color={colors.black}>
                    </ArrowLeft>:<></>
                } {pageName}
             </div>
             {
                headerBtnEnabled?
                <div className="headerBtn" onClick={headerBtnClickHandler}>
                    {headerBtnName}
                </div>:<></>
             }
             <div className="head-info-indicators">
                {renderKeyValueInfo()}
             </div>
            {renderPromptModal()}
        </div>
    )
}

export default ContainerHeader