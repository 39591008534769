import React, { useState, useEffect, Suspense } from "react";

// ** Router Import
import Router from "./router/Router";

// ** Routes & Default Routes
import { getRoutes } from "./router/routes";

// ** Hooks Imports
import { useLayout } from "@hooks/useLayout";
import {
  ACCESS_TYPE,
  LOCAL_STORE_KEY,
  DEVELOPMENT_TYPE
} from "./utility/constants/localStoreKeys";

import "./App.scss";

const App = () => {
  // This is to enable or disable admin mode
  localStorage.setItem(LOCAL_STORE_KEY.USER_MODE, ACCESS_TYPE.USER);
  // Development mode
  localStorage.setItem(LOCAL_STORE_KEY.DEVELOPMENT_MODE, DEVELOPMENT_TYPE.LOCAL);

  // JS format string
  String.prototype.format = function() {
    let args = arguments;
    return this.replace(/{([0-9]+)}/g, function(match, index) {
      return typeof args[index] == "undefined" ? match : args[index];
    });
  };

  const [allRoutes, setAllRoutes] = useState([]);

  // ** Hooks
  const { layout } = useLayout();

  useEffect(() => {
    setAllRoutes(getRoutes(layout));
  }, [layout]);

  return (
    <Suspense fallback={null}>
      <Router allRoutes={allRoutes} />
    </Suspense>
  );
};

export default App;
