import Types from '../types';

const initialState = {
    pricingPayload: {},
    loading: false,
};

export default function pricePlan(state = initialState, action) {
    switch (action.type) {
        case Types.PRICE_PLAN.FULFILLED:
            return {
                ...state,
                pricingPayload: action.payload,
                loading: false
            };
        case Types.PRICE_PLAN.PENDING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state;
    }
}