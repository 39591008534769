import { Link, useNavigate } from "react-router-dom";
import { Button, Table, Input } from "reactstrap";
import { formatText } from "../../../utility/helper";
import moment from "moment";

//fake Data
import { fakeData } from "../../../utility/testDB/chatBot";

const WhatsAppTable = () => {
    const navigate = useNavigate();
    // Ensure we have data to display
    if (!fakeData || fakeData.length === 0) {
        return <p>No data available</p>;
    }

    // Get column names from the first object in fakeData
    const columns = Object.keys(fakeData[0]);

    const handleNavigate = (path, state) => {
        navigate(path, { state });
    };

    return (
        <div className="wtsapp-table" style={{ height: '70vh', overflowY: 'scroll' }}>
            <Table hover>
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col === 'id' ? 'S.No' : formatText(col)}</th>
                        ))}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {fakeData.map((item) => (
                        <tr key={item.id} className={item.premium === true ? "table-success" : ''}>
            
                            {columns.map((col, index) => (
                                 <td key={index}>
                                 {col === 'last_conversation' || col === 'last_document_uploaded'
                                   ? moment(item[col]).format('DD MMM, YYYY')
                                   : col === 'premium'
                                     ? <Input
                                         addon
                                         aria-label="Checkbox for premium status"
                                         type="checkbox"
                                         checked={item[col]}
                                         readOnly
                                       />
                                     : item[col]}
                               </td>
                            ))}

                            <td>
                                <Button
                                    onClick={() => handleNavigate(`/chatbot-dashboard/whatsApp-bot/${item.client_number}`, { item })}
                                    color="primary"
                                    style={{ marginRight: '5px' }}
                                >
                                    Data
                                </Button>
                                <Button onClick={() => handleNavigate(`/chatbot-dashboard/whatsApp-bot/${item.client_number}`, { item, sidebar: true })} color="success">Conversation</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default WhatsAppTable;