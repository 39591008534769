import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import { Card } from "reactstrap";
import PageHeader from "../../@core/components/PageHeader";
import { connect } from "react-redux";
import { Search, Edit } from "react-feather";
import { statusCode } from "../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast } from "../../utility/helper";
// Import for dashboard util
import {
    fourColumnRow,
    threeColumnRow,
    twoColumnRow,
    oneColumnRow,
    CHART_TYPES,
    ROW_TYPE,
} from "./dashboardUtil"
import moment from "moment";

import CodeEditorPopup from "../components/CodeEditor";


import { analyticsData } from "../../utility/testDB/analyticsData";

/**
 * How the component looks like:
 * Dashboard id will render a strauture JSON => This will only have row, columna and chart type details and loader
 * When processStructureJson takes in the JSON it validated it and fetches => Title, DashbaordID, Published Details
 * Then from JSON > Rows will be passed to rendeDashRows() => Which will render the rows structure
 * Then from JSON > Columns will be passed to rendeDashColumns() => Which will render the columns structure
 * Then from JSON > Based on the charts each section chart will be called depending on the type of chart
 */

const AnalyticsDashboard = (props) => {

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const handleQueryClick = (chartData) => {

        console.log(chartData, 'chart')
        setPopupData(chartData);
        setPopupOpen(true);
    };
    const closePopup = () => {
        setPopupOpen(false);
        setPopupData(null);
    };


    const processStructureJson = (structureJSON) => {

        if (!structureJSON || !structureJSON.rows) {
            showErrorToast('Invalid Dashboard Structure');
            return null;
        }

        return structureJSON.rows.map((row, index) => {
            return renderDashRows(row, index);
        });
    }

    const renderPageTitle = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <h1>Analytics Dashboard</h1>
            </div>
        );
    }

    // const renderDashRows = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    const renderDashRows = (row, rowIndex) => {
        switch (row.type) {
            case ROW_TYPE.FOUR_COL_CHART_ROW:
                return fourColumnRow(row.columns, rowIndex, handleQueryClick);
            case ROW_TYPE.THREE_COL_CHART_ROW:
                return threeColumnRow(row.columns, rowIndex, handleQueryClick);
            case ROW_TYPE.TWO_COL_CHART_ROW:
                return twoColumnRow(row.columns, rowIndex, handleQueryClick);
            case ROW_TYPE.ONE_COL_CHART_ROW:
                return oneColumnRow(row.columns, rowIndex, handleQueryClick);
            default:
                return <div key={rowIndex}>Unknown row type</div>;
        }
    };


    // const renderDashColumns = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    // const renderDashPie = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    // const renderDashBar = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    // const renderDashArea = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    // const rendeDashTable = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    // const rendeDashNumber = () => {
    //     return (
    //         <div>
    //             <h1>Analytics Dashboard</h1>
    //         </div>
    //     );
    // }

    return (
        <div>
            <PageHeader
                pageName={"Analytics Dashboard"}
                backButtonEnabled={false}
            />
            <Card>
                <div className='analytics-dashboard-container'>
                    {renderPageTitle()}
                    {processStructureJson(analyticsData)}
                    {/* {fourColumnRow()}
                    {threeColumnRow()}
                    {oneColumnRow()} */}
                    {/* {twoColumnRow()} */}

                </div>
            </Card>

            {isPopupOpen && (
                <CodeEditorPopup
                    isOpen={isPopupOpen}
                    toggle={closePopup}
                    data={popupData}
                />
            )}

        </div>
    );
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ organisationReducer }) => {
    return {
        organisationData: organisationReducer.organisationData
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDashboard);