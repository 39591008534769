import axios from 'axios';
import qs from 'qs';

const develop = false;

const CLOUD = {
	LOCAL: {
	  API_ROOT: 'http://127.0.0.1:8080/',
	},
	SERVER: {
	  API_ROOT: 'http://52.66.243.240:8080/',
	},
  };

let URL = develop ? Object.assign({}, CLOUD.LOCAL) : Object.assign({}, CLOUD.SERVER)

const requests = {
	get : (url) => {
		return axios.get(URL.API_ROOT + url)
					.then(res => {
						return res;
					})
					.catch((error)=> {
						return error
					 })
	},
	post : (url ,body) => {
		return axios.post(URL.API_ROOT + url, body)
			.then(res => {
				return res;
			})
			.catch((error)=> {
				return error;
			 })
	},
	delete : (url ,body) => {
		return axios.delete(URL.API_ROOT + url, body)
			.then(res => {
				return res;
			})
			.catch((error) => {
				return error;
			 })
	},
	uploadFile : (url ,formData) => {
		return  axios.post(`${URL.API_ROOT}${url}`, formData, { headers: { 'Content-Type': 'multipart/form-data'}})
				.then(res => { return res; })
				.catch((error)=> { return error;})
	},
}


export const fetchTitleSuggestionHandler = (payload) => {
	return requests.post('project/doc/v2/generate_title/', payload);
}

export const saveTitleHandler = (payload) => {
	return requests.post('project/doc/v2/save_title/', payload);
}



export const documentListHandler = (userId) => {
	return requests.post('project/doc/fetch_document/',qs.stringify({id:userId}));
}

export const regenerateSectionPointWithParamHanlder = (payload) => {
	return requests.post('project/doc/param/v1/reload_blog_section_points/', payload);
}

export const regenerateSectionContentHanlder = (payload) => {
	return requests.post('project/doc/v2/regenerate_section_content/', payload);
}

export const regenerateSectionConclusionHanlder = (payload) => {
	return requests.post('project/doc/v2/regenerate_section_content_conclusion/', payload);
}

export const generateSectionPointWithParamHanlder = (payload) => {
	return requests.post('project/doc/param/v1/load_blog_section_points/', payload);
}

export const generateSectionPointWithSeqAndParamHanlder = (payload) => {
	return requests.post('project/doc/param/v1/load_blog_section_points_seq_with_param/', payload);
}

export const generateSectionContentHanlder = (payload) => {
	return requests.post('project/doc/v2/load_blog_section_content/', payload);
}

export const fetchKeywordSuggestionHandler = (payload) => {
	return requests.post('project/doc/v2/generate_keyword/', payload);
}

export const generateSectionHanlder = (payload) => {
	return requests.post('project/doc/load_blog_sections/', payload);
}

export const regenerateSectionHanlder = (payload) => {
	return requests.post('project/doc/reload_blog_sections/', payload);
}

export const saveBlogContentHanlder = (payload) => {
	return requests.post('project/doc/save_blog_draft/', payload);
}

export const saveSectionContentHanlder = (payload) => {
	return requests.post('project/doc/save_section_content_version/', payload);
}

export const saveFormattedDraftHanlder = (payload) => {
	return requests.post('project/doc/save_formatted_draft/', payload);
}


export const regenerateSectionPointHanlder = (payload) => {
	return requests.post('project/doc/regenerate_section_content/', payload);
}

export const addMoreContentHanlder = (payload) => {
	return requests.post('project/doc/append_section_content/', payload);
}

export const addMoreContentV2Hanlder = (payload) => {
	return requests.post('project/doc/v2/append_section_content/', payload);
}

export const modeBasedParaphraseHanlder = (payload) => {
	return requests.post('project/doc/mode_based_paraphrase/', payload);
}

export const toneAnalysisHanlder = (payload) => {
	return requests.post('project/doc/analyse_content_tone/', payload);
}

export const toneDocAnalysisHanlder = (payload) => {
	return requests.post('project/doc/analyse_doc_tone/', payload);
}

export const seoDocAnalysisHanlder = (payload) => {
	return requests.post('project/doc/analyse_content_seo/', payload);
}

export const saveGeneratedTextHanlder = (payload) => {
	return requests.post('project/doc/save_generated_text/', payload);
}

export const loadGeneratedTextHanlder = (payload) => {
	return requests.post('project/doc/load_generated_text/', payload);
}

export const fetchBlogInputHandler = (payload) => {
	return requests.post('project/doc/fetch_blog_input/', payload);
}

export const fetchBlogSectionHandler = (payload) => {
	return requests.post('project/doc/fetch_blog_section/', payload);
}

export const fetchBlogSectionPointHandler = (payload) => {
	return requests.post('project/doc/fetch_blog_section_point/', payload);
}

export const fetchBlogSectionContentHandler = (payload) => {
	return requests.post('project/doc/fetch_blog_section_content/', payload);
}



export const fetchPlagiarismReportHandler = (payload) => {
	return requests.post('analyze/seo/check_plagiarism/', payload);
}

export const saveBlogImageHanlder = (payload) => {
	return requests.post('project/doc/save_blog_image/', payload);
}

export const deleteBlogImageHanlder = (payload) => {
	return requests.post('project/doc/delete_blog_image/', payload);
}
