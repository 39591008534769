// ** React Imports
import { Link } from "react-router-dom";

// ** Custom Hooks
import { useSkin } from "@hooks/useSkin";

// ** Icons Imports
import { ChevronLeft } from "react-feather";
import InsightAILogo from "@src/assets/images/logo/LogoDark@2x.png";

// ** Reactstrap Imports
import { Row, Col, CardTitle, CardText, Form, Label, Input, Button, Spinner } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { useState } from "react";
import toast from "react-hot-toast";
import { isValidEmail, showErrorToast, showSuccessToast, toastStyle } from "../../../utility/helper";
import { sendVerifyEmailHandler } from "../../../services/user";
import { statusCode } from "../../../utility/constants/utilObject";
import { ClipLoader } from "react-spinners";

const ForgotPassword = () => {
  // ** Hooks
  const { skin } = useSkin();
  const [email, setEmail] = useState("");
  const [emailLoader, setEmailLoader] = useState(false);

  const illustration = skin === "dark" ? "forgot-password-v2-dark.svg" : "forgot-password-v2.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  const handleSubmit = (e) => {
    setEmailLoader(true);
    e.preventDefault();
    if (!email) {
      setTimeout(() => setEmailLoader(false), 500);
      return toast.error("Email Cannot be blank", { ...toastStyle.error });
    }

    if (!isValidEmail(email)) {
      setTimeout(() => setEmailLoader(false), 500);
      return toast.error("Invalid Email", { ...toastStyle.error });
    }

    sendVerifyEmailHandler({
      email: email,
      origin: window.location.origin,
      request_type: "reset_password"
    })
      .then((res) => {
        setTimeout(() => setEmailLoader(false), 700);
        if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
          showSuccessToast(`Reset password link is sent to the email - ${email}`);
          setEmail("");
        } else if (res.response && res.response.status && res.response.status.toString() === statusCode.HTTP_400_BAD_REQUEST) {
          showErrorToast(res.response.data.detail);
        } else {
          return showErrorToast("Could not send Reset password link email, Please try again after sometime");
        }
      }).catch((error) => {
      console.log("error", error);
      setTimeout(() => setEmailLoader(false), 700);
      return showErrorToast("Could not send email, Please try again after sometime");
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="auth-wrapper auth-cover">
      <Row className="auth-inner m-0">
        <Link className="brand-logo" to="/">
          <img src={InsightAILogo} style={{ width: "140px", height: "30px" }} alt={"logo"} />
        </Link>
        <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
          <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <img className="img-fluid" src={source} alt="Login Cover" />
          </div>
        </Col>
        <Col className="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4" sm="12">
          <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
            <CardTitle tag="h2" className="fw-bold mb-1">
              Forgot Password? 🔒
            </CardTitle>
            <CardText className="mb-2">
              Enter your email and we'll send you instructions to reset your password
            </CardText>
            <Form className="auth-forgot-password-form mt-2" onSubmit={handleSubmit}>
              <div className="mb-1">
                <Label className="form-label" for="login-email">
                  Email <span className={"text-danger"}>*</span>
                </Label>
                <Input type="email" id="login-email" value={email} disabled={emailLoader} onChange={handleEmailChange}
                       placeholder="john@example.com" autoFocus />
              </div>
              {emailLoader ?
                <div className="sipnner-container">
                  <ClipLoader />
                </div> :
                <Button color="primary" block>
                  Send reset link
                </Button>
              }
            </Form>
            {!emailLoader ? <p className="text-center mt-2">
              <Link to="/login">
                <ChevronLeft className="rotate-rtl me-25" size={14} />
                <span className="align-middle">Back to login</span>
              </Link>
            </p> : <> </>
            }
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
