import React, { useEffect, useState } from 'react';
import { Card, Image, Spinner } from 'reactstrap'
import { useLocation } from 'react-router-dom';
import ZohoLogo from '../../../assets/logo/zoho_logo.png';
import './index.scss'; // Assuming you create a separate CSS file for styling

const SampleZohoResponse = "https://cpbgov26nd.loclx.io/access?state=testing&code=1000.96015b0a18040d068e1b79f9bafd98ce.c405decff9d869c260218c91b3cf301d&location=in&accounts-server=https%3A%2F%2Faccounts.zoho.in"

const ZohoAccess = () => {
    const location = useLocation();
    const [loadType, setLoadType] = useState();
    const queryParams = new URLSearchParams(location.search);


    useEffect(() => {
        // Zoho Data Fetch
        const state = queryParams.get('state');
        const code = queryParams.get('code');
        const locationValue = queryParams.get('location');
        const accountsServer = queryParams.get('accounts-server');
        console.log('state', state);
        console.log('code', code);
        console.log('location', locationValue);
        console.log('accounts-server', accountsServer);

        if(accountsServer != null && accountsServer.includes('zoho')){
            console.log('Zoho Data Fetched');
            setLoadType('zoho')
        }

    }, []);

    const loadingContainer = () => {
        if(loadType == 'zoho'){
            console.log('Zoho Working');
            return (
                <div style={{textAlign: 'center'}}>
                    <img src={ZohoLogo} alt="Zoho Logo" style={{ width: '200px', height: 'auto' }} />
                    <div style={{
                        textAlign: 'center',
                        fontSize: '20px',
                        marginTop: '30px',
                        marginBottom: '40px'
                    }}>Thanks for connecting, please wait while we complete the setup</div>
                    <Spinner style={{marginRight:'10px'}} color="secondary" size={"md"} type='grow'/>
                    <Spinner style={{marginRight:'10px'}} color="secondary" size={"md"} type='grow'/>
                    <Spinner style={{marginRight:'10px'}} color="secondary" size={"md"} type='grow'/>
                </div>
            )
        }
    }

    const redirectToSession = () => {
        const timeout = setTimeout(() => {
            window.location.href = '/document-intelligence/new-session'
            clearTimeout(timeout);
        }, 3000);
    }

    return (
        <div>
            <Card className="blogPreviewContainer">
                <div className='vh-85 padding_0' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loadingContainer()}
                </div>
            </Card>
            {redirectToSession()}
        </div>
    );
};

export default ZohoAccess;
