import Types from '../types';

const initialState = {
  organisationData: {},
  loader: false,
};

export default function organisationReducer(state = initialState, action) {
  switch (action.type) {
    case Types.ORGANISATION.SUCCESS:
      return {
        ...state,
        organisationData: action.payload,
        loader: false,
      };

    case Types.ORGANISATION.LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    case Types.ORGANISATION.GET_DATA:
      return {
        ...state,
        organisationData: action.payload,
      };

    case Types.ORGANISATION.UPDATE_DATA:
      return {
        ...state,
        organisationData: action.payload,
      };

    default:
      return state;
  }
}
