import React, { useState, useRef, useEffect } from 'react';
import { ClipLoader } from "react-spinners";

import './index.scss';

const CustomTabs = ({
    tabs,
    onTabSelect,
    verticalJustify,
    customStyle = {},
    customTabStyle = {},
    customSelectedTabStyle = {},
    isLoading
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (onTabSelect) {
            onTabSelect(index);
        }
    };

    const getTabContainerStyle = () => {
        switch (verticalJustify) {
            case 'full':
                return { width: '100%' };
            case 'scroll':
                return { overflowX: 'auto', width: '100%' };
            case 'left':
                return { justifyContent: 'flex-start' };
            case 'right':
                return { justifyContent: 'flex-end' };
            default:
                return {};
        }
    };
    return (
        <div className='custom-tabs' style={{ display: 'flex', backgroundColor: '#f3f2f7', marginBottom: '2rem', ...getTabContainerStyle(), ...customStyle }}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    onClick={() => handleTabClick(index)}
                    style={{
                        padding: '11px 20px',
                        cursor: 'pointer',
                        backgroundColor: activeTab === index ? '#0C6980' : '',
                        color: activeTab === index ? '#ffffff' : '#6e6b7b',
                        fontSize: '16px',
                        fontWeight: '500',
                        borderRight: '1px solid #d8d6de',
                        width: '100%',
                        textAlign: 'center',
                        ...customTabStyle,
                        ...(activeTab === index ? customSelectedTabStyle : {}),
                    }}
                    className='custom-tabs__tab'
                >
                    {tab}
                    {
                        isLoading && isLoading[tab] &&(
                            <ClipLoader size={20} color={activeTab === index ? "#ffffff" : "#0C6980"} />
    
                        )
                    }
                </div>
            ))}
        </div>
    );
};

export default CustomTabs;