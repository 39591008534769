// ** React Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// ** Icons Imports
import { Search, List } from "react-feather";
import { Link } from "react-router-dom";


import NotificationModel from "./NotificationModel";

// ** Styles
import "@styles/react/libs/charts/apex-charts.scss";
import UploadTable from "../components/UploadTable";
import { uploadHistory } from "../../redux/actions/uploadHistory";
import Banner from "../components/banner";

const DashboardContent = () => {
  const [notificationOpen, setNotificationOpen] = useState(false);

  const dispatch = useDispatch();
  const { historyResponse, loading, error } = useSelector(state => ({
    historyResponse: state.uploadHistory.historyResponse,
    loading: state.uploadHistory.loading,
    error: state.uploadHistory.error
  }));

  useEffect(() => {
    dispatch(uploadHistory());
  }, []);


  // ** Function to toggle sidebar
  // const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  // ** Function to toggle notificationbar
  const toggleNotification = () => setNotificationOpen(!notificationOpen);

  return (
    <div className={"dashboard-container no-scroll-bar"}>
      <div id="dashboard-analytics">
        {/* <Banner /> */}
        {/*<Row className="match-height">*/}
        {/*  <Col lg="6" sm="9">*/}
        {/*    <Card className="card-congratulations">*/}
        {/*      <CardBody>*/}
        {/*        <Row>*/}
        {/*          <Col sm={"9"}>*/}
        {/*            <div className={"card-congratulation-text"}>Your Data is Our Responsibility,</div>*/}
        {/*            <div className={"card-congratulation-text"}>Trust Us for Ultimate</div>*/}
        {/*            <div className={"card-congratulation-text"}>Security!</div>*/}
        {/*          </Col>*/}
        {/*          <Col sm={"3"} className={"group-image"}>*/}
        {/*            <img src={GroupImage} alt={""} />*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*  <Col lg="3" sm="10">*/}
        {/*    <Card>*/}
        {/*      <CardBody*/}
        {/*        className={classnames("pb-0")}*/}
        {/*      >*/}
        {/*        <Avatar className="avatar-stats p-50 m-0" icon={<List size={21} />} />*/}
        {/*        <h2 className="fw-bolder mt-lg-2">{kFormatter(dashboardContent.documentProcessed)}</h2>*/}
        {/*        <p className="card-text">Document Processed</p>*/}
        {/*        <p><small>Till Now</small></p>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*  /!*<Col lg="2" sm="5">*!/*/}
        {/*  /!*  <Card>*!/*/}
        {/*  /!*    <CardBody*!/*/}
        {/*  /!*      className={classnames("pb-0")}*!/*/}
        {/*  /!*    >*!/*/}
        {/*  /!*      <Avatar className="avatar-stats p-50 m-0" icon={<Briefcase size={21} />} />*!/*/}
        {/*  /!*      <h2 className="fw-bolder mt-lg-2">{kFormatter(dashboardContent.reportsGenerated)}</h2>*!/*/}
        {/*  /!*      <p className="card-text">Reports Generated</p>*!/*/}
        {/*  /!*      <p><small>Till Now</small></p>*!/*/}
        {/*  /!*    </CardBody>*!/*/}
        {/*  /!*  </Card>*!/*/}
        {/*  /!*</Col>*!/*/}
        {/*  <Col lg="3" sm="10">*/}
        {/*    <Card>*/}
        {/*      <CardBody*/}
        {/*        className={classnames("pb-0")}*/}
        {/*      >*/}
        {/*        <Avatar className="avatar-stats p-50 m-0" icon={<Search size={21} />} />*/}
        {/*        <h2 className="fw-bolder mt-lg-2">{kFormatter(dashboardContent.caseInquiries)}</h2>*/}
        {/*        <p className="card-text">Case Inquiries</p>*/}
        {/*        <p><small>Till Now</small></p>*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}


        <div className="page-header custom-header">
          <div className="tab-container">
            <div className="section-head">Recent Analysis</div>
            <span
              className="section-content">Showing your last {historyResponse?.recent_analysis?.length || 0} analysis</span>
          </div>
          {/* <div className="search-container"> */}
          <Link to="/financial-statement-analyser" color="primary" className="btn btn-primary">New Analysis</Link>
          {/* </div> */}
        </div>

        <UploadTable />

        {notificationOpen ?
          <NotificationModel open={notificationOpen} toggleNotification={toggleNotification} /> : <></>}
      </div>
    </div>

  );
};

export default DashboardContent;
