import Types from "../types";

//API
import { bankListRequest } from "../../services/bankStatement";


export const bankListAction = (bankName) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: Types.BANKS_LIST.PENDING
      });

      const response = await bankListRequest(bankName);
      if (response && response.data && response.data.data) {
        dispatch({
          type: Types.BANKS_LIST.FULFILLED,
          payload: response.data.data
        });
      }
      //For Error in response
      if(response?.response?.data){
        dispatch({
          type: Types.BANKS_LIST.ERROR,
          payload: response.response.data
        });
      }

    } catch (error) {
      dispatch({
        type: Types.BANKS_LIST.ERROR,
        payload: error
      });
    }
  };
};