import { getUserNameFromEmail } from "../helper"

export const LOGIN_TYPE = {
    GOOGLE: 'GOOGLE', 
    APPLE: 'APPLE', 
    LINKEDIN: 'LINKEDIN',
    INSTAGRAM: 'INSTAGRAM',
    TWITTER: 'TWITTER',
    FACEBOOK: 'FACEBOOK',
    GITHUB: 'GITHUB',
    TRUECALLER: 'TRUECALLER',
}

export const SOCIAL_POST_PLATFORM = {
  GOOGLE: 'GOOGLE', 
  LINKEDIN: 'LINKEDIN',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  FACEBOOK: 'FACEBOOK',
  GITHUB: 'GITHUB',
  TRUECALLER: 'TRUECALLER',
  CUSTOM: 'CUSTOM'
}

export const GENERATION_MODE = {
  NEW: "NEW",
  REFORMAT: "REFORMAT",
}

export const ONBOARDING_STATUS = {
  EMAIL_VERIFICATION_PENDING: 'EMAIL_VERIFICATION_PENDING', 
  ONBOARDING_IN_PROCESS: 'ONBOARDING_IN_PROCESS',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  BLOCKED: 'BLOCKED',
}

export const CONTNET_SHARE_INPUT_TYPE = {
  SAVED_AS_DRAFT: "SAVED_AS_DRAFT",
  CREATED_BY_USER_INPUT: "CREATED_BY_USER_INPUT",
  PRE_GENERATIVE_FROM_TREND: "PRE_GENERATIVE_FROM_TREND",
}

export const PUBLISH_STATUS = {
  DRAFT: {
    name: 'DRAFT',
    color: '#F57C00'
  }, 
  SCHEDULED: {
    name: 'SCHEDULED',
    color: '#3F51B5'
  },
  PUBLISHED: {
    name: 'PUBLISHED',
    color: '#2E7D32'
  },
  CANCELLED: {
    name: 'CANCELLED',
    color: '#F4511E'
  },
  BOOKMARK: {
    name: 'BOOKMARK',
    color: '#607D8B'
  },
  DELETED: {
    name: 'DELETED',
    color: '#E64A19'
  }
}

export const _parseDataToPayload = (data, loginType) => {
    console.log(data, loginType, '72')
    if(loginType === LOGIN_TYPE.GOOGLE){
      return {
        first_name: data.given_name,
        last_name: data.family_name,
        email: data.email,
        username: getUserNameFromEmail(data.email),
        password: data.access_token,
        login_type: loginType,
        auth_token: data.access_token,
        token_type: data.token_type,
        image_url: data.picture,
      }
    } else if(loginType === LOGIN_TYPE.LINKEDIN){
      return {
        first_name: data.given_name,
        last_name: data.family_name,
        email: data.email,
        username: getUserNameFromEmail(data.email),
        password: data.access_token,
        login_type: loginType,
        source_uid: data.authuser,
        auth_token: data.access_token,
        token_type: data.token_type,
        expires_in: data.expires_in,
        image_url: data.picture,
        scope: data.scope,
        prompt: data.prompt,
      }
    }
  }
