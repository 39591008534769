export const LOCAL_STORE_KEY = {
    // USER MODE
    USER_MODE: 'userMode',
    DEVELOPMENT_MODE: 'developmentMode',

    //Generator
    GENARATOR_PAGE: 'generator_page',

    // Blog Store
    BLOG_SECTION_CONTENT: 'blog_section_content',
    BLOG_SECTION_POINT: 'blog_section_points',
    BLOG_SECTION: 'blog_section',
    BLOG_OVERVIEW: 'blog_overview',
    WORKING_DOCUMENT_ID: 'working_doc_id',
    BLOG_TITLE: 'blog_title',

    // INSTAGRAM CONTENT
    INSTAGRAM_STORE: 'instagram_store',
    NEW_INSTAGRAM_DOCUMENT: 'NEW_INSTAGRAM_DOCUMENT',
}

export const ACCESS_TYPE = {
    ADMIN: 'ADMIN',
    USER: 'USER'
}

export const DEVELOPMENT_TYPE = {
    STAGING: 'STAGING', 
    LOCAL: 'LOCAL',
    PROD: 'PROD'
}