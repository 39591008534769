import Types from '../types';

const initialState = {
    // bankName: '',
    selectedBank: null,

};

export default function bankSelectedReducer(state = initialState, action) {
    switch (action.type) {
        case Types.SET_BANK_SELECTED:
            return {
                ...state,
                selectedBank: action.payload
            };
        default:
            return state;
    }
}