export const modeOptions = [
  { value: "Formal", label: "Formal" },
  { value: "Creative", label: "Creative" },
  { value: "Simple", label: "Simple" },
  { value: "Informative", label: "Informative" },
  { value: "Witty", label: "Witty" }
];

export const instagramIntentOptions = [
  { value: "Promotional", label: "Promotional" },
  { value: "Educative", label: "Educative" },
  { value: "Engaging", label: "Engaging" }
];

export const modeOptionsArray = ["Formal", "Creative", "Simple", "Informative", "Witty"];


export const linkedinModeOptions = [
  { value: "Formal", label: "Formal" },
  { value: "Creative", label: "Creative" },
  { value: "Simple", label: "Simple" },
  { value: "Informative", label: "Informative" },
  { value: "Humourous", label: "Witty" }
];

export const contentReformatType = [
  { value: "Appreciative", label: "Appreciative" },
  { value: "Awestruck", label: "Awestruck" },
  { value: "Supportive", label: "Supportive" },
  { value: "Critical", label: "Critical" },
  { value: "Analytical", label: "Analytical" },
  { value: "After_Effect", label: "After Effects" }
];

export const newModeOptions = [
  { value: "Scientific", label: "Scientific" },
  { value: "Literary", label: "Literary" },
  { value: "Informative", label: "Informative" },
  { value: "Professional", label: "Professional" },
  { value: "Humorous", label: "Humorous" }
];

export const titleModeOptions = [
  { value: "Formal", label: "Formal" },
  { value: "Witty", label: "Witty" },
  { value: "Simple", label: "Simple" }
];

export const contentType = [
  { value: "-1", label: "All" },
  { value: "6", label: "Blog" },
  { value: "7", label: "LinkedIn Post" },
  { value: "8", label: "Short Form Content" },
  { value: "11", label: "Instagram Caption" }
];

export const wordCountOptions = [
  { value: "50-100", label: "50-100" },
  { value: "100-200", label: "100-200" },
  { value: "200-300", label: "200-300" },
  { value: "300-400", label: "300-400" }
];

export const textUpdateActions = {
  REGENERATE: "REGENERATE",
  PARAPHRASE: "PARAPHRASE",
  ADD_CONTENT: "ADD_CONTENT"
};

export const emailIntentOptions = [
  {
    value: "Update customers on new functionalities",
    label: "Update customers on new functionalities",
    tag: "UPDATE_FUNCTIONALITIES"
  },
  {
    value: "Follow Up Email",
    label: "Follow Up Email",
    tag: "FOLLOW_UP"
  },
  {
    value: "Onboarding Email",
    label: "Onboarding Email",
    tag: "ONBOARDING"
  },
  {
    value: "Resignation Email",
    label: "Resignation Email",
    tag: "RESIGNATION"
  },

  {
    value: "+ Write Your Use Case",
    label: "+ Write Your Use Case",
    tag: "ADD_YOUR_OWN"
  }
];

export const keywordDomains = [
  {
    value: "Technology", label: "Technology"
  },
  {
    value: "Marketing", label: "Marketing"
  },
  {
    value: "Business", label: "Business"
  },
  {
    value: "Manfacturing", label: "Manfacturing"
  },
  {
    value: "Cloud Management", label: "Cloud Management"
  },
  {
    value: "Logistics", label: "Logistics"
  }
];

export const businessDomains = [
  { value: "TECHNOLOGY", label: "Technology" },
  { value: "FINANCE", label: "Finance" },
  { value: "HEALTHCARE", label: "Healthcare" },
  { value: "RETAIL", label: "Retail" },
  { value: "HOSPITALITY", label: "Hospitality" },
  { value: "EDUCATION", label: "Education" },
  { value: "MARKETING", label: "Marketing" },
  { value: "CONSULTING", label: "Consulting" },
  { value: "REAL_ESTATE", label: "Real_Estate" },
  { value: "TRANSPORTATION", label: "Transportation" },
  { value: "MANUFACTURING", label: "Manufacturing" },
  { value: "ENERGY", label: "Energy" },
  { value: "MEDIA", label: "Media" },
  { value: "ENTERTAINMENT", label: "Entertainment" },
  { value: "AGRICULTURE", label: "Agriculture" },
  { value: "CONSTRUCTION", label: "Construction" },
  { value: "GOVERNMENT", label: "Government" },
  { value: "NONPROFIT", label: "Nonprofit" },
  { value: "TELECOMMUNICATIONS", label: "Telecommunications" },
  { value: "TRANSPORT_AND_LOGISTICS", label: "Transport and Logistics" },
  { value: "OTHER", label: "Other" }
];

export const businessProfile = [
  { value: "Founder", label: "Founder" },
  { value: "CXO", label: "CXO" },
  { value: "VP of Sales", label: "VP of Sales" },
  { value: "VP of Marketing", label: "VP of Marketing" },
  { value: "VP of Operations", label: "VP of Operations" },
  { value: "HR Manager", label: "HR Manager" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Sales Manager", label: "Sales Manager" },
  { value: "Marketing Manager", label: "Marketing Manager" },
  { value: "Operations Manager", label: "Operations Manager" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "Software Engineer", label: "Software Engineer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Customer Support Representative", label: "Customer Support Representative" },
  { value: "Accountant", label: "Accountant" },
  { value: "Student", label: "Student" },
  { value: "Influencer", label: "Influencer" },
  { value: "Entrepreneur", label: "Entrepreneur" },
  { value: "Self Employed", label: "Self Employed" },
  { value: "Other", label: "Other" }
];

export const ageGroupList = [
  { value: "BELOW_20", label: "Below 20 yrs" },
  { value: "BETWEEN_20_30", label: "20 - 30 yrs" },
  { value: "BETWEEN_30_40", label: "30 - 40 yrs" },
  { value: "BETWEEN_40_50", label: "40 - 50 yrs" },
  { value: "BETWEEN_50_100", label: "Above 50 yrs" }
];

export const postFreqList = [
  { value: "NONE", label: "Yet to start" },
  { value: "MAX_2", label: "Upto 2 posts a week" },
  { value: "MAX_10", label: "Upto 10 posts a week" },
  { value: "MORE_THAN_10", label: "10+ posts a week" }
];

export const keywordOptions = [
  { value: "digital marketing", label: "digital marketing" },
  { value: "data analytics", label: "data analytics" },
  { value: "artificial intelligence", label: "artificial intelligence" },
  { value: "machine learning", label: "machine learning" },
  { value: "cybersecurity", label: "cybersecurity" },
  { value: "cloud computing", label: "cloud computing" },
  { value: "blockchain", label: "blockchain" },
  { value: "product management", label: "product management" },
  { value: "user experience", label: "user experience" },
  { value: "customer success", label: "customer success" },
  { value: "salesforce", label: "salesforce" },
  { value: "marketing automation", label: "marketing automation" },
  { value: "content marketing", label: "content marketing" },
  { value: "social media marketing", label: "social media marketing" },
  { value: "product design", label: "product design" },
  { value: "software engineering", label: "software engineering" },
  { value: "data engineering", label: "data engineering" },
  { value: "project management", label: "project management" },
  { value: "business development", label: "business development" },
  { value: "customer experience", label: "customer experience" },
  { value: "digital transformation", label: "digital transformation" },
  { value: "growth hacking", label: "growth hacking" },
  { value: "leadership development", label: "leadership development" },
  { value: "design thinking", label: "design thinking" },
  { value: "productivity", label: "productivity" },
  { value: "remote work", label: "remote work" },
  { value: "diversity and inclusion", label: "diversity and inclusion" },
  { value: "talent acquisition", label: "talent acquisition" },
  { value: "employee engagement", label: "employee engagement" },
  { value: "workplace culture", label: "workplace culture" },
  { value: "agile methodology", label: "agile methodology" },
  { value: "devops", label: "devops" },
  { value: "customer service", label: "customer service" },
  { value: "sales management", label: "sales management" },
  { value: "financial analysis", label: "financial analysis" },
  { value: "taxation", label: "taxation" },
  { value: "human resources", label: "human resources" },
  { value: "branding", label: "branding" },
  { value: "public relations", label: "public relations" },
  { value: "event planning", label: "event planning" },
  { value: "logistics", label: "logistics" },
  { value: "supply chain management", label: "supply chain management" },
  { value: "manufacturing", label: "manufacturing" },
  { value: "product marketing", label: "product marketing" },
  { value: "retail management", label: "retail management" },
  { value: "real estate", label: "real estate" },
  { value: "healthcare management", label: "healthcare management" },
  { value: "education technology", label: "education technology" }
];


export const socialPlatforms = [
  { label: "LinkedIn", value: "LINKED_IN" },
  { label: "Facebook", value: "FACEBOOK" },
  { label: "Quora", value: "QUORA" },
  { label: "Instagram", value: "INSTAGRAM" },
  { label: "WhatsApp", value: "WHATS_APP" },
  { label: "YouTube", value: "YOUTUBE" },
  { label: "Our Website", value: "OUR_WEBSITE" },
  { label: "Other", value: "OTHER" },
];

export const contentFormat = [
  {
    value: "Short Form Post", label: "Short Form Post"
  },
  {
    value: "Blog Post", label: "Blog Post"
  }
];
export const organisationType = [
  { label: "Personal", value: "PERSONAL" },
  { label: "Company", value: "COMPANY" },
  { label: "SME", value: "SME" },
  { label: "Large Corporations", value: "LARGE CORPORATIONS" },
  { label: "Middle-Tier", value: "MIDDLE-TIER" },
  { label: "Startup", value: "STARTUP" },
];


export const BotTypeOptions = [
  { label: "Website", value: "WEBSITE" },
  // { label: "Slack", value: "SLACK" },
  // { label: "MS_Teams", value:"MS_TEAMS"},
  { label: "Shopify", value:"SHOPIFY"},
  // { label: "WooCommerce", value:"WOO_COMMERCE"},
  // { label: "Magento", value:"MAGENTO"},
  // { label: "Salesforce", value:"SALESFORCE"},

]

export const colorOptions = [
  { label: 'Red', value: '#FF0000' },
  { label: 'Blue', value: '#0000FF' },
  { label: 'Green', value: '#008000' },
  { label: 'Yellow', value: '#FFFF00' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Purple', value: '#800080' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'Gray', value: '#808080' },
  { label: 'Black', value: '#000000' },
  { label: 'Cyan', value: '#00FFFF' },
  { label: 'Lime', value: '#00FF00' },
  { label: 'Magenta', value: '#FF00FF' },
  { label: 'Teal', value: '#008080' },
  { label: 'Olive', value: '#808000' },
  { label: 'Navy', value: '#000080' },
  { label: 'Maroon', value: '#800000' },
];
