// ** Icons Import
import { Home, Database, User, PieChart, HardDrive, MessageSquare, Calendar } from 'react-feather'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import "./index.scss"

const Footer = (props) => {
  const navigate = useNavigate();
  {/* The footer is used as a bottom navigation here */}
  const { navbarPayload } = props;
  // if(navbarPayload && navbarPayload.pageName){
  //   pageName = navbarPayload.pageName
  // }

  return (
    <div className='bottomNav clearfix mb-0' style={{height:'50px', width:'100%'}}>
        <div 
          onClick={()=>navigate('/dashboard/')} 
          className="bottomNavItem"
          // className={`bottomNavItem ${pageName==='Trending'?'selectedBottomNavItem':''}`}
        >
          <Home size={'17px'} style={{textAlign:'center'}}/>
          <div>Home</div>
        </div>
        <div 
          onClick={()=>navigate('/document-intelligence-history')} 
          className="bottomNavItem"
          // className={`bottomNavItem ${pageName==='Trending'?'selectedBottomNavItem':''}`}
        >
          <HardDrive size={'17px'} style={{textAlign:'center'}}/>
          <div>History</div>
        </div>
        {/* <div 
          onClick={()=>navigate('/analytics')} 
          className="bottomNavItem"
          // className={`bottomNavItem ${pageName==='Trending'?'selectedBottomNavItem':''}`}
        >
          <PieChart size={'17px'} style={{textAlign:'center'}}/>
          <div>Analytics</div>
        </div> */}
        <div 
          onClick={()=>navigate('/pages/account-settings')} 
          className="bottomNavItem"
          // className={`bottomNavItem ${pageName==='Trending'?'selectedBottomNavItem':''}`}
        >
          <User size={'17px'} style={{textAlign:'center'}}/>
          <div>Profile</div>
        </div>
    </div>
  )
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ navbarContent }) => {
  return {
    navbarPayload: navbarContent.payload,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
