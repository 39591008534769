import { useState, useEffect } from "react";
import { Copy, Check } from "react-feather";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from "copy-to-clipboard";

const Script = ({ script }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (script) {
            setCode(script);
        }
    }, [script]);


    const handleCopy = () => {
        if (code) {
            // const jsonString = JSON.stringify(parsedData, null, 2);
            copy(code);
            setIsCopied(true)

            // Reset isCopied back to false after 5 seconds
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);

        }
    }
    return (
        <>
            <div className="script-wrapper">
                <div className="script-wrapper__top">
                    <span className="script-wrapper__title">JS</span>
                    <button className="copy-btn" onClick={handleCopy}>
                        <span className="icon">{!isCopied ? <Copy /> : <Check />}</span>
                        <span className="copy-btn__title">{!isCopied ? 'Copy' : 'Copied'}</span>
                    </button>
                </div>
                <SyntaxHighlighter language="javascript" style={dracula} showLineNumbers={true} lineNumberStyle={true}>
                    {code}
                </SyntaxHighlighter>
            </div>
        </>

    )
}

export default Script;