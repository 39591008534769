import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
} from 'reactstrap';

import AceEditor from 'react-ace';
// import 'brace/mode/sql';
// import 'brace/theme/monokai';

function CodeEditorPopup({ isOpen, toggle, data, handleSQLMessage }) {
    const [code, setCode] = useState(data);
    function onChange(newValue) {
        setCode(newValue);
    }

    const handleSubmit = ()=>{
        handleSQLMessage(code)
        toggle();
    }      

    return (
        <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={false}>
            <ModalHeader>SQL Code Editor</ModalHeader>
            <ModalBody>
                {/* <Input
          type="textarea"
          placeholder="Write or edit your SQL code here..."
          rows={10}
          value={data ? data.sqlCode : ''}
          onChange={(e) => console.log(e.target.value)} // Handle any changes here
        /> */}

                <AceEditor
                    mode="mysql"
                    theme="xCode"
                    onChange={onChange}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    style={{ width: '100%', height: '200px' }}
                    value={code}
                />,


            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default CodeEditorPopup;
