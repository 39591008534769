import { useEffect, useState } from 'react';
import NewsCard from './NewsCard';
import './style.scss';
import { newsFetchRequest } from '../../../services/knowledgeCenter';
import { statusCode } from '../../../utility/constants/utilObject';

const NewsBlock = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')

    useEffect(() => {
        const newFetch = () => {
            setLoading(true);
            newsFetchRequest()
                .then((response) => {
                    if (response.status == statusCode.HTTP_200_OK) {
                        setLoading(false)
                        setNewsData(response.data);
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err)

                })
        }

        newFetch();
    }, []);

    return (
        <div className='news-block'>
            <h2 className='news-block__subtitle'>Weekly Market Updates</h2>
            <span className='news-block__content'>Updates for you from 7 days</span>

            {loading ?
                <div className="loader-wrapper">
                    <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8"
                        data-testid="oval-svg">
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" strokeWidth="2"></circle>
                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s"
                                        repeatCount="indefinite"></animateTransform>
                                </path>
                            </g>
                        </g>
                    </svg>

                </div>
                : error ?
                    <h2 className="error-msg">No Data Found</h2>
                    :
                    newsData &&
                    newsData.map((item) => {
                        if (!(item.title === "[Removed]")) {
                            return (
                                <NewsCard data={item} />
                            )
                        }

                    })
            }
        </div>
    )
}

export default NewsBlock;