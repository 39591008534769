// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'
import { checkAuth, checkAuthAdmin } from '../../../utility/auth'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'
import { isValidEmail } from '../../../utility/helper'
import { ONBOARDING_STATUS } from "../../../utility/constants/socialMediaPlatform";

const PrivateRoute = ({ children, route }) => {
  /**
   * For all private route, check if the token is present
   * Futher to extend into the admin by allowing the admin access
   */
  const ability = useContext(AbilityContext)
  const user = localStorage.getItem('token')
  const email = localStorage.getItem('email')
  const id = localStorage.getItem('id')
  const refresh = localStorage.getItem('refresh')
  const userRole = localStorage.getItem('userRole')
  const status = localStorage.getItem('status')

  let validUser = false
  if(user && email && id && refresh && isValidEmail(email)) {
    validUser = true
  }

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = false

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }

    if(validUser && status === ONBOARDING_STATUS.ONBOARDING_IN_PROCESS) {
      return <Navigate to='/register' />
    }
    if (!validUser) {
      return <Navigate to='/login' />
    }
    if (validUser && restrictedRoute) {
      return <Navigate to='/' />
    }
    if (validUser && restrictedRoute && userRole === 'client') {
      return <Navigate to='/access-control' />
    }
    // if (user && !ability.can(action || 'read', resource)) {
    //   return <Navigate to='/misc/not-authorized' replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
