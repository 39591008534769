import { useState, useEffect } from "react";

import "./index.scss"
import React from 'react';
import { Table, Button, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { PieChart, ThumbsDown, ThumbsUp } from "react-feather";
import CodeEditorPopup from "../CodeEditor";
import { postUserFeedbackHandler } from "../../../services/dbConnect";

const DBQueryResponse = ({ response, session, handleSQLMessage }) => {

  const { message, data, query_response, query } = response;

  const [feedbackMessage, setFeedbackMessage] = useState('Was this useful?');
  const [rSelected, setRSelected] = useState(null);
  const [visible, setVisible] = useState(true);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState(null);

  //Feedback Button handler
  const handleButtonClick = (response) => {
    setRSelected(response);
    setFeedbackMessage(response === 1 ? 'Thank you for your feedback!' : 'We appreciate your feedback!');
    // if(response){}
    const payload = {
      session: session,
      feedback: (response === 1) ? "True" : "False"
    }
    postUserFeedbackHandler(payload)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    setTimeout(() => {
      setVisible(false);
    }, 5000);
  };

  useEffect(() => {
    if (rSelected !== null) {
      // Store the response of the user
      console.log(`User response: ${rSelected === 1 ? 'Useful' : 'Not useful'}`);
    }
  }, [rSelected]);

  // Handler to active QUERY POUP

  const handleQueryClick = (chartData) => {
    console.log(chartData)
    //this chartDATA is dummy
    setPopupData(chartData);
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
    setPopupData(null);
  };

  // Rendering Table
  const renderTable = () => {
    if (!Array.isArray(data) || data.length === 0) return null;
    const rowsCount = data.length;
    const columnCount = Object.keys(data[0]).length;
    const columns = Object.keys(data[0]);

    return (
      <>
        <div style={{ textAlign: 'end', fontSize: '14px' }}>{rowsCount} rows returned / {columnCount} columns</div>
        <div style={{ maxHeight: '400px', overflowY: 'scroll', overflowX: 'auto' }}>
          <Table striped>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', whiteSpace: 'nowrap' }}>
              <tr>
                {columns.map((col) => (
                  <th key={col}>{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((col) => (
                    <td key={col}>{row[col]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="response-actions">
          <div className="response-actions__cta">
            {/* <Button outline color="primary" id="view-chart">
              <PieChart />
            </Button>
            <UncontrolledTooltip
              placement='bottom'
              target='view-chart'
            >
              View Chart
            </UncontrolledTooltip> */}

            <Button outline color="primary" id="sql-query" onClick={() => { handleQueryClick(query) }}>SQL</Button>
            <UncontrolledTooltip
              placement='bottom'
              target="sql-query"
            >
              Enter your custom SQL query
            </UncontrolledTooltip>
          </div>
          {
            visible &&

            <div className="response-actions__feedback">
              <span style={{ marginRight: '10px', fontSize: '12px' }}>{feedbackMessage}</span>
              <Button
                color="primary"
                outline
                onClick={() => handleButtonClick(1)}
                active={rSelected === 1}
              >
                <ThumbsUp />
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => handleButtonClick(2)}
                active={rSelected === 2}
              >
                <ThumbsDown />
              </Button>


            </div>
          }
        </div>
      </>
    );
  };

  return (
    <div>
      {message && <div className="message">{message}</div>}
      {data && renderTable()}

      {isPopupOpen && (
        <CodeEditorPopup
          isOpen={isPopupOpen}
          toggle={closePopup}
          data={popupData}
          handleSQLMessage={handleSQLMessage}
        />
      )}
    </div>
  );
};

export default DBQueryResponse;
