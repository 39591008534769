import Types from '../types';

const initialState = {
  botLeadDataList: [],
  botLeadData : {},
  count: 0,
  limit: 10,
  offset: 0,
  sort: '-created_at',
  search: ''
};

export default function botLeadReducer(state = initialState, action) {
  switch (action.type) {
    case Types.BOT_LEAD.SUCCESS:
      return {
        ...state,
        botLeadDataList: action.payload.data,
        count: action.payload.total_count
      };

    case Types.BOT_LEAD.GET_BOT_LEAD:
      return {
        ...state,
        botLeadData: action.payload,
      };

    case Types.BOT_LEAD.DATA_CLEAR:
      return {
        ...state,
        botLeadData: {},
        limit: 10,
        offset: 0,
        sort: '-created_at',
        search: ''
      }

    case Types.BOT_LEAD.SET_DATA:
      return {
        ...state,
        limit: action.payload.limit,
        offset: action.payload.offset,
        search: action.payload.search,
        sort: action.payload.sort,
      }

    default:
      return state;
  }
}
