// ** React Import
import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';

// ** Custom Components
import Sidebar from "@components/sidebar";
import MarkdownParser from "../components/MarkdownTypingEditor";

//services
import { fetchLedgerConversationHandler, fetchResponseToLedgerQueryHandler } from "../../services/ledger";

// ** Reactstrap Imports
import { Button, Form, Input, Spinner } from "reactstrap";
import { statusCode } from "../../utility/constants/utilObject";
import { BarLoader } from "react-spinners";
import { Send } from "react-feather";

import whiteBotImage from "../../assets/logo/bot@2x.png";

// ** Store & Actions
import { connect } from "react-redux";
import moment from 'moment';


const SidebarDashboard = ({ 
  open, 
  toggleSidebar, 
  title, 
  chatbotId,
  documentId
}) => {
  // Data variable
  const [chatbot_Id, setChatbotId] = useState(chatbotId);
  const [messages, setMessages] = useState([{
    "id": '0001',
    "message": "Hello!! How can I assist you today?",
    "isBot": true,
    "time": new Date().toLocaleTimeString()
  }]); // These messages are loaded from th backend
  const [message, setMessage] = useState("");

  // Loaders
  const [loader, setLoader] = useState(false);
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const [isFetchingResponse, setIsFetchingResponse] = useState(false);

  // Reference Variables
  const documentUploadRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isUserInputFocused, setIsUserInputFocused] = useState(false);


  "Initial Load of the document"
  "============================"
  useEffect(() => {
    loadChatMessages();
    setIsChatLoaded(false);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  "Hanlders for chat messages"
  "=========================="
  const scrollToBottom = () => {
    const scroll = chatContainerRef.current;
    if (scroll) {
      scroll.scrollTo({
        top: scroll.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const loadChatMessages = () => {
    fetchLedgerConversationHandler(documentId)
      .then(response => {
        setIsChatLoaded(true);
        if (response.status == statusCode.HTTP_200_OK) {
          setMessages([...messages, ...response.data.data]);
        } else {
          setMessages([...messages]);
        }
      })
      .catch((error) => {
        setIsChatLoaded(true);
        setMessages([...messages]);
      })
  }

  const handleBotReceivedMessage = (message) => {
    const messagePayload = {
      message: message,
      isBot: true,
      time: moment().format("hh:mm A"),
    }
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, messagePayload];
      return updatedMessages;
    });
    scrollToBottom();
  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    const messagePayload = {
      message: message,
      isBot: false,
      time: moment().format("hh:mm A"),
    }
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, messagePayload];
      return updatedMessages;
    });
    const scrollTimeout = setTimeout(() => {
      scrollToBottom();
      clearTimeout(scrollTimeout)
    }, 500)
    fetchBotResponse(message);
    setMessage("");
  }

  const fetchBotResponse = (query) => {
    setIsFetchingResponse(true);
    fetchResponseToLedgerQueryHandler({
      document: documentId,
      query: query
    })
      .then((response) => {
        
        setIsFetchingResponse(false);
        if (response.status == statusCode.HTTP_200_OK) {
          handleBotReceivedMessage(response.data.message);
        } else {
          handleBotReceivedMessage("I am currently facing some issues. Please try again later.");
        }
      })
      .catch((error) => {
        setIsFetchingResponse(false);
        handleBotReceivedMessage("I am currently facing some issues. Please try again later Error.");
      });
  }

  const handleTextMessage = (e) => { setMessage(e.target.value) }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();  // Prevent the default behavior of a line break
      handleSendMessage(event);  // Replace this with your actual send message function
    }
  };

  const handleFocus = () => setIsUserInputFocused(true);

  const handleBlur = () => setIsUserInputFocused(false);

  "Here new methods for message rendering will be present"
  "====================================================="

  const renderLoaderMessage = (message, loaderType) => {
    let loaderMessage = null;
    if (loaderType == "OLD_CHAT") {
      loaderMessage = <>
        Loading previous chat messages <BarLoader width="250" cssOverride={{ marginTop: '10px' }} color="#1972f5" />
      </>;
    } else {
      loaderMessage = <>
        Checking for your query <BarLoader width="250" cssOverride={{ marginTop: '10px' }} color="#1972f5" />
      </>;
    }
    return (
      <div
        key={message}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: 'flex-start',
          marginRight: "10px",
          marginBottom: "25px",
        }}
      >
        <div style={{ height: "30px", width: "30px", borderRadius: '5px' }}>
          <img src={whiteBotImage} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </div>
        <div style={{
          background: "#FFFFFF",
          color: "#000",
          padding: "10px",
          borderRadius: "10px",
          marginLeft: "10px",
          maxWidth: "70%",
          textAlign: "left",
          fontSize: "0.9em",
        }}>
          {loaderMessage}
        </div>
      </div>
    )
  }

  const renderBotMessage = (message) => {
    return (
      <div key={message}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: 'flex-end',
          marginRight: "10px",
          marginBottom: "25px",
          fontWeight: 'bold',
        }}>
        <div style={{ height: "30px", width: "30px", borderRadius: '5px' }}>
          <img src={whiteBotImage} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </div>
        <div style={{
          background: "#E0F2F1",
          color: "#000",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          borderRadius: "10px 10px 10px 0",
          marginLeft: "10px",
          maxWidth: "80%",
          textAlign: "left",
          fontSize: "1.1em",
          fontWeight: "600"
        }}>
          {<MarkdownParser isTyping={false} texts={[message]} />}
        </div>
      </div>
    )
  }

  const renderUserMessage = (message) => {
    return (
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'flex-start',
        marginRight: "10px",
        marginBottom: "25px",
      }}>
        <div style={{
          background: "#0C6980",
          color: "#ffffff",
          padding: "10px",
          borderRadius: "10px 0 10px 10px ",
          marginRight: "10px",
          maxWidth: "70%",
          textAlign: "right",
          fontSize: "1em",
        }}>
          {message}
        </div>
      </div>
    )
  }

  const renderMessages = () => {
    const messageArray = messages.map((message, index) => {
      if (message.isBot) {
        return renderBotMessage(message.message);
      } else {
        return renderUserMessage(message.message);
      }
    });
    if (isFetchingResponse) {
      messageArray.push(renderLoaderMessage())
    }
    if (!isChatLoaded) {
      messageArray.push(renderLoaderMessage("Loading previous chat messages", "OLD_CHAT"))
    }
    return messageArray
  }


  return (<Sidebar
    size="lg"
    open={open}
    title={title}
    headerClassName="mb-2"
    contentClassName="pt-2"
    toggleSidebar={toggleSidebar}
    onClosed={toggleSidebar}
    loader={loader}
  >
    <div style={{ width: "100%", height: "100%" }}>
      <div
        ref={chatContainerRef}
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          paddingBottom: "50px",
        }}
      >
        {renderMessages()}
      </div>
      <div className="sideform-wrapper">
        <Form>
          <Input id="bot-message-input"
            onChange={handleTextMessage}
            onKeyPress={handleKeyPress}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={message}
            name="message"
            placeholder="Ask any question pertaining to your document...."
            style={{ padding: "1%" }} />
          <button onClick={handleSendMessage} disabled={loader} className='icon'
            color={"primary"}><Send /></button>
        </Form>
      </div>

    </div>

  </Sidebar>);
};

const mapDispatchToProps = {
  // uploadDocument
};

const mapStateToProps = ({ document }) => {
  return {
    botScript: document.botScript,
    botData: document.botData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SidebarDashboard);
