import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// axios

// import axios from 'axios';


//ReactStrap

import {
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";

//icons
// import { FaSearch } from 'react-icons/fa';

//country flags
// import CountryFlag from 'react-country-flag';

//Upload Documents
import StatementProcess from "./StatementProcess";
import { useSelector, useDispatch, connect } from "react-redux";
import { bankListAction } from "../../../redux/actions/bankList";
import { firstBankSelectedAction } from "../../../redux/actions/bankSelected";


const Banks = ({ bankListAction, bankList, loading, error, firstBankSelectedAction }) => {
  const [mainBanks, setMainBanks] = useState([]);
  const [otherBanks, setOtherBanks] = useState([]);
  const [recentBanks, setRecentBanks] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    code: "IN"
  });
  const navigate = useNavigate();


  const handleItemClick = (item) => {

    if (item) {
      setSelectedItem(item);
      firstBankSelectedAction(item);
      navigate("/financial-statement-analyser/upload-statement", { state: { item } });
    }
  };

  // const handleSearch = (query) => {
  //   const filteredResults = otherBanks.filter(item => {
  //     return item.name.toLowerCase().includes(query.toLowerCase());
  //   });
  //   setFilteredData(filteredResults);
  //   setSearchQuery(query);
  // };

  useEffect(() => {
    bankListAction(selectedCountry.name);
  }, []);

  useEffect(() => {
    if (bankList && bankList?.MAIN_BANK) {
      setMainBanks(bankList.MAIN_BANK || []);
      setOtherBanks(bankList.OTHER_BANK || []);
    }
    if (bankList?.RECENTLY_USED) {
      setRecentBanks(bankList.RECENTLY_USED || []);
    }
  }, [bankList]);


  useEffect(() => {
    setFilteredData(otherBanks);
  }, [otherBanks]);

  // const changeComponent = (file, state) => {
  //   setHandleFile(file);
  //   handlePreviewFile(file);
  // };

  const groupByFirstLetter = (items) => {
    const groups = {};

    items.forEach((item) => {
      const firstLetter = item.name.charAt(0).toUpperCase(); 
      if (!groups[firstLetter]) {
        groups[firstLetter] = []; 
      }
      groups[firstLetter].push(item); 
    });

    return groups;
  };

  const sortAlphabetically = (groups) => {
    return Object.keys(groups).sort(); // Sort keys alphabetically
  };

  const groupedData = groupByFirstLetter(filteredData); // Group the data
  const sortedKeys = sortAlphabetically(groupedData);

  return (
    <div className="banks-selection">
      <div className="banks-selection__inner" banks-selection>

        {
          loading ?
            <div className="loader-wrapper">
              <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#0082AC"
                data-testid="oval-svg">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                    <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#0082ac6e" strokeWidth="2"></circle>
                    <path d="M20 0c0-9.94-8.06-20-20-20">
                      <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s"
                        repeatCount="indefinite"></animateTransform>
                    </path>
                  </g>
                </g>
              </svg>

            </div>
            : error || mainBanks.length === 0 ?
              <h2 className="error-msg">{error?.detail || "No Banks Found"}</h2>
              :
              <>
                {
                  recentBanks && recentBanks.length === 0 ? ""
                    :
                    <div className="main-banks">
                      <h2>Recent Banks</h2>
                      <div className="banks_wrapper">


                        {
                          recentBanks.map((item) => {
                            return (
                              <button className="main-banks__tile" key={item?.code || ""}
                                onClick={() => handleItemClick(item)}>
                                <img src={item?.image_url || ""} alt="sbi" />
                                <span className="bank-name">{item.name}</span>
                              </button>
                            );
                          })
                        }
                      </div>
                    </div>
                }
                {
                  mainBanks && mainBanks.length === 0 ? ""
                    :
                    <div className="main-banks my-2">
                      <h2>Popular Banks</h2>
                      <div className="banks_wrapper">
                        {
                          mainBanks.map((item) => {
                            return (
                              <button className="main-banks__tile" key={item?.code || ""}
                                onClick={() => handleItemClick(item)}>
                                <img src={item?.image_url || ""} alt="sbi" />
                                <span className="bank-name">{item.name}</span>
                              </button>
                            );
                          })
                        }
                      </div>
                    </div>
                }

                {
                  // otherBanks && otherBanks.length === 0 ? ""
                  //   :
                  //   <div className="search-bank my-2">
                  //     {/* <FormGroup>
                  //                       <span className="input-icon">
                  //                           <FaSearch />
                  //                       </span>
                  //                       <Input
                  //                           id="bankSearch"
                  //                           name="search"
                  //                           placeholder="Search Bank"
                  //                           type="search"
                  //                           value={searchQuery}
                  //                           onChange={(e) => handleSearch(e.target.value)}
                  //                       />
                  //                   </FormGroup> */}

                  otherBanks.length > 0 ?
                  <div className="main-banks other-banks">
                    <h2>Other Banks</h2>
                    {


                      sortedKeys.map(letter => {
                        return (
                          <div key={letter}>
                            <h2 className="letters">{letter}</h2> {/* Section header */}
                            <div className="banks_wrapper">
                              {groupedData[letter].map((item) => (
                                <button className="main-banks__tile" key={item?.code || ""}
                                  onClick={() => handleItemClick(item)}>
                                  <img src={item?.image_url || ""} alt="sbi" />
                                  <span className="bank-name">{item?.name || ''}</span>
                                </button>
                              ))}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  : ""
                }

              </>
        }
      </div>
      {/* </div> */}
    </div>
  );

};

const mapDispatchToProps = {
  bankListAction,
  firstBankSelectedAction
};

const mapStateToProps = ({ bankListReducer }) => {
  return {
    bankList: bankListReducer.bankList,
    loading: bankListReducer.loading,
    error: bankListReducer.error
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Banks);