// ** React Imports
import { Fragment, useState } from "react";

// ** Third Party Components
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft, ChevronRight } from "react-feather";
import {
  businessDomains,
  organisationType, socialPlatforms
} from "../../../../utility/constants/options";

// ** Reactstrap Imports
import { Form, Label, Input, Row, Col, Button, FormFeedback, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { deleteFile, uploadFile } from "../../../../services/aws-services/s3Upload";
import { onboardOrganisation } from "../../../../services/onboarding";
import { statusCode } from "../../../../utility/constants/utilObject";
import toast from "react-hot-toast";
import { toastStyle } from "../../../../utility/helper";

const style = {
  padding: "0.571rem 1rem",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #d8d6de",
  width: "100%",
  borderRadius: "0.357rem",
  display: "block",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.45",
  color: "#6e6b7b",
  appearance: "none",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
};

const defaultValues = {
  name: "",
  domain: "",
  org_type: "",
  logo: "",
  referred_by: "",
  website: ""
};

const CompanyDetails = ({ stepper }) => {
  const companyOnboardingSchema = yup.object().shape({
    name: yup.string().required(),
    domain: yup.string().required(),
    org_type: yup.string().required(),
    referred_by: yup.string().required()
  });

  const [loader, setLoader] = useState(false);
  const [file, setFileData] = useState("");

  const navigate = useNavigate();
  // ** Hooks

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(companyOnboardingSchema)
  });

  const submitCompanyProfileData = (data, key='') => {
    onboardOrganisation(data).then(res => {
      if (res && res.status && res.status.toString() === statusCode.HTTP_201_CREATED) {
        const response = res.data.data;
        toast.success("Organisation created successfully.", { ...toastStyle.success });
        localStorage.setItem("plan", response.plan);
        localStorage.setItem("status", response.status);
        localStorage.setItem("company_name", response.name);
        localStorage.setItem("company_id", response.id);
        localStorage.setItem("credit_available", response["credit_available"]);
        localStorage.setItem("logo", response.logo);
        setLoader(false);
        setFileData("");
        navigate("/dashboard");
      } else if (res.response && res.response.status && res.response.status.toString() === statusCode.HTTP_400_BAD_REQUEST) {
        toast.error(`${res.response.data && res.response.data.error ? res.response.data.error : "Something went wrong, Please try again"}`,
          { ...toastStyle.error });
        setLoader(false);
        deleteFile(key, 'insight-ai').then(res => {}).catch(err => {console.log('Error while deleting image file', err)});
      } else {
        toast.error(`${res.response && res.response.data && res.response.data.error ? res.response.data.error : "Something went wrong, Please try again"}`,
          { ...toastStyle.error });
        setLoader(false);
      }
    }).catch(err => {
      setLoader(false);
      console.log("error while onboarding the organisation", err);
    });
  };

  const onSubmit = data => {
    setLoader(true);
    if (file) {
      uploadFile(file, "insight-ai", 'org-logo').then(res => {
        data.logo = res.Location;
        return submitCompanyProfileData(data, res.key);
      }).catch(err => {
        setLoader(false);
        console.log("Error while uploading the file", err);
      });
    } else {
      return submitCompanyProfileData(data);
    }

  };

  const handleFileUpload = (imageFile) => {
    if (imageFile[0] && imageFile[0].type.startsWith("image")) {
      setFileData(imageFile[0]);
    }
  };

  const handleFileRemove = () => {
    setFileData("");
  };

  return (
    <Fragment>
      <div className="content-header mb-2">
        <h2 className="fw-bolder mb-75">Let's Create Your Company Account</h2>
        <span>Enter your company details</span>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6" className="mb-1">
            <Label className="form-label" for="company_name">
              Company Name
            </Label>
            <span className="text-danger">*</span>
            <Controller
              id="name"
              name="name"
              control={control}
              render={({ field }) => <Input placeholder="john" invalid={errors.name && true} {...field} />}
            />
            {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
          </Col>
          <Col md="6" className="mb-1">
            <Label className="form-label" for="last_name">
              Industry Domain
            </Label>
            <span className="text-danger">*</span>
            <Controller
              id="domain"
              name="domain"
              control={control}
              render={({ field }) =>
                <select style={style} {...field} className={errors.domain ? "is-invalid" : ""}>
                  <option value="">Select an option</option>
                  {businessDomains.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              }
            />
            {errors.domain && <FormFeedback>{errors.domain.message}</FormFeedback>}
          </Col>
          <Col md="6" className="mb-1">
            <Label className="form-label" for="org_type">
              Organisation Type
            </Label>
            <span className="text-danger">*</span>
            <Controller
              id="org_type"
              name="org_type"
              control={control}
              render={({ field }) =>
                <select style={style} {...field} className={errors.org_type ? "is-invalid" : ""}>
                  <option value="">Select an option</option>
                  {organisationType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              }
            />
            {errors.org_type && <FormFeedback>{errors.org_type.message}</FormFeedback>}
          </Col>
          <Col md="6" className="mb-1">
            <Label className="form-label" for={`mobile`}>
              Website
            </Label>
            <Controller
              control={control}
              id="website"
              name="website"
              render={({ field }) => (
                <Input type="text" placeholder="Website Url" invalid={errors.website && true} {...field} />
              )}
            />
            {errors.website && <FormFeedback>{errors.website.message}</FormFeedback>}
          </Col>
        </Row>
        <Row>
          <div className="form-password-toggle col-md-6 mb-1">
            <Label className="form-label" for="referred_by">
              From where did you heard about us?
            </Label>
            <span className="text-danger">*</span>
            <Controller
              id="referred_by"
              name="referred_by"
              control={control}
              render={({ field }) =>
                <select style={style} {...field} className={errors.referred_by ? "is-invalid" : ""}>
                  <option value="">Select an option</option>
                  {socialPlatforms.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              }
            />
            {errors.referred_by && <FormFeedback>{errors.referred_by.message}</FormFeedback>}</div>
        </Row>
        {loader ? <div className="sipnner-container">
            <Spinner color="primary" />
          </div> :
          <div className="d-flex justify-content-end mt-2">
            {/*<Link className="btn-prev" to={"/login"}>*/}
            {/*  <ChevronLeft size={14} className="align-middle me-sm-25 me-0"></ChevronLeft>*/}
            {/*  <span className="align-middle d-sm-inline-block d-none">Sign In Instead</span>*/}
            {/*</Link>*/}
            <Button type="submit" color="primary" className="btn-next">
              <span className="align-middle d-sm-inline-block d-none">Next</span>
              <ChevronRight size={14} className="align-middle ms-sm-25 ms-0"></ChevronRight>
            </Button>
          </div>}
      </Form>
    </Fragment>
  );
};

export default CompanyDetails;
