export const colors = {
    editorGreen: '#43A047',
    white:'#FFFFFF',
    black:'#000000',
    primaryColor: '#2196F3',
    editIconGrey: '#90A4AE',
    logoPink: '#ed73b7',
    logoBlue: '#8e8fe2',
    logoPurple: '#a065d8',
    logoMagenta: '#ce71d7',
    logoLightPurple: '#b38be3',
    logoLightPink: '#eb6fdc',
    logoLightMagenta: '#d294d5',
    logoDarkBlue: '#8c8ec4',
    errorRed: '#FF5722',

    // InsightAI Logo Color
    iDarkGreen: '#44d4b4',
    iDarkBlue: '#44acdc',
    iDarkBlueShade2: '#44ace4',
    iLightGreen: '#60dcbc',
    iDarkYello: '#d5b55f',
    iLightBlue: '#8bccf2',
    iVeryLightBlue: '#d0eaf4',
    iBrightGreen: '#34d4ac'
}