import RecommendBlogCard from "./RecommendBlogCard";
import { blogsFetchRequest } from "../../../services/knowledgeCenter";
import './style.scss';
import { useEffect, useState } from "react";

const RecommendedBlogs = () => {
    const [blogsList, setBlogList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')

    useEffect(() => {
        const blogFetch = async () => {
            try {
                setLoading(true);
                const request = await blogsFetchRequest();
                if (request?.data) {
                    setLoading(false)
                    setBlogList(request.data)
                }
            } catch (err) {
                setLoading(false);
                setError(err)

            }

        }

        blogFetch();
    }, [])
    return (
        <div className="recommended-blogs">
            <h2 className="custome-subtitle">Recommended For You</h2>

            {loading ?
                <div className="loader-wrapper">
                    <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8"
                        data-testid="oval-svg">
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" strokeWidth="2"></circle>
                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s"
                                        repeatCount="indefinite"></animateTransform>
                                </path>
                            </g>
                        </g>
                    </svg>

                </div>
                : error ?
                    <h2 className="error-msg">No Data Found</h2>
                    :

                    < div className="recommended-blogs__grid">
                        {
                            blogsList &&
                            blogsList.slice(0, 3).map((item) => {
                                return (
                                    <RecommendBlogCard data={item} />
                                )

                            })
                        }

                    </div>
            }

        </div >
    )
}

export default RecommendedBlogs;