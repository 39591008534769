import Types from '../types';

const initialState = {
    bankResponse: {},
    loading: false,
    error: {}
};

export default function bankStatementReducer(state = initialState, action) {
    switch (action.type) {
        case Types.BANKS_STATEMENT.PENDING:
            return {
                ...state,
                loading: true
            };
        case Types.BANKS_STATEMENT.FULFILLED:
            return {
                ...state,
                bankResponse: action.payload,
                loading: false
            };

        case Types.BANKS_STATEMENT.ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}