import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

import notificationIcon from "@src/assets/images/pages/gift.png";
// import notificationIcon from "@src/assets/images/pages/trophy.png";


const NotificationModel = ({ open, toggleNotification }) => {
    const { width, height } = useWindowSize();

    const handleToggle = () => {
        toggleNotification()
    }
    return (
        <div className='pop'>
            <Confetti
                width={width}
                height={height}

                style={{ zIndex: '1056' }}
            />
            <Modal isOpen={open} toggle={handleToggle} backdrop="static" className='notification-modal'>
                <div style={{position: 'absolute', width:{width},
                height:{height}}}>

                
                {/* <Confetti
                    width={width}
                    height={height}

                    style={{ zIndex: '1056' }}
                /> */}
                </div>
                <button onClick={handleToggle} className='btn-close'></button>
                <ModalBody>

                    <div className='notification-modal__icon'>
                        <img src={notificationIcon} alt='/' />
                    </div>
                    <p>Congratulations! <b>Free $50 </b> in credits have been added to your account.</p>
                    {/* <p>We are in the process of integration and will go live soon. Stay tuned for the exciting launch!</p> */}

                    {/* <Button color='primary' onClick={handleToggle}>Explore Now</Button> */}
                </ModalBody>
            </Modal>
        </div>
    )
}

export default NotificationModel;