// ** React Imports
import React, { useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom'

//style
import './style.scss';
import InsightAILogo from '../../../assets/logo/nav/Logo-white.png';
import BG from '../../../assets/images/backgrounds/bg-blue.png';
// ** Custom Components
import Wizard from '@components/wizard'

// ** Reactstrap Imports
import { Col, Row } from "reactstrap";
import { Globe, User } from "react-feather";
import AccountDetails from './steps/AccountDetails';
import RegisterForm from "./RegisterForm";
// ** Styles
import '@styles/react/pages/page-authentication.scss'
import CompanyProfile from "./steps/CompanyProfile";

const Register = () => {
  const [stepper, setStepper] = useState(null);
  const location = useLocation();

  // ** Ref
  const ref = useRef(null)
  // const userData = location.state;

  const source = require('@src/assets/images/pages/create-account.png').default

  return (
    <div className='auth-wrapper auth-cover'>
      <div className='auth-inner'>
        <div className="auth-inner__left" style={{ backgroundImage: `url(${BG})` }} >
          <Link className="brand-logo" to="/">
            <img src={InsightAILogo} style={{ width: "140px", height: "30px" }} alt={"logo"} />
          </Link>

          <div className="auth-inner__content">
            <h1 className="welcome-title">
              Welcome to Insight<span>AI</span>
            </h1>

            <p>Embrace the Future of Productivity: Empower Your Data with InsightAI - Your Pathway to a Smarter, More Efficient Workspace!</p>
          </div>
          
        </div>


        <div className='auth-inner__right '>
          <RegisterForm />
        </div>
      </div>
    </div>
  )
}

export default Register
