import React, { useState, useEffect } from 'react';
import {   
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    Button,
    Input
} from 'reactstrap';
import './index.scss'
import { showErrorToast, showSuccessToast } from '../../../utility/helper';


const UrlDataIntegrations = ({urlType = "Website", onClose}) => {
    const [alertModalShow, setAlertModalShow] = useState(true);
    const [inputUrl, setInputUrl] = useState();
    const [isValidUrl, setIsValidUrl] = useState(true);

    const handleInputChange = (event) => {
        setIsValidUrl(true);
        setInputUrl(event.target.value);
    };

    const handleProceed = () => {
        // Basic URL validation
        const isValid = /^(ftp|http|https):\/\/[^ "]+$/.test(inputUrl);
        if (isValid) {
            // showSuccessToast("URL is valid");
            setIsValidUrl(true);
        }else{
            // showSuccessToast("URL is valid");
            setIsValidUrl(false);
        }
    };

    const renderPromptModal = () => {
        if(alertModalShow){
            return (
                <Modal style={{ zIndex: '100' }} isOpen={alertModalShow}>
                    <ModalHeader>{`Enter ${urlType} URL`}</ModalHeader>
                    <ModalBody>
                        <Input type="text" value={inputUrl} onChange={handleInputChange} />
                        {!isValidUrl && <div style={{color: 'red', marginTop: '10px'}}>Invalid URL!</div>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleProceed}>Proceed</Button>{' '}
                        <Button color="secondary" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    return (
        <>
            {renderPromptModal()}
        </>
    );
};

export default UrlDataIntegrations;
