import Types from '../types';

const initialState = {
    bankSelect: {},
};

export default function firstBankSelectedReducer(state = initialState, action) {
    switch (action.type) {
        case Types.SET_FIRST_BANK_SELECTED:
            return {
                ...state,
                bankSelect: action.payload,
            };
        default:
            return state;
    }
}