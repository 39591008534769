import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import icon from './text.png';
import './style.scss';

//image
import Bivek from '../../../assets/images/testimonialImg/bivek.png';
import Gopal from '../../../assets/images/testimonialImg/gopal.jpg';
import Himanshu from '../../../assets/images/testimonialImg/himanshu.jpg';
import Dinesh from '../../../assets/images/testimonialImg/Dinesh.jpeg';
import Pradeep from '../../../assets/images/testimonialImg/pradeep.jpeg';


const items = [
    {
      src: Dinesh,
      altText: 'product testimonial',
      caption: 'The team at InsightAI has been supportive and knowledgeable throughout our engagement. Their contributions have been valuable, helping us to address some of our business challenges more effectively.',
      author: 'Dinesh',
      company: '',
      key: 1,
    },
    {
      src: Bivek,
      altText: 'product testimonial',
      caption: "Working with InsightAI has given us a better perspective on our business data. While it's a journey, their insights have already pointed us towards areas for growth that we hadn't fully considered before.",
      author: 'Bivek',
      company: '',
      key: 2,
    },
    {
      src: Pradeep,
      altText: 'product testimonial',
      caption: "Since implementing InsightAI's solutions, we've noticed a steady improvement in our operational workflows. Their approach is innovative, and while there are always areas to refine, we're seeing clear benefits.",
      author: 'Pradeep',
      company: '',
      key: 3,
    },
    {
        src: Gopal,
        altText: 'product testimonial',
        caption: "The integration of InsightAI's solutions into our existing systems was smoother than expected. Their technology is advanced yet user-friendly, and we’re beginning to see how it can add value to our business.",
        author: 'Gopal',
        company: '',
        key: 4,
      },
      {
        src: Himanshu,
        altText: 'product testimonial',
        caption: "InsightAI has provided us with tools that significantly improved our decision-making process. While we're still exploring the full potential of their solutions, the initial results have been promising, making our team more efficient.",
        author: 'Himanshu',
        company: '',
        key: 5,
      },
  ];
  

const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonial-carousel">
            <Slider {...settings}>
                {items.map((item) => (
                    <div className="testimonial-slide" key={item.key}>
                        <div className='testimonial-slide__top'>
                            <div className="testimonial-slide__img">
                                <img src={item.src} alt={item.altText} />
                            </div>
                            <div className='testimonial-slide__info'>
                                <div className="testimonial-author">{item.author}</div>
                                <span className="testimonial-company">{item.company}</span>
                            </div>
                        </div>

                        <div className="testimonial-slide__text">
                            <span className="quote"><img src={icon} alt="/" /></span>
                            <div className="testimonial-text">{item.caption}</div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Testimonial;