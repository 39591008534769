// ** React Imports
import { useState, Fragment, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// ** Reactstrap Imports
import { Card, CardBody, Button, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody } from 'reactstrap'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { FileText, X, DownloadCloud, ArrowLeft } from 'react-feather'
import { FaFilePdf, FaFileImage, FaFileAlt } from 'react-icons/fa';
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';

//Bank statement API

import { bankStatement } from "../../../redux/actions/bankStatement";
import BankHeader from "./BankHeader";
import StatementProcess from "./StatementProcess";

import UploadIcon from '../../../assets/images/icons/upload.svg'

import "./style.scss";

const UploadStatement = ({selectedBankItem}) => {

    // ** State
    const [files, setFiles] = useState([])
    const [file, setFileData] = useState("");
    const [showProcess, setShowProcess] = useState(false);
    const [selectedItem, setSelectedItem] = useState({})
    const dispatch = useDispatch();

    const location = useLocation();
    const bankData = location.state?.item;
    const navigate = useNavigate();

    useEffect(() => {
        if(bankData && !selectedBankItem){
            setSelectedItem(bankData);
        }
        

        if(selectedBankItem){
            setSelectedItem(selectedBankItem);
        }
    }, [bankData, selectedBankItem])

    const handleFileUpload = (pdfFile) => {
        if (pdfFile[0] && pdfFile[0].type === "application/pdf") {
            setFileData(pdfFile[0]);
        }
    };

    const handleFileRemove = () => {
        setFileData("");
    };

    useEffect(() => {
        if (files && files.length) {
            handleFileUpload(files);
        }
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: 'application/pdf',
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length) {
                toast.error('You can only upload PDFs!');
            } else {
                setFiles([...files, ...acceptedFiles.map(file => Object.assign(file))]);
            }
        },
    });

    const renderFilePreview = file => {
        if (file.type === 'application/pdf') {
            return <FaFilePdf size='28' />;
        } else if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />;
        } else {
            return <FileText size='28' />;
        }
    }

    const handleRemoveFile = index => {
        setFiles(currentFiles => currentFiles.filter((_, i) => i !== index));
        handleFileRemove();
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    // const handleRemoveAllFiles = () => {
    //     setFiles([]);
    // }
    const handleUploadFiles = async () => {

        if (files) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bank', selectedItem?.id);

            //action dispatched
            dispatch(bankStatement(formData));
            setShowProcess(true);
        }
    }

    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='preview-cards d-flex align-items-center'>
            <div className='file-preview me-1'>{renderFilePreview(file)}</div>
            <div className="file-data">
                <p className='file-name mb-0'>{file.name}</p>
                <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
            </div>
            <Button size='sm' className='btn-icon close-btn' onClick={() => handleRemoveFile(index)}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    ))

    // Determine class based on multiple conditions
    let dropzoneClass = 'custom-dropzone';

    if (fileList.length > 4) {
        dropzoneClass += ' has-files limit-exceeded'; // will add this class if the file count exceeds 5
    } else if (fileList.length > 0) {
        dropzoneClass += ' has-files'; // will add this class only if there's at least one file
    }

    
    const backBtnHandle = () => {
        navigate(-1)
    }

    return (
        <>
            <Button onClick={backBtnHandle} color="link" className="backIcon">
                <ArrowLeft>
                </ArrowLeft>
                <span>Back</span>
            </Button>

            <Card className={showProcess? 'bsp-upload wd-processbar' : 'bsp-upload'}>
                <div style={{display:"block"}} className='container-fluid'>
                    <BankHeader activeStep={1} />

                    <div className='page-container no-scroll-bar '>
                        {/* <button className="back-btn" onClick={toggle}> <ArrowLeft size={20} /></button> */}
                        {showProcess ? <StatementProcess selectedItem={selectedItem} files={files} /> :
                            <>

                                <h2 className="upload-title">Please upload your clients’ bank statement(s) for {selectedItem?.name || ''} in PDF format and make sure to upload all documents from same client only.</h2>
                                {/* <CardBody> */}
                                {
                                    // (fileList.length < 5) &&
                                    <div>

                                        <div className={dropzoneClass}>
                                            {fileList}
                                            <div {...getRootProps({ className: 'dropzone custom-dropzone__field' })}>
                                                <input {...getInputProps()} />
                                                <div className='d-flex align-items-center justify-content-center '>
                                                    {/* <DownloadCloud size={24} /> */}
                                                    <img src={UploadIcon} alt="/" className="upload-icon"/>
                                                    <small>Upload bank statement(s)</small>
                                                </div>
                                            </div>
                                        </div>
                                        <span className={(fileList.length > 4) ? "text-center d-block mb-2 custome-note limit" : "text-center d-block mb-2 custome-note"}>{(fileList.length > 4) ? 'Limit reached! Cannot upload more than 5 documents. Please remove or replace any of the above document(s) to add new' : 'Note: Maximum 5 documents can be Uploaded'}</span>

                                    </div>
                                }

                                {/* {files.length ? ( */}
                                <Fragment>
                                    {/* <ListGroup className='my-2'>{fileList}</ListGroup> */}
                                    <div className='d-flex justify-content-start'>
                                        {/* <Button className='me-1' color='danger' onClick={handleRemoveAllFiles}>
                                        Remove All
                                    </Button> */}
                                        <Button color='primary' onClick={handleUploadFiles} className="upload-btn" disabled={files.length <= 0} >Continue</Button>
                                    </div>
                                </Fragment>
                                {/* ) : null} */}
                                {/* </CardBody> */}
                            </>

                        }
                    </div>
                </div>
            </Card>

        </>
    )
}

const mapStateToProps = state => ({
    selectedBankItem: state.bankSelectedReducer.selectedBank
});


export default connect(mapStateToProps)(UploadStatement);