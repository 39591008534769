import Types from "../types";


export const firstBankSelectedAction = (bank) => {
    return async (dispatch) => {

        dispatch({
            type: Types.SET_FIRST_BANK_SELECTED,
            payload: bank
        });
    };
};