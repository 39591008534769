import { useState } from "react";

// Define database constants
const databases = [
    { name: 'MySQL', image: require('../../assets/images/DB/my-sql.png').default, dbName: 'MYSQL', premium: false },
    { name: 'PostgreSQL', image: require('../../assets/images/DB/postgres.png').default, dbName: 'POSTGRESQL', premium: true },
    { name: 'Redshift', image: require('../../assets/images/DB/redshift.png').default, dbName: 'REDSHIFT', premium: true },
    { name: 'SAP Database', image: require('../../assets/images/DB/sap.jpeg').default, dbName: 'SAP', premium: true },
    { name: 'MongoDB', image: require('../../assets/images/DB/mongo.png').default, dbName: 'MONGODB', premium: true },
    { name: 'SQLite', image: require('../../assets/images/DB/sql-lite.png').default, dbName: 'SQLITE', premium: true },
    { name: 'Snowflake', image: require('../../assets/images/DB/snowflakes.png').default, dbName: 'SNOWFLAKE', premium: true },
    { name: 'Oracle', image: require('../../assets/images/DB/oracle.png').default, dbName: 'ORACLE', premium: true },
    { name: 'SQL Server', image: require('../../assets/images/DB/msq-server.png').default, dbName: 'SQLSERVER', premium: true },
    { name: 'Cassandra', image: require('../../assets/images/DB/cassandra.webp').default, dbName: 'CASSANDRA', premium: true },
    { name: 'Elasticsearch', image: require('../../assets/images/DB/elastic.png').default, dbName: 'ELASTICSEARCH', premium: true },
    { name: 'BigQuery', image: require('../../assets/images/DB/postgres.png').default, dbName: 'BIGQUERY', premium: true },
    { name: 'Teradata', image: require('../../assets/images/DB/teradata.png').default, dbName: 'TERADATA', premium: true },
    { name: 'DB2', image: require('../../assets/images/DB/db2.png').default, dbName: 'DB2', premium: true },
    { name: 'DynamoDB', image: require('../../assets/images/DB/DynamoDB.png').default, dbName: 'DYNAMODB', premium: true },
    { name: 'HBase', image: require('../../assets/images/DB/Hbase.png').default, dbName: 'HBASE', premium: true },
    { name: 'Hive', image: require('../../assets/images/DB/hive.png').default, dbName: 'HIVE', premium: true },
    { name: 'Impala', image: require('../../assets/images/DB/Impala.png').default, dbName: 'IMPALA', premium: true },
    { name: 'Neo4j', image: require('../../assets/images/DB/neo-removebg-preview.png').default, dbName: 'NEO4J', premium: true },
    { name: 'Redis', image: require('../../assets/images/DB/Redis.png').default, dbName: 'REDIS', premium: true },
    { name: 'InfluxDB', image: require('../../assets/images/DB/influx.png').default, dbName: 'INFLUXDB', premium: true },
    { name: 'CouchDB', image: require('../../assets/images/DB/couch.webp').default, dbName: 'COUCHDB', premium: true },
];


const DBSelection = ({ handleForm }) => {
    const [filteredDB, setFilteredDB] = useState(databases);

    const handleDB = (dbName) => {
        handleForm(dbName);
    };

    const handleSearch = (e) => {
        const search = e.target.value;
        const filtered = databases.filter(db => db.name.toLowerCase().includes(search.toLowerCase()));
        setFilteredDB(filtered);
    }

    return (
            <div className="db-selection">
                <div style={{
                    width: '100%',
                    height: '40px',
                    borderBottom: '1px solid #d8d6de',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <div style={{
                        height:'18px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    }}>Select Database</div>
                    <input 
                        type="text" 
                        className="db-search"
                        placeholder="Search Database..." 
                        onChange={handleSearch}
                        style={{
                            height: '30px',
                            width: '300px',
                            borderRadius: '5px',
                            border: '1px solid #d8d6de',
                            padding: '0 10px',
                    }} />
                </div>
                <div className="db-itelligence__db-selection">
                    {filteredDB.map((db, index) => (
                        <div 
                            className={`db-card ${db.premium ? 'db-card--premium' : ''}`} 
                            key={index} 
                            onClick={() => handleDB(db.name)}
                        >
                            {db.premium && <span className="db-card__premium-tag">Premium</span>}
                            <div className="db-card__img">
                                <img src={db.image} alt={db.name} />
                            </div>
                            <div className="db-card__title">{db.name}</div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default DBSelection;
