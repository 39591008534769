import { createBot, updateBot } from "../../services/bot";
import { statusCode } from "../../utility/constants/utilObject";
import Types from "../types";
import toast from "react-hot-toast";
import { fetchOrganisation } from "../../services/onboarding";

export const createOrganisationInstance = (botPayload) => async (dispatch) => {
  createBot(botPayload).then(res => {
    if (res.status.toString() === statusCode.HTTP_201_CREATED) {
      toast.success('Bot Create Successfully.')
      dispatch({ type: Types.ORGANISATION.SCRIPT, payload: res.data.data });
      if(botPayload.bot_type !== 'WEBSITE') {
        window.location.replace("/bot-list");
      }
    } else {
      toast.error('Error occurred while updating bot, Please contact support');
    }
  });
};

export const updateOrganisationInstance = (botPayload, id) => async (dispatch) => {
  updateBot(botPayload, id).then(res => {
    if (res.status.toString() === statusCode.HTTP_200_OK) {
      toast.success('Bot Updated Successfully.')
      dispatch({ type: Types.ORGANISATION.UPDATE_DATA, payload: res.data.data });

      if(botPayload.bot_type !== 'WEBSITE') {
        window.location.replace("/bot-list");
      }
      //....AWS SCRIPT UPDATE ....//
    } else {
      toast.error('Error occurred while updating bot, Please contact support');
    }
  });
};

export const fetchOrganisationData = (id) => async (dispatch) => {
  fetchOrganisation(id).then(res => {
    if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
      dispatch({ type: Types.ORGANISATION.GET_DATA, payload: res.data });
    } else {
      toast.error('Organisation details not found')
    }
  });
};

export const clearOrganisationData = () => async (dispatch) => {
  dispatch({ type: Types.ORGANISATION.SCRIPT_CLEAR, payload: null });
};

