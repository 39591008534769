import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Button } from "reactstrap";
import PageHeader from "../../@core/components/PageHeader";
import DBSelection from "./DBSelection";
import DBForm from "./DBForm";

import './style.scss';
import DBSchema from "./DBSchema";

const DBIntelligence = ({}) => {
    const [showForm, setShowForm] = useState(false);
    const [dbName, setDBname] = useState('');
    const location = useLocation();
    const navigate = useNavigate();


    const handleForm = (db) => {
        setShowForm(prev => !prev);
        if (db) { setDBname(db) }
    }

    const backToDBSelect = () => {
        setShowForm(false);
    }

    const handlePreviousConnections = ()=>{
        navigate('/database-intelligence/connection/:1');
    }

    return (
        <>
            <PageHeader
                pageName={"Database Intelligence"}
                backButtonEnabled={false}
            />
            <Card>
                <div className='container-fluid vh-85'>
                    <div className='no-scroll-bar db-itelligence'>
                        <div className="db-itelligence__body">
                            {
                                !showForm ?
                                <DBSelection handleForm={handleForm} />:
                                <DBForm dbName={dbName || ''} backButton={backToDBSelect} />
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default DBIntelligence;