import { Card, CardBody } from 'reactstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Function to transform the input data
const transformData = (data) => {
    return data.labels.map((label, index) => {
        const transformedItem = { name: label };
        data.datasets.forEach((dataset) => {
            transformedItem[dataset.label] = dataset.data[index];
        });
        return transformedItem;
    });
};

const LineCard = ({ data }) => {
    const chartData = transformData(data);
    return (
        <Card>
            <CardBody >
                <ResponsiveContainer width="100%" height={250}>
                    <LineChart
                        width={500}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {data.datasets.map((dataset, index) => (
                            <Line
                                key={index}
                                type="monotone"
                                dataKey={dataset.label}
                                stroke={dataset.borderColor || '#8884d8'}
                                activeDot={{ r: 8 }}
                                fill={dataset.fill ? dataset.fill : false}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </CardBody>
        </Card>

    )
}

export default LineCard;