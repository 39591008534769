import { Card, CardBody, Button } from 'reactstrap';
import bankLogo from '../../assets/images/bsp/bank-logo.png'


//style
import './index.scss';

const ApiDashboard = () => {
    return (
        <div className="api-dashboard">
            <div className="container row">
                <Card className='api-card col-md-4'>
                    <CardBody className=''>
                        <div className='card-header'>
                            <img src={bankLogo} className="w-25 h-25" alt='logo' />
                            <h3 className='h3 font-weight-bold'>Bank Statement Analysis</h3>
                        </div>
                        {/* <div className='card-image mb-1'>
                            <img src={cardImg} alt='logo' />
                        </div> */}
                        <p>Expert bank statement analysis assesses financial health, tracks spending patterns, and informs decisions for loan approvals and strategic financial planning.</p>
                        <div className='card-action-wrapper'>
                            <Button color='link'>Pricing</Button>
                            <Button color='link'>Test</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default ApiDashboard;