import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";
import { toTitleCase, convertToSentenceCase } from "./helper";
import { saveAs } from 'file-saver';
const generateDocxAndDownload = async (documentObject) => {
    // Create a new instance of Document for the docx module
    let doc = new Document({
        styles: {
          paragraphStyles: [
            {
              id: "paraStyle",
              name: "My Custom Style",
              basedOn: "Normal",
              run: {
                color: "000000",
                italics: false,
                bold: false,
                size: 28,
                font: "Helvetica"
              },
              paragraph: {
                spacing: { line: 276, before: 150, after: 150 }
              }
            },
            {
              id: "mainHeadingStyle",
              name: "main Heading Style",
              basedOn: "Normal",
              run: {
                color: "000000",
                italics: false,
                bold: true,
                size: 48,
                font: "Helvetica"
              },
              paragraph: {
                spacing: { line: 276, before: 150, after: 400 }
              }
            },
            {
              id: "subHeadingStyle",
              name: "sub Heading Style",
              basedOn: "Normal",
              run: {
                color: "000000",
                italics: false,
                bold: true,
                size: 36,
                font: "Helvetica"
              },
              paragraph: {
                spacing: { line: 276, before: 250, after: 250 }
              }
            }
          ]
        },
        sections: [{ children: genrateDocxContent(documentObject) }]
      });

      // Saving the document
      const fileName = documentObject.title?toTitleCase(documentObject.title):"ProContent Document";
      saveDocumentToFile(doc, fileName);
      return new Promise((resolve) => { setTimeout(() => { resolve("downloaded") }, 1000) });
}

const genrateDocxContent = (documentObject) => {
    const contentArr = [];

    // Add Title to the page
    if(documentObject.title){
        contentArr.push(new Paragraph({
            text: toTitleCase(documentObject.title), 
            style: "mainHeadingStyle"
        }))
    }

    // Add section to the page
    if(
        documentObject.content 
        && typeof(documentObject.content) == typeof([]) 
        &&  documentObject.content.length > 0
    ){
        // Insert head
        documentObject.content.forEach((sectionObject, sectionIndex)=>{
            contentArr.push(
                new Paragraph({
                    text: convertToSentenceCase(sectionObject.head), 
                    style:"subHeadingStyle"
                })
            )

            // Insert content
            if(
                sectionObject.content 
                && typeof(sectionObject.content) == typeof([]) 
                &&  sectionObject.content.length > 0
            ){

                sectionObject.content.forEach((contentItem, contentIndex)=>{
                    contentArr.push(
                        new Paragraph({
                            text: contentItem, 
                            style:"paraStyle",
                            alignment: AlignmentType.JUSTIFIED
                        })
                    )
                })
            }
        })
    }
    return contentArr
}

const saveDocumentToFile = (doc, fileName) => {
    const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    Packer.toBlob(doc).then((blob) => {
      const docblob = blob.slice(0, blob.size, mimeType);
      saveAs(docblob, fileName);
    });
}


const fetchUploadedDocumentDetails = (file) => {
  if (file) {
      const fileSizeInKB = (file.size / 1024).toFixed(2);
      let fileCategory = '';
      switch (file.type) {
        case 'image/png':
          fileCategory = 'PNG';
          break;
        case 'image/jpeg':
          fileCategory = 'JPEG';
          break;
        case 'image/webp':
          fileCategory = 'WEBP';
          break;
        case 'application/pdf':
          fileCategory = 'PDF';
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          fileCategory = 'DOCX';
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          fileCategory = 'XLSX';
          break;
        case 'application/msword':
          fileCategory = 'DOC';
          break;
        case 'application/vnd.ms-excel':
          fileCategory = 'XLS';
          break;
        case 'application/javascript':
          fileCategory = 'JS';
          break;
        case 'application/json':
          fileCategory = 'JSON';
          break;
        case 'text/csv':
          fileCategory = 'CSV';
          break;
        case 'text/plain':
          fileCategory = 'TEXT';
          break;
        default:
          fileCategory = 'UNKNOWN';
          break;
      }
      return {
          fileCategory,
          fileSizeInKB
      }
  }
}

export {
    generateDocxAndDownload,
    fetchUploadedDocumentDetails
}
