import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const Charts = ({data}) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
        <div style={{ width: '100%', height: 130 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data || []}
                        cx={140}
                        cy={60}
                        innerRadius={30}
                        outerRadius={50}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data && data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default Charts;
