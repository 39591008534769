import './style.scss';
import Charts from "../../components/ChartRenderer";
import { Table } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fetchChatBotAnalytics } from '../../../services/chatBot';

const ChatBotAnalytics = ({ chatbotId }) => {
    const [data, setData] = useState({});
    const [browserData, setBrowserData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [devicesData, setDevicesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [chatbot_Id, setChatbotId] = useState(chatbotId);

    useEffect(() => {
        const fetchData = async () => {
            if(chatbotId == 'new-bot'){
                return;
            }
            try {
                const response = await fetchChatBotAnalytics(chatbot_Id);
                if (response?.data?.data) {
                    setData(response.data.data);

                    if (response?.data?.data?.browser) {
                        const dataArray = Object.entries(response.data.data.browser).map(([key, value]) => ({
                            name: key,
                            value: value
                        }));
                        setBrowserData(dataArray);
                    }


                    if (response?.data?.data?.device) {
                        const deviceArray = Object.entries(response.data.data.device).map(([key, value]) => ({
                            name: key,
                            value: value
                        }));
                        setDevicesData(deviceArray);
                    }

                    if (response?.data?.data?.location) {
                        const locationArray = Object.entries(response.data.data.location).map(([key, value]) => ({
                            name: key,
                            value: value
                        }));
                        setLocations(locationArray);
                    }
                }

            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [chatbotId]);

    return (
        <div className="chatbot-analytics" style={{ height: '70vh' }}>
            {
                isLoading ?
                    <div className='loader-wrapper'>
                        <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#0082AC" data-testid="oval-svg">
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                    <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#0082ac6e" strokeWidth="2"></circle>
                                    <path d="M20 0c0-9.94-8.06-20-20-20">
                                        <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    :
                    error ? <h2 className='error-msg'>No data found</h2> :
                        (Object.keys(data).length > 0) ?
                            <>
                                {/* Top Analytics */}
                                <div className="chatbot-analytics__top">
                                    <div className="count-card">
                                        <span className="count-card__count">{data?.session_count || 0}</span>
                                        <span className="count-card__title">No. of Users</span>
                                    </div>
                                    <div className="count-card">
                                        <span className="count-card__count">{data?.query_count || 0}</span>
                                        <span className="count-card__title">No. of Queries</span>
                                    </div>
                                    <div className="count-card">
                                        <Charts data={browserData || []} />
                                        <span className="count-card__title">Browsers</span>
                                    </div>
                                    <div className="count-card">
                                        <Charts data={devicesData || []} />
                                        <span className="count-card__title">Devices</span>
                                    </div>
                                </div>
                                {/* Middle content, Region and Priority Questions */}
                                <div className="chatbot-analytics__middle">
                                    {/* <div className='chatbot-analytics__table'>
                                        <h2>Regions</h2>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th style={{ width: '100px' }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    locations.length > 0 &&
                                                    locations.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item.name || ''}</td>
                                                            <td>{item.value || ''}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div> */}
                                    {/* <div className='chatbot-analytics__table'>
                                        <h2>Top Queries</h2>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Queries</th>
                                                    <th style={{ width: '100px' }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.frequent_queries.length > 0 &&
                                                    data.frequent_queries.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item.message || ''}</td>
                                                            <td>{item.count || ''}</td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </Table>
                                    </div> */}
                                </div>

                                {/* Bottom content, Recommendations */}
                                {/* <div className="chatbot-analytics__bottom">
                                    <div className='chatbot-analytics__table'>
                                        <h2>Recommendation</h2>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th style={{ width: '100px' }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>India</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>India</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>India</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div> */}
                            </>
                            : <h2 className='error-msg'>No data found</h2>
            }
        </div>
    )
}

export default ChatBotAnalytics;