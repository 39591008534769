// ** React Imports
import { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// ** Third Party Components
import "./style.scss";
import "cleave.js/dist/addons/cleave-phone.us";

const { decode } = require("base64-arraybuffer");
import defaultAvatar from "@src/assets/images/avatars/avatar-user.png";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  FormFeedback,
  Spinner
} from "reactstrap";

// ** Utils
import {
  getUserNameFromEmail,
  isValidEmail,
  isValidPassword,
  onLogout, saveDataToLocalStorage,
  scrollableDivStyle,
  toastStyle
} from "../../../utility/helper";

// ** Demo Components
import DeleteAccount from "./templates/DeleteAccount";
import AvatarEditor from "react-avatar-editor";
import InputPasswordToggle from "../../../@core/components/input-password-toggle";
import toast from "react-hot-toast";
import { updateUserData } from "../../../services/onboarding";
import { statusCode } from "../../../utility/constants/utilObject";
import PageHeader from "../../../@core/components/PageHeader";

const CompanyTabs = () => {
  // ** States
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [isFileInputEnabled, setIsFileInputEnabled] = useState(true);
  const editorRef = useRef(null);
  const [formData, setFormData] = useState({
    name: localStorage.getItem("company_name") || "",
    domain: localStorage.getItem("domain") || "",
    org_type: localStorage.getItem("org_type") || "",
    company_id: localStorage.getItem("company_id") || "",
    website: localStorage.getItem("website") || "",
    logo: localStorage.getItem("logo") || ""
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onSubmit = e => {
    setLoader(true);
    e.preventDefault();

    if (!formData.first_name) {
      setLoader(false);
      return toast.error("First Name cannot be empty", { ...toastStyle.error });
    }

    if (!formData.last_name) {
      setLoader(false);
      return toast.error("Last Name cannot be empty", { ...toastStyle.error });
    }

    if (formData.mobile && (formData.mobile.length < 10 || formData.mobile.length > 12)) {
      setLoader(false);
      return toast.error("Invalid Mobile Number", { ...toastStyle.error });
    }

    if (formData.password && !formData.confirmPassword) {
      setLoader(false);
      return toast.error("Confirm password field cannot be empty", { ...toastStyle.error });
    }

    if (formData.password && !isValidPassword(formData.password)) {
      setLoader(false);
      return toast.error("Password validation failed", { ...toastStyle.error });
    }

    if (formData.confirmPassword && !formData.password) {
      setLoader(false);
      return toast.error("Password field cannot be empty", { ...toastStyle.error });
    }

    if (formData.password && formData.confirmPassword
      && formData.password.trim().toLowerCase() !== formData.confirmPassword.trim().toLowerCase()) {
      setLoader(false);
      return toast.error("Passwords does not match", { ...toastStyle.error });
    }

    if (editorRef.current && image) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      formData.image_url = canvas.toDataURL("image/png");
      setIsFileInputEnabled(true); // Enable the file input after image upload
    }

    // API Call
    updateUserData(formData, localStorage.getItem("id")).then(res => {
      if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
        console.log(res.data.data)

        saveDataToLocalStorage(res.data.data);
        toast("User Details has been updated", { ...toastStyle.success });
        setLoader(false);
        // setFormData(res.data.data);
      } else {
        setLoader(false);
        return toast.error(
          `${res.response && res.response.data && res.response.data.error ? res.response.data.error : res.response && res.response.data && res.response.data[Object.keys(res.response.data)[0]] || "Something went wrong"}`, { ...toastStyle.error }
        );
      }
    }).catch(err => {
      setLoader(false);
      return toast.error("Something went wrong, Please contact support !", { ...toastStyle.error });
    });

  };

  const navigate = useNavigate();

  const logoutUser = () => {
    onLogout();
    navigate("/");
  };

  const checkUrl = () => {
    let image_url = localStorage.getItem("image_url");
    if (image_url === "null" || image_url === "NA" || !image_url) {
      image_url = defaultAvatar;
    }
    setImage(image_url);
  };

  useEffect(() => {
    checkUrl();
  }, []);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file, 'file')
    if (file) {
      setImage(URL.createObjectURL(file));
      setIsFileInputEnabled(false); // Disable the file input after image selection
    }
  };

  const handleImageReset = () => {
    checkUrl();
    setIsFileInputEnabled(true); // Enable the file input after resetting
  };

  const handleCircularClick = () => {
    if (isFileInputEnabled) {
      document.getElementById("imageInput").click();
    }
  };

  return (
    <div>
      <Card>
        <CardHeader style={{ display: "flex", justifyContent: "space-between", padding: "1%" }}
          className="border-bottom">
          <CardTitle tag="h3"><b>Company Details</b></CardTitle>
          {/* <div>
              <Button onClick={logoutUser} className="me-1" color="danger">
                Logout
              </Button>
            </div> */}
        </CardHeader>
        <CardBody className="py-2 my-25">
          <div className="d-flex" style={{ marginTop: "-0.5%", marginBottom: "-0.5%" }}>
            <div
              className="circular-image"
              onClick={handleCircularClick}
            >
              <AvatarEditor
                ref={editorRef}
                image={image}
                width={110}
                height={110}
                border={10}
                color={[255, 255, 255, 0.6]}
                scale={1.1}
                rotate={0.3}
                borderRadius={200}
                style={{ cursor: isFileInputEnabled ? "pointer" : "default" }}
              /> : <> </>
            </div>

            <div>

              <input
                id="imageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />

              <div className="action-buttons">
                <button onClick={handleImageReset} className="button-reset">Reset</button>
              </div>
            </div>
          </div>
          <Form className="mt-2 pt-30" onSubmit={onSubmit}>
            <Row>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="company_name">
                  Company Name
                </Label>
                <Input
                  type="text"
                  name="company_name"
                  // disabled={loader}
                  placeholder="Company Name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </Col>
              {/* <Col sm="6" className="mb-1">
                  <Label className="form-label" for="company_id">
                    Company ID
                  </Label>
                  <Input
                    type="text"
                    name="company_id"
                    // disabled={loader}
                    placeholder="Company ID"
                    onChange={handleChange}
                    value={formData.company_id}
                  />
                </Col> */}
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="domain">
                  Domain
                </Label>
                <Input
                  type="text"
                  name="domain"
                  // disabled={loader}
                  placeholder="Industry Domain"
                  onChange={handleChange}
                  value={formData.domain}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="org_type">
                  Organisation Type
                </Label>
                <Input
                  type="text"
                  name="org_type"
                  // disabled={true}
                  placeholder="Organisation Type"
                  onChange={handleChange}
                  value={formData.org_type}
                />
              </Col>
              {/* <Col sm='6' className='mb-1'>
                <Label className='form-label' for='company'>
                  Company
                </Label>
                <Input defaultValue={data.company} id='company' name='company' placeholder='Company Name' />
              </Col> */}
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="website">
                  Website
                </Label>
                <Input
                  name="website"
                  type="text"
                  // disabled={loader}
                  placeholder="Website Url"
                  onChange={handleChange}
                  value={formData.mobile}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="location">
                  Location
                </Label>
                <Input
                  name="location"
                  type="text"
                  // disabled={loader}
                  placeholder="Your Company Location"
                  onChange={handleChange}
                  value={formData.mobile}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <Label className="form-label" for="country">
                  Country
                </Label>
                <Input
                  name="country"
                  type="text"
                  // disabled={loader}
                  placeholder="Your Country Name"
                  onChange={handleChange}
                  value={formData.mobile}
                />
              </Col>

              {loader ? <div className="sipnner-container">
                <Spinner color="primary" />
              </div> :
                <Col className="mt-1" sm="12">
                  <Button type="submit" className="me-1" color="primary">
                    Update
                  </Button>
                </Col>
              }
            </Row>
          </Form>
        </CardBody>
      </Card>
      {/* <DeleteAccount /> */}
    </div>
  );
};

export default CompanyTabs;