import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Spinner, FormGroup, Input } from "reactstrap";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";

import { columns } from "./columns";
//css
import './style.scss';
import DataTable from "react-data-table-component";

const UploadTable = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [nameFilter, setNameFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [bankNameFilter, setBankNameFilter] = useState('');


    const { historyResponse, loading, error } = useSelector(state => ({
        historyResponse: state.uploadHistory.historyResponse,
        loading: state.uploadHistory.loading,
        error: state.uploadHistory.error,
    }));
    useEffect(() => {
        if (historyResponse && historyResponse?.recent_analysis) {
            setData(historyResponse?.recent_analysis);
            setFilteredData(historyResponse?.recent_analysis)
        }
    }, [historyResponse])



    const handleFilter = () => {
        let updatedData = data;

        if (nameFilter) {
            updatedData = updatedData.filter(item => item.customer_name.toLowerCase().includes(nameFilter.toLowerCase()));
        }

        setFilteredData(updatedData);
    };

    useEffect(() => {
        handleFilter();
    }, [nameFilter, dateFilter, bankNameFilter]);


    //Loader
    if (loading) {
        return (
            <div className='loader-wrapper'>
                <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8" data-testid="oval-svg">
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2" data-testid="oval-secondary-group">
                            <circle stroke-opacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" stroke-width="2"></circle>
                            <path d="M20 0c0-9.94-8.06-20-20-20">
                                <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                            </path>
                        </g>
                    </g>
                </svg>

            </div>
        )
    }
    return (
        <>
            <div className="filter-wrapper">
                <FormGroup>
                    <Input
                        name="search"
                        placeholder="Filter by Name"
                        type="search"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}

                    />
                </FormGroup>
            </div>

            <div className="history-table">
                {
                    !(Object.keys(error).length === 0) ?
                        <h2 className="error-msg">{error.data?.error || 'No Data Found'}</h2>
                        :
                        <DataTable
                            noHeader
                            responsive={true}
                            columns={columns}
                            data={filteredData}
                            className="history-table"
                            sortable 
                        />
                }

            </div>
        </>
    )
}

export default UploadTable;