import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'ap-south-1',
});

const s3 = new AWS.S3();

export const uploadFile = (file, bucketName, directory) =>  {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject('No file provided');
        return;
      }

      const params = {
        Bucket: bucketName,
        Key: `${directory}/${new Date().getTime()}-${file.name}`,
        Body: file,
        ContentType: file.type,
        ContentDisposition: 'inline',
        ACL: 'public-read', // Make the uploaded file public
      };


      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading file', err);
          reject(err); // Reject the promise on error
        } else {
          console.log('File uploaded successfully');
          resolve(data); // Resolve the promise with the uploaded data
        }
      });
    })

}

export const deleteFile = (key, bucketName) =>  {
  return new Promise((resolve, reject) => {
    if (!key) {
      reject('No file provided to delete');
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: key,
    };


    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error('Error deleting file', err);
        reject(err); // Reject the promise on error
      } else {
        console.log('File deleted successfully');
        resolve(data); // Resolve the promise with the uploaded data
      }
    });
  })

}
