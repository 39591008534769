// ** React Imports
import { Fragment, useState, useEffect } from "react";

// ** Third Party Components
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft, ChevronRight, Rss } from "react-feather";

// ** Reactstrap Imports
import { Form, Label, Input, Row, Col, Button, FormFeedback, Spinner } from "reactstrap";
import { ClipLoader } from "react-spinners";

// ** Custom Components
import InputPasswordToggle from "@components/input-password-toggle";
import {
    getUserNameFromEmail,
    isValidPassword,
    saveDataToLocalStorage,
    showErrorToast,
    toastStyle
} from "../../../utility/helper";
import toast from "react-hot-toast";
import { signupUserHandler } from "../../../services/onboarding";
import { createNewUserHandler } from "../../../services/onboarding";
import { statusCode, emailVerificationStatus } from "../../../utility/constants/utilObject";
import { Link, useNavigate } from "react-router-dom";
import { businessDomains } from "../../../utility/constants/options";
import GoogleSignInButton from "../socialLoginScreens/GoogleLoginBtn";

const style = {
    padding: "0.571rem 1rem",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #d8d6de",
    width: "100%",
    borderRadius: "0.357rem",
    display: "block",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.45",
    color: "#6e6b7b",
    appearance: "none",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
};


const RegisterForm = ({ }) => {
    const [loader, setLoader] = useState(false);
    const [isGoogleLogin, setIsGoogleLogin] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        org_name: "",
        domain: "TECHNOLOGY",
        website: "",
        password: "",
        confirmPassword: "",
        auth_token: "",
        origin: window.location.origin
    });
    const [errors, setErrors] = useState({});

    const handleGoogleLogin = (data) => {
        if (data) {
            setIsGoogleLogin(true);
            setFormData({
                first_name: data?.given_name || "",
                last_name: data?.family_name || "",
                email: data?.email || "",
                org_name: data?.given_name || "",
                domain: "TECHNOLOGY",
                website: "",
                password: "",
                confirmPassword: "",
                auth_token: data?.sub || "",
                origin: window.location.origin

            });

            setLoader(true);

            const payload = {
                first_name: data?.given_name || '',
                last_name: data?.family_name || "",
                email: data?.email || "",
                org_name: data?.given_name || "",
                domain: formData.domain,
                website: formData.website,
                password: formData.password,
                auth_token: data?.sub || "",
                origin: formData.origin
            };

            createNewUserHandler(payload)
                .then((res) => {
                    setLoader(false);
                    if (res && res.status == statusCode.HTTP_201_CREATED) {
                        res.data.user_id && localStorage.setItem("id", res.data.user_id);
                        res.data.org_id && localStorage.setItem("org_id", res.data.org_id);
                        res.data.access && localStorage.setItem("token", res.data.access);
                        res.data.refresh && localStorage.setItem('refresh', res.data.refresh);
                        res.data.status && localStorage.setItem('status', res.data.status);

                        localStorage.setItem("first_name", payload.first_name);
                        localStorage.setItem("last_name", payload.last_name);
                        localStorage.setItem("email", payload.email);
                        localStorage.setItem("company_name", payload.org_name);
                        localStorage.setItem("website", payload.website);
                        localStorage.setItem("domain", payload.domain);
                        localStorage.setItem("user_type", 'new');

                        // userDetails?.picture ? localStorage.setItem("image_url", userDetails.picture) : localStorage.setItem("image_url", '');

                        window.location.replace("/dashboard");
                        toast.success("Successfully created the user account", { ...toastStyle.success });
                    } else {
                        toast.error(`${(res.response && 'User with this email already exist!') && res.response.data && res.response.data.error ? res.response.data.error : res.response.data && res.response.data[Object.keys(res.response.data)[0]] || "Something went wrong"}`, { ...toastStyle.error });
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    showErrorToast("Something went wrong, Please try with a different email");
                })

        } else {
            showErrorToast("Something went wrong, Please try with a different email");
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const validationErrors = {};

        if (!formData.first_name) {
            validationErrors.first_name = "First Name is required";
        }
        if (!formData.last_name) {
            validationErrors.last_name = "Last Name is required";
        }
        if (!formData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = "Email is invalid";
        }

        if (!isGoogleLogin) {
            if (!formData.password) {
                validationErrors.password = "Password is required";
                // } else if (!isValidPassword(formData.password)) {
                //     // validationErrors.password = "Password must have 7+ characters, including at least 1 letter, 1 digit, and 1 special character (e.g., @$!%*?&).";
                //     validationErrors.password = "Password must have 6+ characters."
                // }
            } else if ((formData.password).length < 6) {
                // validationErrors.password = "Password must have 7+ characters, including at least 1 letter, 1 digit, and 1 special character (e.g., @$!%*?&).";
                validationErrors.password = "Password must have 6+ characters."
            }
            if (!formData.confirmPassword) {
                validationErrors.confirmPassword = "Confirm Password is required";
            } else if (formData.password !== formData.confirmPassword) {
                validationErrors.confirmPassword = "Passwords must match";
            }
        }

        return validationErrors;
    };


    const onSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoader(true);

        const payload = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            org_name: formData.first_name,
            domain: formData.domain,
            website: formData.website,
            password: formData.password,
            auth_token: formData.auth_token,
            origin: formData.origin
        };

        createNewUserHandler(payload)
            .then((res) => {
                console.log(res)

                setLoader(false);
                if (res && res.status == statusCode.HTTP_201_CREATED) {
                    res.data.user_id && localStorage.setItem("id", res.data.user_id);
                    res.data.org_id && localStorage.setItem("org_id", res.data.org_id);
                    res.data.access && localStorage.setItem("token", res.data.access);
                    res.data.refresh && localStorage.setItem('refresh', res.data.refresh);
                    res.data.status && localStorage.setItem('status', res.data.status);

                    localStorage.setItem("first_name", payload.first_name);
                    localStorage.setItem("last_name", payload.last_name);
                    localStorage.setItem("email", payload.email);
                    localStorage.setItem("company_name", payload.org_name);
                    localStorage.setItem("website", payload.website);
                    localStorage.setItem("domain", payload.domain);
                    localStorage.setItem("user_type", 'new');

                    // userDetails?.picture ? localStorage.setItem("image_url", userDetails.picture) : localStorage.setItem("image_url", '');

                    if (isGoogleLogin) {
                        window.location.replace("/dashboard");
                        toast.success("Successfully created the user account", { ...toastStyle.success });

                    } else {
                        window.location.replace("/verify-email");
                        toast.success("Successfully created the user account", { ...toastStyle.success });

                    }
                } else {
                    toast.error(`${(res.response && 'User with this email already exists!') && res.response.data && res.response.data.error ? res.response.data.error : res.response.data && res.response.data[Object.keys(res.response.data)[0]] || "Something went wrong"}`, { ...toastStyle.error });
                }
            })
            .catch((err) => {
                setLoader(false);
                showErrorToast("Something went wrong, Please try with a different email");
            })


        // signupUserHandler(payload)
        //     .then((res) => {
        //         setLoader(false);
        //         if (res && res.status && res.status.toString() === statusCode.HTTP_201_CREATED) {
        //             saveDataToLocalStorage(res.data.data);
        //             navigate("/dashboard");
        //             toast.success("Successfully created the user account", { ...toastStyle.success });
        //         } else {
        //             toast.error(`${res.response && res.response.data && res.response.data.error ? res.response.data.error : res.response.data && res.response.data[Object.keys(res.response.data)[0]] || "Something went wrong"}`, { ...toastStyle.error });
        //         }
        //     })
        //     .catch((err) => {
        //         setLoader(false);
        //         showErrorToast("Something went wrong, Please try with a different email");
        //     });

    };

    return (
        <Fragment>
            <div className="content-header resgister-title mb-2 width-700 ">
                <h2 className="fw-bolder mb-75">Let's Create Your Account</h2>
                <span className="mb-2">To get started, you'll need to create an account.</span>
                <GoogleSignInButton onSignInSuccess={handleGoogleLogin} />

                {
                    !isGoogleLogin ?
                        <div className="divider my-2">
                            <div className="divider-text">or</div>
                        </div>
                        :
                        ""
                }

            </div>
            <Form onSubmit={onSubmit} className="width-700">
                <Row>
                    <Col md="6" className="mb-1">
                        <Label className="form-label" for="first_name">
                            First Name
                        </Label>
                        <span className="text-danger">*</span>
                        <Input
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            disabled={loader}
                            placeholder="John Doe"
                            invalid={errors.first_name && true}
                        />
                        {errors.first_name && <FormFeedback>{errors.first_name}</FormFeedback>}
                    </Col>
                    <Col md="6" className="mb-1">
                        <Label className="form-label" for="last_name">
                            Last Name
                        </Label>
                        <span className="text-danger">*</span>
                        <Input
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            disabled={loader}
                            placeholder="John Doe"
                            invalid={errors.last_name && true}
                        />
                        {errors.last_name && <FormFeedback>{errors.last_name}</FormFeedback>}
                    </Col>

                    <Col md="12" className="mb-1">
                        <Label className="form-label" for="email">
                            Email
                        </Label>
                        <span className="text-danger">*</span>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={loader}
                            placeholder="john.doe@email.com"
                            invalid={errors.email && true}
                            style={{ padding: '0.571rem 1rem', borderRadius: '0.357rem', border: '1px solid #d8d6de', marginTop: '0' }}
                        />
                        {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                    </Col>

                    {
                        !isGoogleLogin
                        &&
                        <>
                            <Col md="6" className="mb-1">

                                <div className="form-password-toggle">
                                    <Label className="form-label" for="password">
                                        Password
                                    </Label>
                                    <span className="text-danger">*</span>
                                    <InputPasswordToggle
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        disabled={loader}
                                        className="input-group-merge"
                                        invalid={errors.password && true}
                                    />
                                    {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                </div>
                            </Col>
                            <Col md="6" className="mb-1">

                                <div className="form-password-toggle">
                                    <Label className="form-label" for="confirmPassword">
                                        Confirm Password
                                    </Label>
                                    <span className="text-danger">*</span>
                                    <InputPasswordToggle
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        disabled={loader}
                                        className="input-group-merge"
                                        invalid={errors.confirmPassword && true}
                                    />
                                    {errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
                                </div>

                            </Col>
                            <Col md="12" className="mb-1">
                                <div style={{ opacity: 0.6 }}>
                                    <p>
                                        <small>Password must have 6+ characters.
                                        </small>
                                    </p>
                                </div></Col>
                            {/* <div style={{ opacity: 0.6 }}>
                            <p>
                                <small>Password must have 7+ characters, including at least 1 letter, 1 digit, and 1 special character
                                    (e.g., @$!%*?&).
                                </small>
                            </p>
                        </div> */}
                        </>
                    }

                    {/* <Col md="6" className="mb-1">
                        <Label className="form-label" for="org_name">
                            Company Name (optional)
                        </Label>
                        <Input
                            id="org_name"
                            name="org_name"
                            value={formData.org_name}
                            onChange={handleChange}
                            placeholder="Company Name"
                            invalid={errors.org_name && true}
                        />
                        {errors.org_name && <FormFeedback>{errors.org_name}</FormFeedback>}
                    </Col>

                    <Col md="6" className="mb-1">
                        <Label className="form-label" for="domain">
                            Industry Domain (optional)
                        </Label>
                        <Input
                            id="domain"
                            name="domain"
                            type="select"
                            value={formData.domain}
                            onChange={handleChange}
                            style={style}
                            invalid={errors.domain && true}
                        >
                            <option value="">Select an option</option>
                            {businessDomains.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Input>
                        {errors.domain && <FormFeedback>{errors.domain}</FormFeedback>}
                    </Col>

                    <Col md="6" className="mb-1">
                        <Label className="form-label" for="website">
                            Website (optional)
                        </Label>
                        <Input
                            id="website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                            placeholder="Website URL"
                            invalid={errors.website && true}
                        />
                        {errors.website && <FormFeedback>{errors.website}</FormFeedback>}
                    </Col> */}
                </Row>

                {loader ? (
                    <div className="spinner-container">
                        <ClipLoader color="primary" />
                    </div>
                ) : (
                    <div className="d-flex justify-content-between mt-2">
                        <Link className="btn-prev" to="/login">
                            <>
                                <ChevronLeft size={14} className="align-middle me-sm-25 me-0" />
                                <span className="align-middle d-sm-inline-block">Sign In Instead</span>
                            </>
                        </Link>
                        <Button type="submit" color="primary" className="btn-next">
                            <span className="align-middle d-sm-inline-block">Create Account</span>
                        </Button>
                    </div>
                )}
            </Form>
        </Fragment >
    );
};

export default RegisterForm;

