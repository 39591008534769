import Types from "../types";

const initialState = {
  historyResponse: {},
  loading: false,
  error: {}
};

export default function uploadHistory(state = initialState, action) {
  switch (action.type) {
    case Types.UPLOAD_HISTORY.PENDING:
      return {
        ...state,
        loading: true
      };
    case Types.UPLOAD_HISTORY.FULFILLED:
      return {
        ...state,
        historyResponse: action.payload,
        loading: false
      };

    case Types.UPLOAD_HISTORY.ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
}