import Types from '../types';

const initialState = {
  payload: null,
};

export default function calendarPosts(state = initialState, action) {
    switch (action.type) {
        case Types.CALANDER_POST_LIST:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
}