//react
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { ArrowLeft } from 'react-feather';
//Bank Sidebar

// import BankSidebar from './Sidebar';

import { Button, Card, Input, InputGroup, Modal } from 'reactstrap'

//Banks
import Banks from './Bank';
import BankHeader from './BankHeader';
import UploadStatement from './UploadSatement';


//style
import './style.scss';


const BankParsing = () => {
    const [showUpload, setShowUpload] = useState(false);
    const navigate = useNavigate();

    const handleUpload = () => {
        // setShowUpload(true);
        navigate('/financial-statement-analyser/upload-statement');
    }
    const backBtnHandle = () => {
        navigate(-1)
    }


    return (
        <>
            <Button onClick={backBtnHandle} color="link" className="backIcon">
                <ArrowLeft>
                </ArrowLeft>
                <span>Back</span>
            </Button>

            <Card className='fsa'>
                <div style={{display: "block"}} className='container-fluid vh-85'>
                    <BankHeader activeStep={0} />
                    <div className='page-container no-scroll-bar main-bsp'>
                        <Banks handleUpload={handleUpload} />
                    </div>
                </div>
            </Card>

        </>

    )
}
export default BankParsing;