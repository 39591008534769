import NewsBlock from "../components/newsBlock";
import RecommendedBlogs from "../components/recommendedBlogs";

import "./index.scss";
import PageHeader from "../../@core/components/PageHeader";
import React from "react";

const KnowledgeCenter = () => {
  return (
    <>
      <PageHeader
        pageName={"Knowledge Center"}
        backButtonEnabled={false}
      />
      <RecommendedBlogs />
      <NewsBlock />
    </>
  );
};

export default KnowledgeCenter;