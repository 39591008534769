import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
    login: "auth/login/",
    signup: "auth/register/",
    signupThirdparty: "auth/social_login/",
    createNewUser: "auth/user_onboarding/",
    createUserProfile: "auth/user/create_user_profile/",
    createOrganisation: "organisation/onboard/",
    fetchOrganisation: "organisation/{0}",
    linkedinAuthSetup: "auth/linkedin_auth_setup/",
    linkedinAddAccountSetup: "auth/third_party_auth/linkedin_auth_setup/",
    updateUserDataRoute: "auth/user/{0}/",
    updateUserCompanyDetail: "user/update_company/",
    uploadDocument: 'data_connection/recommendation/document_upload/',
}

export const loginUserHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.login, payload)
};

export const signupUserHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.signup, payload)
};

export const onboardOrganisation = (payload) => {
    return REQUESTS.post(API_ROUTES.createOrganisation, localStorage.getItem('token'), payload);
}

export const uploadDocumentFile = (payload) => {
    return REQUESTS.postFile(API_ROUTES.uploadDocument, localStorage.getItem('token'), payload);
}

export const fetchOrganisation = (id) => {
    return REQUESTS.get(API_ROUTES.fetchOrganisation.format(id), localStorage.getItem("token"));
};

export const submitBotSearchMessage = async(messagePayload) => {
    return REQUESTS.post(`inquiry/recommendation/admin_bot_search/`, localStorage.getItem("token"), messagePayload);
}

export const signupThirdPartyUserHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.signupThirdparty, payload)
};

export const linkedinAuthSetupHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.linkedinAuthSetup, payload)
};

export const linkedinAddAccountHandler = (payload) => {
    let token = localStorage.getItem('token');
    if(!token){
        token = localStorage.getItem('temp_token');
    }
    return REQUESTS.post(API_ROUTES.linkedinAddAccountSetup, token, payload)
};

export const createNewUserHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.createNewUser, payload)
};

export const createUserProfileHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.createUserProfile, localStorage.getItem('temp_token'), payload)
};

export const createCompanyProfileHandler = (payload) => {
    return REQUESTS.post(API_ROUTES.createCompanyProfile, localStorage.getItem('temp_token'), payload)
};

export const updateUserData = (payload, userId) => {
    return REQUESTS.put(
      API_ROUTES.updateUserDataRoute.format(userId),
      localStorage.getItem('token'),
      payload
    )
}
