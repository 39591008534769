// ** React Imports
import { Fragment, useState, useRef, useEffect } from 'react'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'

import defaultAvatar from '@src/assets/images/avatars/companyLogo.png'
import MainLogo from '../../../../../assets/logo/nav/main-logo.svg'
import { ChevronRight, ChevronDown, X, Disc, Circle } from "react-feather";
import { NavLink } from "react-router-dom";

import { getUserData, getHomeRouteForLoggedInUser } from '@utils';
import themeConfig from '@configs/themeConfig';

const Sidebar = props => {
  // ** Props
  const { menuCollapsed, menu, skin, menuData, setMenuCollapsed } = props;
  const user = getUserData();
  // ** States
  const [groupOpen, setGroupOpen] = useState([]);
  const [groupActive, setGroupActive] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [collapseMenu, setCollapseMenu] = useState(false);

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true);
  }

  // ** Scroll Menu
  const scrollMenu = container => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  const verticalMenuItem = (
    <ul className='navigation navigation-main'>
      <VerticalNavMenuItems
        items={menuData}
        menuData={menuData}
        menuHover={menuHover}
        groupOpen={groupOpen}
        activeItem={activeItem}
        groupActive={groupActive}
        setGroupOpen={setGroupOpen}
        menuCollapsed={menuCollapsed}
        setActiveItem={setActiveItem}
        setGroupActive={setGroupActive}
        currentActiveGroup={currentActiveGroup}
        setCurrentActiveGroup={setCurrentActiveGroup}
      />
    </ul>
  );

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-primary toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  const expandVerticalMenu = () => {
    const companyName = localStorage.getItem('company_name');
    const logo = localStorage.getItem('logo') !== null && localStorage.getItem('logo') !== "NA" ? localStorage.getItem('logo') : defaultAvatar;

    // return (
    //   <Fragment>
    //     <div onClick={() => setCollapseMenu(!collapseMenu)} className={classnames("menu-center menu-header")}>
    //       <div className={classnames("menu-center")} style={{paddingLeft: '10px'}} >
    //         {(menuHover || menuCollapsed === false) && <h1 className='menu-header-text'>{"MindGraph"}</h1>}
    //       </div>
    //       {collapseMenu ? <ChevronDown className="actionIcon" size={"20px"} color="#F2F5FA" /> : <ChevronRight className="actionIcon" size={"20px"} color="#F2F5FA" />}
    //     </div >
    //   </Fragment>
    // )
    
    return (
      <Fragment>
        <div className='navbar-header'>
          <ul className='nav navbar-nav flex-row align-items-center'>
            <li className='nav-item me-auto'>
              <NavLink to={user ? getHomeRouteForLoggedInUser(user.role) : '/'} className='navbar-brand'>
            <span className='brand-logo 12'>
              {/* { logo && <img src={logo || themeConfig.app.appLogoImage} alt='logo' />} */}
              <img src={MainLogo} alt='logo' />
            </span>
                {/* <h2 className='brand-text mb-0'>{companyName || themeConfig.app.appName}</h2> */}
                <h2 className='brand-text'>Insight<span>AI</span></h2>
              </NavLink>
            </li>
            <li className='nav-item nav-toggle'>
              <div className='nav-link modern-nav-toggle cursor-pointer'>
                <Toggler />
                <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
              </div>
            </li>
          </ul>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div
        className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
          expanded: menuHover || menuCollapsed === false,
          'menu-light': skin !== 'semi-dark' && skin !== 'dark',
          'menu-dark': skin === 'semi-dark' || skin === 'dark'
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
        id="verticalHeaderMenu"
      >
        {menu ? (
          menu({ ...props })
        ) : (
          <Fragment>
            {/* Vertical Menu Header Shadow */}
            <div className='shadow-bottom' ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className='main-menu-content'
              options={{ wheelPropagation: false }}
              onScrollY={container => scrollMenu(container)}
            >
              {expandVerticalMenu()}
              {verticalMenuItem}
            </PerfectScrollbar>
            {/* Vertical Menu Footer */}
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
