import Types from '../types';

const initialState = {
  queryLogDataList: [],
  queryLogData: {},
  count: 0,
  limit: 10,
  offset: 0,
  loader: false,
  sort: '-created_at',
  search: ''
};

export default function queryLogReducer(state = initialState, action) {
  switch (action.type) {
    case Types.QUERY_LOGS.SUCCESS:
      return {
        ...state,
        queryLogDataList: action.payload.data,
        count: action.payload.total_count
      };

    case Types.QUERY_LOGS.GET_QUERY_LOGS:
      return {
        ...state,
        queryLogData: action.payload,
      };

    case Types.QUERY_LOGS.DATA_CLEAR:
      return {
        ...state,
        queryLogData: {},
        limit: 10,
        offset: 0,
        search: '',
        sort: '-created_at',
      }

    case Types.QUERY_LOGS.TOGGLE_LOADER:
      return {
        ...state,
        loader: action.payload
      }

    case Types.QUERY_LOGS.SET_DATA:
      return {
        ...state,
        limit: action.payload.limit,
        offset: action.payload.offset,
        search: action.payload.search,
        sort: action.payload.sort,
      }

    default:
      return state;
  }
}
