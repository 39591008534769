import Types from '../types';

const initialState = {
  loader: false,
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case Types.COMMON.LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    default:
      return state;
  }
}
